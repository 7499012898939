import React, { Component } from 'react'
import { exampleElements, exampleElementSets } from '../static/example-data/elements'
import LayoutManager from './LayoutManager'
import localforage from 'localforage'

const elementStore = localforage.createInstance({ name: "augooiigooey", storeName: "elements" })

/*
  { key, classes[], data: {"augmented-ui": augs}, whtl }
*/

class ElementManager extends Component {
  // props: classManager = {
  //   revert, // (existingClassName)
  //   save, // (oldClassName, newClassName)
  //   getCSS, // (className)
  //   onCSSChanged, // (className, css)
  //   exampleClasses
  //   savedClasses
  // }

  constructor (props) {
    super(props)

    const state = {
      // "001": { key: "001", classes: ["x_corners-square"], data: { "augmented-ui": "exe" }, h: 120, w: 120 }, ...
      _sources: {},
      // lists of keys as they appear in _sources
      _exampleElements: Object.keys(exampleElements),
      _savedElements: undefined,
      // key list of objects drictly on state, which are individual clones of source elements with their own unique keys
      _currentElements: undefined
    }
    state._exampleElements.forEach(key => state._sources[key] = exampleElements[key])
    const { clonedElements, setKeyList } = this.randomExampleSet(state._sources)
    state._currentElements = setKeyList
    this.state = Object.assign(state, clonedElements)
  }

  // weakmap clonedEl -> source element's key (for reverting)
  cloneSourceWM = new WeakMap()

  randomExampleSet = (sources) => {
    sources = sources || this.state._sources
    const allExampleSets = Object.keys(exampleElementSets)
    const randomIndex = ~~(Math.random() * allExampleSets.length)
    const randomExampleSet = exampleElementSets[allExampleSets[randomIndex]].slice()
    const setKeyList = []
    const clonedElements = randomExampleSet.reduce(
      (clonedElements, exampleKey) => {
        const clonedEl = this.deepCloneElement(sources[exampleKey])
        clonedElements[clonedEl.key] = clonedEl
        setKeyList.push(clonedEl.key)
        return clonedElements
      },
      {}
    )
    return { clonedElements, setKeyList }
  }

  deepCloneElement = (sourceEl) => {
    const clonedEl = Object.assign({}, sourceEl)
    clonedEl.classes = (clonedEl.classes || []).slice()
    clonedEl.data = Object.keys(clonedEl.data).reduce((obj, dataAttr) => {
      obj[dataAttr] = clonedEl.data[dataAttr]
      return obj
    }, {})
    clonedEl.key = this.getRandomString()
    this.cloneSourceWM.set(clonedEl, sourceEl.key)
    return clonedEl
  }

  componentDidMount () {
    const _sources = this.state._sources
    const _savedElements = []
    const savedElements = {}
    elementStore.iterate((obj, key) => {
      _savedElements.push(key)
      savedElements[key] = obj
    }).then(() => {
      this.setState({ _savedElements, _sources: Object.assign({}, _sources, savedElements) })
    }).catch(err => {
      console.log(err)
      this.setState({ _savedElements, _sources: Object.assign({}, _sources, savedElements) })
    })
  }

  dataChanged = (key, dataAttr, newValue) => {
    this.setState(state => {
      const el = state[key]
      el.data[dataAttr] = newValue
      // TODO: clone deep for this?
      return { [key]: Object.assign({}, el) }
    })
  }

  getData = (key, dataAttr) => {
    return this.state[key].data[dataAttr]
  }

  getEl = key => {
    return this.state[key]
  }

  getRandomString = () => {
    // https://gist.github.com/6174/6062387
    return Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15)
  }

  // revert live items on the page to their last saved state
  revert = key => {
    const cloneSourceWM = this.cloneSourceWM
    const curEl = this.state[key]
    const sourceElKey = cloneSourceWM.get(curEl)
    const sourceEl = this.state._sources[sourceElKey]
    const clonedEl = this.deepCloneElement(sourceEl)
    clonedEl.key = key
    cloneSourceWM.delete(curEl) // not necessary
    cloneSourceWM.set(clonedEl, sourceElKey)
    this.setState({ [key]: clonedEl })
    return clonedEl
  }

  removeFromPage = key => {
    const _currentElements = (this.state._currentElements || []).slice()
    _currentElements.splice(_currentElements.indexOf(key), 1)
    this.setState({ _currentElements, [key]: undefined })
  }

  addToPage = sourceKey => {
    this.setState(state => {
      const _currentElements = (state._currentElements || []).slice()
      const sourceEl = _currentElements.includes(sourceKey) ? state[sourceKey] : state._sources[sourceKey]
      const clonedEl = this.deepCloneElement(sourceEl)
      _currentElements.push(clonedEl.key)
      return { _currentElements, [clonedEl.key]: clonedEl }
    })
  }

  // save an element on the page to _sources, update current el's source key reference
  commit = (key, newClassName) => {
    const state = this.state
    const curObj = state[key]
    const oldClassName = curObj.classes[0]
    newClassName = newClassName || oldClassName
    curObj.classes[0] = newClassName
    const newSourceObj = this.deepCloneElement(curObj)
    const curSourceElKey = this.cloneSourceWM.get(curObj) || ""

    // if replacing an existing source, that isn't an example element
    if (newClassName === oldClassName && !curSourceElKey.length === 3) {
      // then maintain the source key
      newSourceObj.key = curSourceElKey
    } else {
      // otherwise add the new source as a new saved element
      const _savedElements = (state._savedElements || []).slice()
      _savedElements.push(newSourceObj.key)
      this.setState({ _savedElements })
    }

    this.setState({
      _sources: Object.assign({}, state._sources, { [newSourceObj.key]: newSourceObj })
    })

    // this.removeFromPage(key)
    // this.addToPage(newSourceObj.key)
    this.cloneSourceWM.set(curObj, newSourceObj.key)

    return elementStore.setItem(newSourceObj.key, newSourceObj)
  }

  render () {
    const state = this.state
    const { _exampleElements, _savedElements, _currentElements } = state
    const classManager = this.props.classManager
    const elementManager = {
      getEl: this.getEl,
      onDataChanged: this.dataChanged,
      getData: this.getData,
      revert: this.revert,
      removeFromPage: this.removeFromPage,
      addToPage: this.addToPage,
      save: this.commit,
      allSourceElements: state._sources,
      exampleElements: _exampleElements,
      savedElements: _savedElements,
      currentElements: _currentElements
    }

    return (
      <LayoutManager classManager={classManager} elementManager={elementManager} />
    )
  }
}

export default ElementManager
