import React, { Component } from 'react'
import magicComponent from 'magic-react-component'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import spaceEnterClick from '../../utils/space-enter-click'

const FoldableContainer = magicComponent("div", `
  width: 100%;
  position: relative;

  > .foldable__caret {
    width: 15px;
  }
  > .foldable__title {
    --aug-tl: 5px;
    --aug-border: 1px;
    --aug-border-opacity: 0.85;
  }
  > .foldable__content {
    --aug-tr: 5px;
    --aug-border: 1px;
    --aug-border-bg: linear-gradient(to top right, gold, transparent 10%, transparent 50%, gold 65%);
    --aug-border-opacity: 0.667;
  }
`, {
  borderColor: "gold",

  "> .foldable__title": {
    cursor: "pointer",
    fontSize: 2,
    p: "2px",
    textAlign: "center",
    backgroundColor: "gray800",
    color: "white",
    opacity: 0.75,
    "--border-color": ({magicScale}) => magicScale("color", "gold"),
    "--aug-border-bg": ({magicProps}) => magicProps.collapsed
                        ? "transparent"
                        : "top left/50% 100% no-repeat linear-gradient(135deg, var(--border-color), transparent)"
  },

  "> .foldable__title:focus": {
    "--border-color": ({magicScale}) => magicScale("color", "blue700")
  },

  "> .foldable__content": {
    py: 1,
    px: 1,
    mx: "-5px",
    fontSize: 2,
    display: ({magicProps}) => magicProps.collapsed ? "none" : "block",
    mb: ({magicProps}) => magicProps.collapsed ? 0 : 2,
    pb: ({magicProps}) => magicProps.collapsed ? 1 : 2
  }
})

class FoldableSubSection extends Component {
  // props: title, defaultOpen
  constructor (props) {
    super(props)
    this.state = {
      collapsed: (typeof props.defaultOpen === "boolean") ? !props.defaultOpen : true
    }
  }

  toggleCollapsed = () => {
    this.setState((state, props) => {
      return {
        collapsed: !state.collapsed
      }
    })
  }

  render () {
    const state = this.state
    const props = this.props
    const icon = state.collapsed ? "caret-right" : "caret-down"
    const titleAugs = state.collapsed ? "exe" : "tl-clip exe"

    return (
      <FoldableContainer collapsed={state.collapsed} className={props.className}>
        <div className="foldable__title" {...spaceEnterClick(this.toggleCollapsed)} data-augmented-ui={titleAugs}>
          <FontAwesomeIcon icon={icon} className="foldable__caret" /> {props.title || "Untitled"}
        </div>
        <div className="foldable__content" data-augmented-ui="tr-clip exe">{props.children}</div>
      </FoldableContainer>
    )
  }
}

export default FoldableSubSection
