import React, { Component } from 'react'
// import spaceEnterClick from '../../utils/space-enter-click'
import Button from '../Button'
import magicComponent from 'magic-react-component'

const ElementSettingsContainer = magicComponent("div", `
  position: relative;

  .element-settings__form {
    width: 40%;
    opacity: 0.25;
    &:hover, &:focus-within, &.highlighted {
      opacity: 1;
    }
  }
  .element-settings__form__actions {
    display: flex;
    justify-content: space-between;
    width: calc(100% - 25px);
  }
  .element-settings__form__input {
    background: rgba(255, 215, 0, 0.25);
    color: white;
    border: 1px solid gold;
    border-radius: 5px;
    font-weight: bold;
    width: calc(100% - 25px);
  }
  .element-settings__separator {
    height: 9px;
    width: 40%;
    background: gold;
    margin-top: 20px;
    margin-bottom: 20px;
    opacity: 0.25;
    --aug-tl-width: 35%;
    --aug-tl-height: 4px;
    --aug-t: 6px;
    --aug-tr-width: 20%;
    --aug-tr-height: 5px;
    --aug-br-width: 35%;
    --aug-br-height: 2px;
    --aug-b: 4px;
    --aug-bl-width: 22%;
    --aug-bl-height: 2px;
  }
  .element-settings__preview {
    position: absolute;
    top: 0px;
    right: 0px;
    width: 40%;
  }
  .element-settings__preview__box {
    position: relative;
    width: 100%;
    height: 220px;
    margin-top: 10px;
    padding: 10px;
    &::before {
      content: "";
      border-radius: 5px;
      position: absolute;
      background: var(--preview-bg, "white");
      opacity: 0.25;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: -1;
    }
  }
  .element-settings__preview__augmented {
    position: absolute;
    top: 50%;
    left: 50%;
    transform-origin: 50% 50%;
    transform: translate(-50%, -50%) scale(var(--scale, 1));
  }
`, {
  ".element-settings__form": {
    m: 1,
    p: 2,
    color: "gold",
    fontSize: 5
  },
  ".element-settings__form__note": {
    color: "gold",
    fontSize: 3,
    ml: 2
  },
  ".element-settings__form__input": {
    my: 2,
    ml: 2,
    p: 2,
    fontSize: 4
  },
  ".element-settings__form__actions": {
    my: 2,
    ml: 2
  },
  button: {
    fontSize: 4,
    width: "40%"
  }
})

class ElementSettings extends Component {
  // props: el, previewBG, classManager, elementManager, cancelCallback, onRemove

  constructor (props) {
    super(props)
    this.state = {
      highlightFirst: true,
      // TODO: dropdown, allow selecting to support more than 1 class name per el:
      saveName: props.el ? this.avoidExampleClassCollision(props.el.classes[0] || "") : "",
      saveProgressMsg: ""
    }
  }

  avoidExampleClassCollision = cn => {
    const cnIsExample = this.props.classManager.exampleClasses.includes(cn)
    return cnIsExample ? cn.replace("x_", "") : cn
  }

  saveNameUpdated = (ev) => {
    const saveName = ev.target.value.replace(/^-|^_|[^a-z0-9-_]/gi, "")
    this.setState({
      saveName: this.avoidExampleClassCollision(saveName)
    })
  }

  doSave = () => {
    const { el, classManager, elementManager, cancelCallback } = this.props
    this.setState({ saveProgressMsg: "Saving.|..."})

    Promise.all([
      classManager.save(el.classes[0], this.state.saveName),
      elementManager.save(el.key, this.state.saveName)
    ]).then(() => {
      this.setState({ saveProgressMsg: "Saved!" })
      setTimeout(cancelCallback, 1500)
    }).catch(err => {
      console.log(err)
      this.setState({ saveProgressMsg: "Could not save, please try again." })
      setTimeout(() => {
        this && this.setState && this.setState({ saveProgressMsg: "" })
      }, 2000)
    })
  }

  render () {
    const state = this.state
    const props = this.props
    const { el, previewBG, classManager, elementManager, cancelCallback, onRemove } = props
    const saveName = state.saveName
    const allSavedElements = elementManager.savedElements
    const allSavedClasses = classManager.savedClasses
    const savedElementsLoading = !allSavedElements || !allSavedClasses
    const saveNameIsAlreadyUsed = !savedElementsLoading && allSavedClasses.includes(saveName)
    const elementWidthScale = 200 / (el.w || 200)
    const elementHeightScale = 200 / (el.h || 200)
    const minScale = Math.min(Math.min(elementWidthScale, elementHeightScale), 1)
    const saveProgressMsg = state.saveProgressMsg
    const augs = el.data["augmented-ui"]

    const highlightFirstManagment = {
      onPointerEnter: () => this.setState({ highlightFirst: false }),
      onFocus: () => this.setState({ highlightFirst: false }),
      onPointerLeave: () => this.setState({ highlightFirst: true }),
      onBlur: () => this.setState({ highlightFirst: true })
    }
    const doRemove = () => {
      elementManager.removeFromPage(el.key)
      onRemove()
    }

    return (
      <ElementSettingsContainer magic={{"--preview-bg": ({magicScale}) => magicScale("colors", previewBG || "white")}}>
        <div className={"element-settings__form" + (state.highlightFirst ? " highlighted" : "")}>
          <div className="element-settings__form__title">Save element locally</div>
          <input type="text"
            className="element-settings__form__input"
            aria-label="Element Class Name"
            onChange={this.saveNameUpdated}
            value={saveName} />
          {saveProgressMsg
            ? <div className="element-settings__form__note">{saveProgressMsg}</div>
            : (saveName
                ? <div className="element-settings__form__actions">
                    {savedElementsLoading
                      ? <div className="element-settings__form__note">Loading.|...</div>
                      : <React.Fragment>
                          {saveNameIsAlreadyUsed
                            ? <Button aria-label="Class already exists, confirm Save and Replace"
                                onClick={this.doSave}
                              >Replace Existing</Button>
                            : <Button aria-label="Save element locally"
                                onClick={this.doSave}
                              >Confirm</Button>
                          }
                          <Button variant="error"
                            aria-label="Cancel element options, return to editor"
                            onClick={cancelCallback}
                          >
                            Cancel
                          </Button>
                        </React.Fragment>
                    }
                  </div>
                : <div className="element-settings__form__note">Enter a class name to save the element</div>
              )
          }
        </div>
        <div className="element-settings__separator" data-augmented-ui="tl-clip-x t-clip tr-rect br-clip-x b-clip bl-rect exe"></div>
        <div className="element-settings__form" {...highlightFirstManagment}>
          <div className="element-settings__form__title">Remove element from the page</div>
          <div className="element-settings__form__actions">
            <Button variant="error" aria-label="Confirm: Remove element from the page" onClick={doRemove}>Remove</Button>
            <Button variant="error"
              aria-label="Cancel element options, return to editor"
              onClick={cancelCallback}
            >
              Cancel
            </Button>
          </div>
        </div>
        <div className="element-settings__preview" aria-hidden="true">
          <div className="element-settings__form__note">Element Preview (scaled to fit)</div>
          <div className="element-settings__preview__box">
            <div style={{"--scale": minScale, height: (el.h || 200) + "px", width: (el.w || 200) + "px"}}
              className={el.classes.join(" ") + " element-settings__preview__augmented"}
              data-augmented-ui={augs}
            ></div>
          </div>
        </div>
      </ElementSettingsContainer>
    )
  }
}

export default ElementSettings
