import React, { Component } from 'react'
import magicComponent from 'magic-react-component'
import keyMap from '../../../utils/key-map'

const OptionList = magicComponent("div", `
  margin-left: auto;
  margin-right: auto;
  max-width: 75%;
  border-radius: 5px;
  overflow: hidden;
`, {
  backgroundColor: 'gray900',
  ".list__option": {
    backgroundColor: 'gray900',
    color: "gray500",
    p: 1,
    fontSize: 3,
    cursor: "pointer"
  },
  ".list__option:focus, .list__option:hover": {
    backgroundColor: 'gray750'
  }
})

class NewBGLayer extends Component {
  // props: onValueChanged, setPopover

  state = {
    options: [
      { name: "Image URL",
        defaultCSS: "url()"
      },
      { name: "linear-gradient",
        defaultCSS: "linear-gradient(gold, transparent)"
      },
      { name: "repeating-linear-gradient",
        defaultCSS: "repeating-linear-gradient(gold, transparent 20px)"
      },
      { name: "radial-gradient",
        defaultCSS: "radial-gradient(gold, transparent)"
      },
      { name: "repeating-radial-gradient",
        defaultCSS: "repeating-radial-gradient(gold, transparent 20px)"
      },
      { name: "color",
        defaultCSS: "rgba(255, 215, 0, 0.5)"
      }
    ]
  }

  optionSelected = (opt) => {
    const props = this.props
    const val = ((props.fakeColor && opt.name === "color")
                  ? "linear-gradient(rgba(255, 215, 0, 0.5) 0.00px, rgba(255, 215, 0, 0.5) 0.00px)"
                  : opt.defaultCSS)
    props.onValueChanged && props.onValueChanged(val)
    props.setPopover && props.setPopover()
  }

  focusPrev = ev => {
    const target = ev.target || {}
    const nextTarget = target.previousElementSibling
    nextTarget && nextTarget.focus && nextTarget.focus()
  }

  focusNext = ev => {
    const target = ev.target || {}
    const nextTarget = target.nextElementSibling
    nextTarget && nextTarget.focus && nextTarget.focus()
  }

  render () {
    const state = this.state
    const props = this.props

    const options = state.options

    return (
      <OptionList className={props.className} role="listbox" aria-label="Available Types">
        {options.map(opt => (
          <div key={opt.name}
            aria-label={opt.name}
            role="option"
            aria-selected="false"
            onKeyDown={keyMap({
              ArrowUp: this.focusPrev,
              ArrowDown: this.focusNext,
              " ": () => this.optionSelected(opt),
              Enter: () => this.optionSelected(opt)
            })}
            tabIndex="0"
            onClick={() => this.optionSelected(opt)}
            className="list__option"
          >{opt.name}</div>
        ))}
      </OptionList>
    )
  }
}

export default NewBGLayer
