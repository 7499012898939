import React, { Component } from 'react'
import WHTL from './WHTL'
import spaceEnterClick from '../utils/space-enter-click'

class Element extends Component {
  // props: elClass, active, css, augs, onSelected, onResize, w, h, t, l

  constructor (props) {
    super(props)

    const el = props.el
    const { w, h, t, l } = el
    const whtlDefaults = {}
    if (typeof w !== "undefined") { whtlDefaults.wDefault = w }
    if (typeof h !== "undefined") { whtlDefaults.hDefault = h }
    if (typeof l !== "undefined") { whtlDefaults.lDefault = l }
    if (typeof t !== "undefined") { whtlDefaults.tDefault = t }

    this.state = {
      whtlDefaults
    }
  }

  render () {
    const props = this.props
    const el = props.el
    const keyClass = "key--" + el.key
    const className = el.classes.join(" ")
    const dragTitle = "." + (className || "drag-me")
    const interactive = (props.onSelected && !props.active) ? spaceEnterClick(() => props.onSelected(el.key)) : null
    const whtlDefaults = this.state.whtlDefaults

    return (
      <React.Fragment>
        <WHTL
          title={dragTitle}
          className={props.active ? "active" : "inactive"}
          onWHTL={props.onResize}
          {...whtlDefaults}
          settingsFn={props.elementSettings}
        >
          <div className={keyClass + " " + className}
            style={{width: "100%", height: "100%"}}
            data-augmented-ui={el.data["augmented-ui"]}></div>
          {interactive
            ? <div aria-label="Select this element for editing"
                style={{width: "100%", height: "100%", position: "absolute", top: "0", left: "0"}}
                {...interactive}
              ></div>
            : null
          }
        </WHTL>
      </React.Fragment>
    )
  }
}

export default Element
