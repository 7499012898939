import React, { Component } from 'react'
import Augs from './Augs'
import AugBorderOrInset from './AugBorderOrInset'
import AugProps from './AugProps'

class AugmentedUIBuilder extends Component {
  // props: augs, onAugsChanged, css, onCSSChanged, setPopover

  state = {}

  render () {
    const props = this.props
    const onAugsChanged = props.onAugsChanged
    const onCSSChanged = props.onCSSChanged
    const setPopover = props.setPopover
    const css = props.css
    const augs = props.augs || ""
    const auglist = augs.split(" ")

    return (
      <div>
        <Augs augs={augs} onValueChanged={onAugsChanged} setPopover={setPopover} css={css} onCSSChanged={onCSSChanged} />
        <AugBorderOrInset type="border" css={css} onCSSChanged={onCSSChanged} setPopover={setPopover} />
        <AugBorderOrInset type="inset" css={css} onCSSChanged={onCSSChanged} setPopover={setPopover} />
        {auglist.map(
          aug => <AugProps key={aug} aug={aug} css={css} onCSSChanged={onCSSChanged} setPopover={setPopover} />
        )}
      </div>
    )
  }
}

export default AugmentedUIBuilder
