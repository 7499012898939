import React, { Component } from 'react'
import AugPosistionOptions from './AugPosistionOptions'
import magicComponent from 'magic-react-component'
import { getCSSPropValue, replaceCSS } from '../css-helpers'

const GridLayout = magicComponent("div", `
  display: grid;
  grid-template-columns: 50px 1fr;
  grid-template-rows: 150px;
  grid-gap: 5px;
`)

const AugUIContainer = magicComponent("div", `
  position: relative;
  border: 1px solid;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr;

  .aug-preview {
    position: absolute;
    left: 10px;
    top: 10px;
    right: 10px;
    bottom: 10px;
    --aug-t: 10px;
    --aug-r: 10px;
    --aug-b: 10px;
    --aug-l: 10px;
    --aug-t-origin-x: 50%;
    --aug-r-origin-y: 50%;
    --aug-b-origin-x: 50%;
    --aug-l-origin-y: 50%;
    pointer-events: none;
    --aug-border: 3px;
  }
  .augs__button {
    opacity: 0.125;
    background-color: transparent;
    border: none;
    cursor: pointer;
  }
  .executable-option {
    position: relative;
  }
  .executable-option__label {
    position: absolute;
    top: 50%;
    left: 50%;
    height: 0px;
    transform: translate(-50%, -0.9em);
    font-weight: bold;
    opacity: 0.5;
  }
`, {
  backgroundColor: "gray800",
  borderColor: "gray900",
  ".aug-preview": {
    "--aug-border-bg": ({magicScale}) => magicScale("colors", "gray600"),
    "--aug-inset-bg": ({magicScale}) => magicScale("colors", "gray500"),
    "--aug-inset-opacity": "0.25"
  },
  ".augs__button--light": {
    backgroundColor: "gray600"
  },
  ".augs__button:hover, .augs__button:focus": {
    backgroundColor: "gray600",
    opacity: 0.5
  },
  ".executable-option__label": {
    fontSize: 3,
    color: "gray600",
    textShadow: ({magicScale}) => "0 0 10px " + magicScale("colors", "gray900") +
                                  ", 1px 1px 1px " + magicScale("colors", "gray900") +
                                  ", -1px -1px 1px " + magicScale("colors", "gray900") 
  }
})

class Augs extends Component {
  // props: augs, onValueChanged, setPopover, css, onCSSChanged

  state = {
    tl: "Select Top-Left Aug",
    t: "Select Top Aug",
    tr: "Select Top-Right Aug",
    r: "Select Right Aug",
    br: "Select Bottom-Right Aug",
    b: "Select Bottom Aug",
    bl: "Select Bottom-Left Aug",
    l: "Select Left Aug"
  }

  cleanUpAugCSS = (position, newAug, oldAug) => {
    let css = this.props.css
    let prop = "--aug-" + position
    let old = getCSSPropValue(css, prop)
    if (old && !newAug) { css = replaceCSS(css, prop, old, "") }

    old = getCSSPropValue(css, prop + "-width")
    if (old) { css = replaceCSS(css, prop + "-width", old, "") }

    old = getCSSPropValue(css, prop + "-height")
    if (old) { css = replaceCSS(css, prop + "-height", old, "") }

    old = getCSSPropValue(css, prop + "-origin-x")
    if (old) { css = replaceCSS(css, prop + "-origin-x", old, "") }

    old = getCSSPropValue(css, prop + "-origin-y")
    if (old) { css = replaceCSS(css, prop + "-origin-y", old, "") }

    old = getCSSPropValue(css, prop + "-offset")
    if (old) { css = replaceCSS(css, prop + "-offset", old, "") }

    this.props.onCSSChanged(css)
  }

  augSelected = (position, aug, oldAug, refocusEl) => {
    this.props.setPopover()

    const augs = (this.props.augs || "").trim().replace(/\s+/g, " ").split(" ")
    const oldAugIndex = augs.indexOf(oldAug)
    if (oldAugIndex > -1) {
      augs[oldAugIndex] = aug
    } else if (aug) {
      augs.push(aug)
    }

    const orderedAugs = new Array(9)
    const order = {tl: 0, t: 1, tr: 2, r: 3, br: 4, b: 5, bl: 6, l: 7}
    const posRx = /^(tl|t|tr|r|br|b|bl|l)-.*|(.*)/
    orderedAugs[8] = "exe"
    augs.forEach(unorderedAug => {
      const pos = unorderedAug.replace(posRx, "$1$2")
      const index = order[pos]
      orderedAugs[index === undefined ? 8 : index] = unorderedAug
    })
    const orderedAugString = orderedAugs.join(" ").replace(/\s+/g, " ").trim()
    this.props.onValueChanged(orderedAugString)

    if (oldAugIndex > -1) {
      this.cleanUpAugCSS(position, aug, oldAug)
    }
    refocusEl && refocusEl.focus()
  }

  chooseAugDialog = (position, refocusEl) => {
    position = position.substr(0, 2)
    const augs = this.props.augs || ""
    const selectedAug = augs.replace(new RegExp(".*\\b(" + position + "-[^ \\b]+).*|.*"), "$1")

    this.props.setPopover(
      this.state[position],
      (<AugPosistionOptions selectedAug={selectedAug} position={position}
        onValueChanged={val => this.augSelected(position, val, selectedAug, refocusEl)} />),
      () => refocusEl && refocusEl.focus()
    )
  }

  render () {
    const state = this.state
    const props = this.props
    const chooseAugDialog = this.chooseAugDialog

    return (
      <GridLayout>
        <span>Augs</span>
        <AugUIContainer>
          <div className="aug-preview" data-augmented-ui={props.augs}></div>
          <button aria-label={state.tl} onClick={ev => chooseAugDialog("tl", ev.target)} className="augs__button"></button>
          <button aria-label={state.t } onClick={ev => chooseAugDialog("t" , ev.target)} className="augs__button augs__button--light"></button>
          <button aria-label={state.tr} onClick={ev => chooseAugDialog("tr", ev.target)} className="augs__button"></button>

          <button aria-label={state.l } onClick={ev => chooseAugDialog("l" , ev.target)} className="augs__button augs__button--light"></button>
          <div className="executable-option"><span className="executable-option__label"></span></div>
          <button aria-label={state.r } onClick={ev => chooseAugDialog("r" , ev.target)} className="augs__button augs__button--light"></button>

          <button aria-label={state.bl} onClick={ev => chooseAugDialog("bl", ev.target)} className="augs__button"></button>
          <button aria-label={state.b } onClick={ev => chooseAugDialog("b" , ev.target)} className="augs__button augs__button--light"></button>
          <button aria-label={state.br} onClick={ev => chooseAugDialog("br", ev.target)} className="augs__button"></button>
        </AugUIContainer>
      </GridLayout>
    )
  }
}

export default Augs
