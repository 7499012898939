import React, { Component } from 'react'
import magicComponent from 'magic-react-component'
import ValueColorStop from './ValueColorStop'
import { parseColorStopArrayIntoCSSLevel3 } from './gradient-helpers'

const ColorStopList = magicComponent("div", `
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 5px;
  align-items: center;
  vertical-align: middle;
  border-top: 1px solid;

  .span--3 {
    grid-column-end: span 3;
  }
`, {
  pt: 1,
  borderColor: "gray800"
})

class ValueColorStopList extends Component {
  // props: cssValueArray, onValueChanged, setPopover

  state = {}

  joinParsedColorStops = parsedColorStops => {
    return parsedColorStops.reduce((str, cs) => str + ", " + cs.cssValue, "").replace(/^, /, "").trim()
  }

  colorStopChanged = (newVal, parsedColorStops, index) => {
    const props = this.props
    let listValue = ""
    newVal = newVal.trim()
    if (!newVal) {
      listValue = this.joinParsedColorStops(parsedColorStops.filter((v, x) => x !== index))
    } else {
      listValue = this.joinParsedColorStops(parsedColorStops.map((v, x) => x === index ? { cssValue: newVal } : v))
    }
    props.onValueChanged && props.onValueChanged(listValue)
  }

  render () {
    const props = this.props
    const cssValueArray = props.cssValueArray
    const parsedColorStops = parseColorStopArrayIntoCSSLevel3(cssValueArray)
    const colorStopChanged = this.colorStopChanged

    return (
      <ColorStopList className={props.className}>
        <div className="span--3" aria-label="Color Stop List">
          Color Stop List
        </div>
        {parsedColorStops.map((cs, index) => (
          <ValueColorStop key={index} cssValue={cs.cssValue} setPopover={props.setPopover}
            onValueChanged={val => colorStopChanged(val, parsedColorStops, index)}
            onDeleteClicked={
              parsedColorStops.length > 2
                ? () => colorStopChanged("", parsedColorStops, index)
                : null
            }
            onAddClicked={() => colorStopChanged(cs.cssValue + ", " + cs.color, parsedColorStops, index)} />
        ))}
      </ColorStopList>
    )
  }
}

export default ValueColorStopList
