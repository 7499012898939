import React, { Component } from 'react'
import { pointerLastPos, pointerCurrentPos, dragSettings } from '../../utils/pointer-drag'
import magicComponent from 'magic-react-component'

const SliderContainer = magicComponent("div", `
  position: relative;
  --augslider__gap: var(--gap, 5px);
  --augslider__handleWidth: var(--handleSize, 25px);
  --augslider__handleWidthSansGap: calc(var(--augslider__handleWidth) - var(--augslider__gap) * 2);
  --augslider__value: var(--value, 0);
  width: 100%;
  --augslider__height: calc(var(--augslider__handleWidthSansGap) + var(--augslider__gap) * 2);
  height: var(--augslider__height);

  --augslider__trackwidth: (100% - var(--augslider__gap) * 2 - var(--augslider__handleWidthSansGap)) * var(--augslider__value);

  .slider__track,
  .slider__handle {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .slider__track {
    width: 100%;
    height: 100%;
    --aug-border: 1px;
    --aug-border-bg: black;
    --aug-tl-height: calc(var(--augslider__height) / 2 - var(--augslider__gap) / 2);
    --aug-bl-height: calc(var(--augslider__height) / 2 - var(--augslider__gap) / 2);
    --aug-tr-height: calc(var(--augslider__height) / 2 - var(--augslider__gap) / 2);
    --aug-br-height: calc(var(--augslider__height) / 2 - var(--augslider__gap) / 2);

    --aug-tl-width: calc(var(--augslider__trackwidth) + var(--augslider__handleWidthSansGap) / 2 + var(--augslider__gap) * 0.75);
    --aug-bl-width: calc(var(--augslider__trackwidth) + var(--augslider__handleWidthSansGap) / 2 + var(--augslider__gap) * 0.75);

    --aug-tr-width: calc(100% - var(--augslider__trackwidth) - var(--augslider__handleWidthSansGap) / 2 - var(--augslider__gap) * 1.25);
    --aug-br-width: calc(100% - var(--augslider__trackwidth) - var(--augslider__handleWidthSansGap) / 2 - var(--augslider__gap) * 1.25);
  }
  .slider__handle {
    left: calc(var(--augslider__trackwidth) + var(--augslider__handleWidthSansGap) / 2 + var(--augslider__gap) * 1.25);
    width: var(--augslider__handleWidth);
    height: var(--augslider__handleWidth);
    cursor: pointer;
  }
`, {
  ".slider__track": {
    // backgroundColor: "gray600",
    background: "var(--track-bg, linear-gradient(to right, black 10%, gold 90%))"
  },
  ".slider__handle": {
    backgroundColor: "transparent"
  }
})

class ValueSlider extends Component {
  // props: onValueChange, value [0, 1], handleSize, gap

  state = {}

  sliding = dragData => {
    const props = this.props;
    const handleSize = props.handleSize || 25
    const dif = pointerCurrentPos.x - pointerLastPos.x
    const el = dragData
    const oldVal = props.value
    const newVal = Math.min(Math.max(oldVal + dif / (el.offsetWidth - handleSize), 0), 1)
    props.onValueChange && props.onValueChange(newVal)
  }

  dragInit = ev => {
    if (ev && ev.button !== 0) {
      return
    }
    ev && ev.preventDefault()
    Object.assign(dragSettings, { drag: this.sliding, data: ev.target.parentNode.querySelector(".slider__track") })
  }

  render () {
    const props = this.props;
    const handleSize = props.handleSize || 25
    const gap = props.gap || 5

    return (
      <SliderContainer style={{ "--value": props.value || 0, "--handleSize": handleSize + "px", "--gap": gap + "px" }}>
        <div className="slider__track" data-augmented-ui="tl-clip-x tr-clip-x bl-clip-x br-clip-x exe"></div>
        <div className="slider__handle" onPointerDown={this.dragInit}></div>
      </SliderContainer>
    )
  }
}

export default ValueSlider
