import React, { Component } from 'react'
import ValueCSSTypeCombo from '../ValueCSSTypeCombo'
import ValueSlider from '../ValueSlider'
import magicComponent from 'magic-react-component'
import colors from './color-helpers'

const SlidersGrid = magicComponent("div", `
  display: grid;
  grid-template-columns: 12px calc(33% - 17px) 1fr 1fr;
  grid-gap: 5px;
  align-items: center;
  vertical-align: middle;
  .span--2 {
    grid-column-end: span 2;
  }
`, {
  color: "gray200"
})

class ValueRGBSliders extends Component {
  // props: cssValue, onValueChanged

  state = {}

  updateRGBPropValue = (rgbObj, prop, newVal) => {
    const newRGBObj = Object.assign({}, rgbObj, { [prop]: newVal })
    const strFn = newRGBObj.a === 1 ? colors.rgbObjToRgbString : colors.rgbObjToRgbaString
    const newStr = strFn(newRGBObj)
    this.props.onValueChanged && this.props.onValueChanged(newStr)
  }

  render () {
    const props = this.props
    const cssValue = props.cssValue
    const resolvedColor = colors.resolveAnyColorStr(cssValue)
    const rgbObj = colors.anyColorStringToRGBObj(resolvedColor)
    const updateRGBPropValue = this.updateRGBPropValue

    const redBG = `linear-gradient(to right,
                                    rgb(0, ${rgbObj.g}, ${rgbObj.b}) 10px,
                                    rgb(128, ${rgbObj.g}, ${rgbObj.b}),
                                    rgb(255, ${rgbObj.g}, ${rgbObj.b}) calc(100% - 10px)
    )`
    const greenBG = `linear-gradient(to right,
                                    rgb(${rgbObj.r}, 0, ${rgbObj.b}) 10px,
                                    rgb(${rgbObj.r}, 128, ${rgbObj.b}),
                                    rgb(${rgbObj.r}, 255, ${rgbObj.b}) calc(100% - 10px)
    )`
    const blueBG = `linear-gradient(to right,
                                    rgb(${rgbObj.r}, ${rgbObj.g}, 0) 10px,
                                    rgb(${rgbObj.r}, ${rgbObj.g}, 128),
                                    rgb(${rgbObj.r}, ${rgbObj.g}, 255) calc(100% - 10px)
    )`
    return (
      <SlidersGrid>
        <span>R</span>
        <div className="span--2" style={{"--track-bg": redBG}}>
          <ValueSlider value={rgbObj.r / 255} onValueChange={v => updateRGBPropValue(rgbObj, "r", Math.round(v * 255))} />
        </div>
        <ValueCSSTypeCombo useLabel="RGB Red Value"
          type={["R"]} onValueChanged={v => updateRGBPropValue(rgbObj, "r", Math.round(parseFloat(v)))}
          valmin={0} valmax={255} cssValue={rgbObj.r + "R"} />

        <span>G</span>
        <div className="span--2" style={{"--track-bg": greenBG}}>
          <ValueSlider value={rgbObj.g / 255} onValueChange={v => updateRGBPropValue(rgbObj, "g", Math.round(v * 255))} />
        </div>
        <ValueCSSTypeCombo useLabel="RGB Green Value"
          type={["G"]} onValueChanged={v => updateRGBPropValue(rgbObj, "g", Math.round(parseFloat(v)))}
          valmin={0} valmax={255} cssValue={rgbObj.g + "G"} />

        <span>B</span>
        <div className="span--2" style={{"--track-bg": blueBG}}>
          <ValueSlider value={rgbObj.b / 255} onValueChange={v => updateRGBPropValue(rgbObj, "b", Math.round(v * 255))} />
        </div>
        <ValueCSSTypeCombo useLabel="RGB Blue Value"
          type={["B"]} onValueChanged={v => updateRGBPropValue(rgbObj, "b", Math.round(parseFloat(v)))}
          valmin={0} valmax={255} cssValue={rgbObj.b + "B"} />

        <span>A</span>
        <div className="span--2">
          <ValueSlider value={rgbObj.a} onValueChange={v => updateRGBPropValue(rgbObj, "a", v)} />
        </div>
        <div>
          <ValueCSSTypeCombo useLabel={"Color Alpha Value"} step={0.01}
            type={["A"]} onValueChanged={v => updateRGBPropValue(rgbObj, "a", parseFloat(v))}
            valmin={0} valmax={1} cssValue={rgbObj.a + "A"} />
        </div>
      </SlidersGrid>
    )
  }
}

export default ValueRGBSliders
