import React, { Component } from 'react'
import keyMap from '../../utils/key-map'
import magicComponent from 'magic-react-component'

const OverlayEscaper = magicComponent("div", `
  z-index: 999;
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  opacity: 0.87;
`, {
  backgroundColor: "gray900"
})

const OverlayTitle = magicComponent("div", `
  z-index: 999;
  position: absolute;
  pointer-events: none;
  width: 50%;
  min-width: 600px;
  height: 32px;
  top: 5%;
  left: 50%;
  transform: translateX(-50%);
  font-family: monospace;
  text-transform: uppercase;
  .overlay__x {
    position: absolute;
    right: 0px;
    top: 0px;
    font-weight: bold;
    line-height: 12px;
    transform: scaleX(1.5);
    opacity: 0.5;
  }
  .overlay__logo {
    position: absolute;
    height: 50px;
    top: -10px;
    left: 10px;
  }
  h2 {
    position: absolute;
    top: 5%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-shadow: 2px 0px 10px black, 0px 2px 10px black, -2px 0px 10px black, 0px -2px 10px black;
  }
`, {
  color: "gold",
  m: 0,
  p: 0,
  pl: 1,
  h2: {
    fontSize: 7,
    m: 0,
    p: 0
  },
  ".overlay__x": {
    fontSize: 4,
    pr: 2
  }
})

const OverlayPageContent = magicComponent("div", `
  z-index: 999;
  position: absolute;
  width: 50%;
  min-width: 600px;
  height: 90%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: linear-gradient(rgba(0, 0, 0, 0.75),rgba(0, 0, 0, 0.75)), url('/logo400--transparent.png') no-repeat calc(100% - 20px) bottom / 45% auto, var(--aug-inset-bg);
  border-radius: 2px;
  box-shadow: 0 0 2px black;
  --aug-tl-height: 40px;
  --aug-tl-width: 100px;
  --aug-t-origin-x: 50%;
  --aug-t: 25px;
  --aug-t-width: 250px;
  --aug-tr-width: 40px;
  --aug-b: 10px;
  --aug-inset: 0px;
  --aug-inset-opacity: 0.87;
  --aug-border: 2px;
  --aug-border-bg: linear-gradient(to bottom right, gold, transparent, gold),
                    linear-gradient(to bottom left, gold, transparent, transparent, gold);
  &:before {
    mask-image: repeating-linear-gradient(to bottom, black, black 2px, transparent 5px);
  }
`, {
  "--aug-inset-bg": ({magicScale}) => magicScale("colors", "gray900"),
  p: 3,
  pt: "50px",
  color: "gold"
})

class OverlayContainer extends Component {
  // props: title, children, exitCallback

  constructor (props) {
    super(props)
    this.focusFirstOptionOnOpen = React.createRef()
  }

  state = {}

  componentDidMount () {
    const refel = this.focusFirstOptionOnOpen && this.focusFirstOptionOnOpen.current
    if (refel && refel.querySelector) {
      const el = refel.querySelector('button, a[href], input, select, textarea, [tabindex]:not([tabindex="-1"])')
      el && el.focus()
    }
  }

  exitOveraly = () => {
    this.props.exitCallback && this.props.exitCallback()
  }

  render () {
    return (
      <React.Fragment>
        <OverlayEscaper onClick={this.exitOveraly} onKeyDown={keyMap({ Escape: this.exitOveraly })} />
        <OverlayTitle className="overlay__title">
          <h2 id="overlay__title">{this.props.title}</h2>
          <img className="overlay__logo" alt="app logo" aria-hidden="true" src="/logo400--transparent.png" />
          <span className="overlay__x" aria-hidden="true">X</span>
        </OverlayTitle>
        <OverlayPageContent role="dialog" aria-labelledby="overlay__title"
          onKeyDown={keyMap({ Escape: this.exitOveraly })}
          ref={this.focusFirstOptionOnOpen}
          data-augmented-ui="tl-clip-x t-clip-x tr-clip-x bl-clip b-clip br-clip exe"
        >
          {this.props.children}
        </OverlayPageContent>
      </React.Fragment>
    )
  }
}

export default OverlayContainer
