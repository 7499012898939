import React, { Component } from 'react'
import magicComponent from 'magic-react-component'

const ButtonEl = magicComponent("button", `
  text-align: center;
  cursor: pointer;
  border: none;
  background: none;
  --aug-tl: 12px;
  --aug-br: 12px;
  --aug-border: 2px;
  --aug-border-bg: radial-gradient(20px 10px ellipse at top left, gold 20px, transparent 50px),
                   radial-gradient(20px 10px ellipse at bottom right, gold 20px, transparent 50px);
  --aug-inset: 1px;

  &.button--error {
    --aug-tl: 5px;
    --aug-br: 5px;
    --aug-tr-width: 60%;
    --aug-tr-height: 2px;
    --aug-b: 4px;
    --aug-b-width: -1px; /* aug border and inset make the notch effectively larger but border is invisible here */
    --aug-b-origin-x: 20%;
  }

  &:focus {
    --aug-border-bg: transparent
  }
`, {
  "--aug-inset-bg": ({magicScale}) => `linear-gradient(${magicScale("colors", "gray900")}, black)`,
  "&:focus": {
    "--aug-border-bg": ({magicScale}) => magicScale("colors", "blue700"),
    backgroundColor: "gray800"
  },
  color: "gray100",
  py: 2,
  px: 3,
  m: 1
})

class Button extends Component {
  // props: variant ("normal", "error")

  state = {}

  render () {
    const props = this.props
    const variant = props.variant || "normal"
    const augs = variant === "error" ? "tl-rect br-rect b-rect tr-clip-x exe" : "tl-clip br-clip exe"
    const propsClone = Object.assign({}, props)
    propsClone.className = (props.className || "") + " button--" + variant

    return (
      <ButtonEl data-augmented-ui={augs} {...propsClone}>{props.children}</ButtonEl>
    )
  }
}

export default Button
