import React, { Component } from 'react'
import keyMap from '../../../utils/key-map'
import magicComponent from 'magic-react-component'

const PositionOptions = magicComponent("div", `
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: 70px 70px;
  grid-gap: 7px;
  grid-row-gap: 10px;

  .augpos__button {
    position: relative;
    cursor: pointer;
    margin: 0px;
    padding: 0px;
    border: none;
  }
  .augpos__button__preview {
    position: absolute;
    background: var(--active-color, black);
    --aug-t: 10px;
    --aug-r: 10px;
    --aug-b: 10px;
    --aug-l: 10px;
  }
  .augpos__button__preview--tl {
    top: 15px;
    left: 15px;
    right: 0px;
    bottom: 10px;
  }
  .augpos__button__preview--t {
    top: 15px;
    left: 0px;
    right: 0px;
    bottom: 10px;
  }
  .augpos__button__preview--tr {
    top: 15px;
    left: 0px;
    right: 15px;
    bottom: 10px;
  }
  .augpos__button__preview--r {
    top: 0px;
    left: 0px;
    right: 15px;
    bottom: 10px;
  }
  .augpos__button__preview--br {
    top: 0px;
    left: 0px;
    right: 15px;
    bottom: 25px;
  }
  .augpos__button__preview--b {
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 25px;
  }
  .augpos__button__preview--bl {
    top: 0px;
    left: 15px;
    right: 0px;
    bottom: 25px;
  }
  .augpos__button__preview--l {
    top: 0px;
    left: 15px;
    right: 0px;
    bottom: 10px;
  }
  .augpos__button__label {
    position: absolute;
    bottom: -1px;
    left: 0px;
    right: 0px;
    text-align: center;
    white-space: nowrap;
  }
`, {
  ".augpos__button": {
    backgroundColor: "black",
    "--active-color": ({magicScale}) => `linear-gradient(to top left, ${magicScale("colors", "gray200")}, ${magicScale("colors", "gray700")})`,
    "&:hover, &:focus": {
      outline: ({magicScale}) => "2px dashed " + magicScale("colors", "blue700")
    }
  },
  ".augpos__button--active": {
    backgroundColor: "black",
    outline: ({magicScale}) => "2px solid " + magicScale("colors", "gold"),
    "--active-color": ({magicScale}) => magicScale("colors", "gold")
  },
  ".augpos__button__label": {
    backgroundColor: "gray700",
    color: "gray100",
    fontSize: 1
  }
})

class AugPosistionOptions extends Component {
  // props: onValueChanged, position, selectedAug

  state = {
    tl: ["", "tl-clip", "tl-rect", "tl-clip-x", "tl-clip-y", "tl-round"],
    t: ["", "t-clip", "t-rect", "t-clip-x", "t-clip-y"],
    tr: ["", "tr-clip", "tr-rect", "tr-clip-x", "tr-clip-y", "tr-round"],
    r: ["", "r-clip", "r-rect", "r-clip-x", "r-clip-y"],
    br: ["", "br-clip", "br-rect", "br-clip-x", "br-clip-y", "br-round"],
    b: ["", "b-clip", "b-rect", "b-clip-x", "b-clip-y"],
    bl: ["", "bl-clip", "bl-rect", "bl-clip-x", "bl-clip-y", "bl-round"],
    l: ["", "l-clip", "l-rect", "l-clip-x", "l-clip-y"]
  }

  focusPrev = ev => {
    const target = ev.target || {}
    const nextTarget = target.previousElementSibling
    nextTarget && nextTarget.focus && nextTarget.focus()
  }

  focusNext = ev => {
    const target = ev.target || {}
    const nextTarget = target.nextElementSibling
    nextTarget && nextTarget.focus && nextTarget.focus()
  }

  render () {
    const props = this.props
    const position = props.position
    const selectedAug = props.selectedAug
    const auglist = this.state[position]

    return (
      <PositionOptions role="listbox" aria-label="Available Augs">
        {auglist.map(aug => (
          <button key={aug}
            role="option"
            aria-selected={selectedAug === aug ? "true" : "false"}
            onKeyDown={keyMap({
              ArrowUp: this.focusPrev,
              ArrowDown: this.focusNext
            })}
            className={"augpos__button" + (selectedAug === aug ? " augpos__button--active" : "")}
            onClick={() => props.onValueChanged && props.onValueChanged(aug)}
          >
            <div className={"augpos__button__preview augpos__button__preview--" + position} data-augmented-ui={aug + " exe"}></div>
            <span className="augpos__button__label">{aug || "none"}</span>
          </button>
        ))}
      </PositionOptions>
    )
  }
}

export default AugPosistionOptions
