import React, { Component } from 'react'
import keyMap from '../../utils/key-map'
import magicComponent from 'magic-react-component'

const PopoverEscaper = magicComponent("div", `
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  opacity: 0.5;
`, {
  backgroundColor: "black"
})

const PopoverContent = magicComponent("div", `
  position: absolute;
  left: 0px;
  right: 0px;
  top: 50%;
  transform: translateY(-50%);
  border-radius: 2px;
  box-shadow: 0 0 2px black;
`, {
  backgroundColor: "gray700",
  p: 1,
  pb: 2,
  ".popover__title": {
    color: "gray100",
    fontSize: 3,
    mb: 2
  }
})

class PopoverContainer extends Component {
  constructor (props) {
    super(props)
    this.focusFirstOptionOnOpen = React.createRef()
  }

  state = {}

  componentDidMount () {
    const refel = this.focusFirstOptionOnOpen && this.focusFirstOptionOnOpen.current
    if (refel && refel.querySelector) {
      const el = refel.querySelector('button, a[href], input, select, textarea, [tabindex]:not([tabindex="-1"])')
      el && el.focus()
    }
  }

  cancelPopover = () => {
    this.props.cancelCallback && this.props.cancelCallback()
  }

  render () {
    return (
      <React.Fragment>
        <PopoverEscaper onClick={this.cancelPopover} onKeyDown={keyMap({ Escape: this.cancelPopover })} />
        <PopoverContent role="dialog" aria-labelledby="popover__title"
          onKeyDown={keyMap({ Escape: this.cancelPopover })}
          ref={this.focusFirstOptionOnOpen}
        >
          <div id="popover__title" className="popover__title">{this.props.title}</div>
          {this.props.children}
        </PopoverContent>
      </React.Fragment>
    )
  }
}

export default PopoverContainer
