import React, { Component } from 'react'
import PopoverContainer from './PopoverContainer'
import FoldableSection from './FoldableSection'
import BackgroundBuilder from './backgrounds/BackgroundBuilder'
import AugmentedUIBuilder from './augmented-ui/AugmentedUIBuilder'
import magicComponent from 'magic-react-component'
import { heldKeys } from '../../utils/key-state'

const ScrollArea = magicComponent("div", `
  height: 100%;
  overflow-y: auto;
  position: relative;
  padding-bottom: 50px;
`)

class CSSBuilder extends Component {
  // props: el, classManager, elementManager

  state = {
    popoverTitle: null,
    showPopover: null,
    cancelCallback: null
  }

  setPopover = (title, popoverJSX, uiCancelledCallback) => {
    this.setState({
      popoverTitle: title,
      showPopover: popoverJSX,
      cancelCallback: uiCancelledCallback
    })
  }

  cancelPopover = () => {
    this.state.cancelCallback && this.state.cancelCallback()
    this.setState({
      popoverTitle: null,
      showPopover: null,
      cancelCallback: null
    })
  }

  redirectFocusTrap = (fromBottom) => {
    const doFocus = el => el && el.focus && el.focus()
    const trapEl = document.activeElement
    const shiftHeld = !!heldKeys.Shift
    // shiftHeld && top trap -> app info button outside form
    if (shiftHeld && !fromBottom) {
      // focus the app info button
      doFocus(document.querySelector("[title='App Info']"))
      return
    }
    const focusableSelector = `button, a[href], input, select, textarea, [tabindex]:not([tabindex="-1"]):not(.tab-trap)`
    const popoverEl = (document.getElementById("popover__title") || {}).parentNode
    const focusableElsInsidePopover = popoverEl && popoverEl.querySelectorAll(focusableSelector)
    const scrollAreaEl = document.querySelector(".scroll-area")
    const focusableScrollAreaEls = scrollAreaEl.querySelectorAll(focusableSelector)
    const popoverOpen = !!this.state.showPopover
    // shiftHeld && bot trap -> popoverOpen ? bottom inside popover : bottom of scroll-area
    if (shiftHeld && fromBottom) {
      if (popoverOpen) {
        // bottom inside popover
        doFocus(focusableElsInsidePopover[focusableElsInsidePopover.length - 1])
      } else {
        // bottom of scroll-area
        doFocus(focusableScrollAreaEls[focusableScrollAreaEls.length - 1])
      }
      return
    }
    // shiftNOTheld && top trap -> popoverOpen ? top inside popover : first el in scroll-area
    if (!fromBottom) {
      if (popoverOpen) {
        // top inside popover
        doFocus(focusableElsInsidePopover[0])
      } else {
        // first el in scroll-area
        doFocus(focusableScrollAreaEls[0])
      }
      return
    }
    // shiftNOTheld && bot trap -> blur?
    trapEl && trapEl.blur()
  }

  render () {
    const props = this.props
    const state = this.state
    const popover = state.showPopover ? (
      <PopoverContainer cancelCallback={this.cancelPopover} title={state.popoverTitle}>{state.showPopover}</PopoverContainer>
    ) : null
    const { el, classManager, elementManager } = props
    // TODO: show dropdown of classes at the top to allow more than 1 class to be edited from selected element in future
    const className = el.classes[0]
    const onCSSChanged = newCSS => classManager.onCSSChanged(className, newCSS)
    const onAugsChanged = newAugs => elementManager.onDataChanged(el.key, "augmented-ui", newAugs)
    const css = classManager.getCSS(className)
    const augs = elementManager.getData(el.key, "augmented-ui") || ""
    const setPopover = this.setPopover

    return (
      <React.Fragment>
        <ScrollArea className="scroll-area hide-scrollbars">
          <div aria-hidden="true" tabIndex="0" className="tab-trap" style={{height: 0, width: 0}} onFocus={ev => this.redirectFocusTrap()}></div>
          <FoldableSection title="Element Background">
            <BackgroundBuilder css={css} onCSSChanged={onCSSChanged} setPopover={setPopover} />
          </FoldableSection>
          <FoldableSection title="augmented-ui">
            <AugmentedUIBuilder setPopover={setPopover}
              augs={augs} onAugsChanged={onAugsChanged}
              css={css} onCSSChanged={onCSSChanged} />
          </FoldableSection>
          <div aria-hidden="true" tabIndex="0" className="tab-trap" style={{height: 0, width: 0}} onFocus={ev => this.redirectFocusTrap(true)}></div>
        </ScrollArea>
        {popover}
      </React.Fragment>
    )
  }
}

export default CSSBuilder
