import React, { Component } from 'react'
import CanvasComponent from './Canvas'
import Element from './Element'
import PrismCode from './PrismCode'
import Pages from './pages/Pages'
import CSSBuilder from './css-builder/CSSBuilder'
import normalizedEventKey from '../utils/normalized-event-key.js'
import { GridLayout, Title, HeaderBar, ByLine, Css, Canvas, Gui } from './LayoutStyledComponents'
// import { exampleElements, exampleElementSets } from './exampleElements'
// import localforage from 'localforage'

class LayoutManager extends Component {
  // props:  classManager, elementManager

  state = {
    showPageOverlay: null,
    canvasBg: "black",
    selectedElKey: null // randomExampleSet[~~(Math.random() * randomExampleSet.length)]
  }

  componentDidMount () {
    this.setState({ selectedElKey: this.props.elementManager.currentElements[0] })
    document.addEventListener("keydown", this.exitOnEscape)
  }

  componentWillUnmount () {
    document.removeEventListener("keydown", this.exitOnEscape)
  }

  exitPageOverlay = () => this.setState({ showPageOverlay: null })
  exitOnEscape = ev => normalizedEventKey(ev) === "Escape" ? this.exitPageOverlay() : null

  setCanvasBg = canvasBg => this.setState({ canvasBg })

  showElementSettings = el => this.setState({ showPageOverlay: "Element" })

  getHtml = () => {
    const state = this.state
    const selectedElKey = state.selectedElKey
    const { elementManager } = this.props
    const selectedEl = selectedElKey && elementManager.getEl(selectedElKey)
    if (!selectedEl) {
      return ""
    }
    const augs = (elementManager.getData(selectedEl.key, "augmented-ui") || "").trim()
    const className = selectedEl.classes.join(" ") || "class-selector"

    return `<div class="${className}"\n  augmented-ui="${augs}"\n></div>`
  }

  getCss = () => {
    const state = this.state
    const selectedElKey = state.selectedElKey
    const { classManager, elementManager } = this.props
    const selectedEl = selectedElKey && elementManager.getEl(selectedElKey)
    if (!selectedEl) {
      return ""
    }
    // TODO: loop these to support more than 1 class in future
    const className = selectedEl.classes[0] || "class-selector"
    const css = "\n" + classManager.getCSS(className).replace(/^\s+|\s+$/gim, "").replace(/^/gm, "  ") + "\n"

    return `.${className} {${css}}`
  }

  selectAllOnFocus = ev => {
    // https://stackoverflow.com/a/2838358
    const el = ev.target, doc = document
    let sel, range
    if (window.getSelection && doc.createRange) {
      sel = window.getSelection()
      range = doc.createRange()
      range.selectNodeContents(el)
      sel.removeAllRanges()
      sel.addRange(range)
    } else if (doc.body.createTextRange) {
      range = doc.body.createTextRange()
      range.moveToElementText(el)
      range.select()
    }
  }

  deselectAllOnBlur = () => {
    // https://stackoverflow.com/a/6562764
    if (window.getSelection) {
      window.getSelection().removeAllRanges()
    } else if (document.selection) {
      document.selection.empty()
    }
  }

  render () {
    const state = this.state
    const selectedElKey = state.selectedElKey
    const { classManager, elementManager } = this.props
    const selectedEl = selectedElKey && elementManager.getEl(selectedElKey)
    // focus title of selected element when the overlay closes
    const overlayExit = () => {
      this.exitPageOverlay()
      const focusEl = document.querySelector(".key--" + selectedElKey + " ~ .title")
      focusEl && focusEl.focus()
    }
    const selectFirst = () => {
      this.exitPageOverlay()
      this.setState((state, props) => {
        const key = props.elementManager.currentElements[0] || ""
        setTimeout(() => {
          const focusEl = document.querySelector(".key--" + key + " ~ .title")
          focusEl && focusEl.focus()
        })
        return { selectedElKey: key }
      })
    }
    const selectLastAdded = () => {
      this.exitPageOverlay()
      this.setState((state, props) => {
        const els = props.elementManager.currentElements
        const key = els[els.length - 1]
        setTimeout(() => {
          const focusEl = document.querySelector(".key--" + key + " ~ .title")
          focusEl && focusEl.focus()
        })
        return { selectedElKey: key }
      })
    }

    return (
      <GridLayout>
        <Title><img className="app-logo" alt="app logo" src="/logo400--transparent.png" /> Augooiigooey</Title>
        <HeaderBar>integrate your apps with technology</HeaderBar>
        <Css>
          <h2 data-augmented-ui="t-rect b-clip-x l-clip r-clip tr-clip-x exe">
            Get Augmented. <a href="http://augmented-ui.com/">augmented-ui</a>
          </h2>
          <div>
            Your HTML &amp; CSS:
          </div>
          <section className="output" aria-label="Generated HTML and CSS Output"
            tabIndex="0"
            onFocus={this.selectAllOnFocus}
            onBlur={this.deselectAllOnBlur}
          >
            <div className="scrollable hide-scrollbars">
              <PrismCode code={this.getHtml()} language="html" />
              <PrismCode code={this.getCss()} language="css" />
            </div>
          </section>
        </Css>
        <Canvas magic={{backgroundColor: state.canvasBg}}>
          <CanvasComponent
            bgCallback={this.setCanvasBg.bind(this)}
            defaultColor={state.canvasBg}
            showPage={page => this.setState({ showPageOverlay: page })}
          >
            {elementManager.currentElements.map(key => {
              const el = elementManager.getEl(key)
              // note: resize handling here is dirty, but nothing reacts to that data changing, only used if user saves
              return (
                <Element key={key} el={el}
                  onResize={whtl => Object.assign(el, whtl)}
                  onSelected={key => this.setState({ selectedElKey: key })}
                  active={key === selectedElKey}
                  elementSettings={() => this.showElementSettings(el)} />
              )
            })}
          </CanvasComponent>
        </Canvas>
        <ByLine>
          <button className="button--byline" title="App Info" onClick={() => this.setState({showPageOverlay: "App Info"})}>
            <i className="button--byline__icon" data-augmented-ui="tl-clip tr-clip br-clip bl-clip exe">
              <i>i</i>
            </i>
            App Info
          </button>
        </ByLine>
        <Gui>
          {selectedEl
            ? <CSSBuilder el={selectedEl} classManager={classManager} elementManager={elementManager} />
            : <div style={{padding: "10px", color: "grey", background: "black"}}>Select an element to edit</div>
          }
        </Gui>
        {state.showPageOverlay
          ? <Pages onExit={overlayExit}
              onRemove={selectFirst}
              onAdd={selectLastAdded}
              title={state.showPageOverlay}
              previewBG={state.canvasBg}
              selectedEl={selectedEl}
              classManager={classManager}
              elementManager={elementManager} />
          : null
        }
      </GridLayout>
    )
  }
}

export default LayoutManager
