import React, { Component } from 'react'
import ImageLayer from './ImageLayer'
import ColorLayer from './ColorLayer'
import NewBGLayer from './NewBGLayer'
import Button from '../../Button'
import magicComponent from 'magic-react-component'
import { getCSSPropValue, replaceCSS, splitTopLevelCommas } from '../css-helpers'
// import { pointerLastPos, pointerCurrentPos, dragSettings } from '../../../utils/pointer-drag'
import { dragSettings } from '../../../utils/pointer-drag'
import keyMap from '../../../utils/key-map'

// <bg-image> || <bg-position> [ / <bg-size> ]? || <repeat-style> || <attachment>
/*
  image:::
                  Draw       Image // Color // Gradient
                              ____
                             |    | Full URL:
                             |__ _| [                      ]

  Layers   (Add New Layer)
     ____
    |    | Color
    |__ _| Hex: #FFCC00  Opacity: 80%
     ____
    |    | Image
    |__ _| URL: http://i.imgur.com/asdsdf.png
     ____
    |    | linear-gradient
    |__ _| [ ][ ][ ][ ][ ][ ][ ][ ]
     ____
    |    | radial-gradient
    |__ _| [ ][ ][ ][ ][ ][ ][ ][ ]

  enhance 34 to 46
  pull back, wait a min, go right, stop
  enhance 57 19
  track 45 left
  stop
  enhance 15 to 23
  give me a hard copy right there
*/

const BGLayers = magicComponent("div", `
  display: grid;
  grid-template-columns: 50px 1fr;
  grid-auto-rows: auto;
  grid-row-gap: 5px;
  grid-gap: 5px;
  align-items: center;

  .bg__layer {
    grid-column-end: span 2;
    border-top: 1px solid;
  }
  .bg__layer:last-child {
    border-bottom: 1px solid;
  }
  .button--newlayer {
    margin: 0;
    padding: 8px;
  }
  .show-drop-zone {
    border-top: 42px solid;
  }
  .grabbed-item {
    display: none;
    border-top: 1px solid;
  }
`, {
  ".bg__layer, .bg__layer:last-child": {
    py: 1,
    borderColor: "gray750"
  },
  ".show-drop-zone, .show-drop-zone:last-child": {
    borderTopColor: "gold"
  }
})

class BackgroundBuilder extends Component {
  // props: css, cssProperty, onCSSChanged, setPopover

  state = {
    showEditorAtIndex: -1,
    dropLayer: null
  }

  identifyLayerTypes = layers => {
    return layers.map((layer, index) => {
      if (layer.includes("repeating-linear-gradient(")) {
        return { index, css: layer, family: "image", type: "repeating-linear-gradient" }
      }
      if (layer.includes("repeating-radial-gradient(")) {
        return { index, css: layer, family: "image", type: "repeating-radial-gradient" }
      }
      if (layer.includes("linear-gradient(")) {
        const color = layer.trim().match(/^linear-gradient\((.*) 0.00px, \1 0.00px\)$/i)
        if (color && color[1]) {
          return { index, css: color[1], family: "color", type: "fake" }
        }
        return { index, css: layer, family: "image", type: "linear-gradient" }
      }
      if (layer.includes("radial-gradient(")) {
        return { index, css: layer, family: "image", type: "radial-gradient" }
      }
      if (layer.includes("conic-gradient(")) {
        return { index, css: layer, family: "image", type: "conic-gradient" }
      }
      if (layer.includes("url(")) {
        return { index, css: layer, family: "image", type: "url" }
      }
      // todo: other image-family options: element(...can't wait for this one...), image(), image-set(), paint(), cross-fade()
      if (layer.includes("rgba(")) {
        return { index, css: layer, family: "color", type: "rgba" }
      }
      if (layer.includes("rgb(")) {
        return { index, css: layer, family: "color", type: "rgb" }
      }
      if (layer.includes("hsla(")) {
        return { index, css: layer, family: "color", type: "hsla" }
      }
      if (layer.includes("hsl(")) {
        return { index, css: layer, family: "color", type: "hsl" }
      }
      // todo: future css colors like lch() and lab()
      if (!layer.includes("(")) {
        // # could show up inside of unhandled element()
        if (layer.includes("#")) {
          return { index, css: layer, family: "color", type: "hex" }
        }
        return { index, css: layer, family: "color", type: "named" }
      }
      // variable based var() ? would need to build var pool in the app... could be fun!
      return { index, css: layer, family: "unhandled", type: "unknown" }
    })
  }

  layerValueChanged = (oldVal, layers, index, newLayerValue) => {
    let reducer = (v, l) => (v + ", " + (l.index === index ? newLayerValue : l.css))
    if (!newLayerValue.trim() || newLayerValue === "transparent") {
      reducer = (v, l) => l.index === index ? v : (v + ", " + l.css)
    }
    const props = this.props
    const newBGValue = layers.reduce(reducer, "").replace(/^, /, "").trim()
    const newCSS = replaceCSS(props.css, props.cssProperty || "background", oldVal, newBGValue)
    props.onCSSChanged(newCSS.replace(/,\s+/g, ", "))
  }

  toggleEditorAtIndex = index => {
    const showEditorAtIndex = this.state.showEditorAtIndex
    if (showEditorAtIndex === index) {
      this.setState({ showEditorAtIndex: -1 })
    } else {
      this.setState({ showEditorAtIndex: index })
    }
  }

  setDropArea = layer => {
    this.setState({
      dropLayer: layer
    })
  }

  moveLayerIndex = (fromIndex, offset, layers, bgCSS) => {
    const numLayers = layers.length
    const endIndex = Math.min(Math.max(0, fromIndex + offset), numLayers)
    const newOrder = []
    let landingIndex = 0
    for (let i = 0; i < numLayers; i++) {
      const curIndex = layers[i].index
      if (curIndex === endIndex) {
        landingIndex = newOrder.length
        newOrder.push(layers[fromIndex])
      }
      if (curIndex !== fromIndex) {
        newOrder.push(layers[i])
      }
    }
    if (endIndex === numLayers) {
      landingIndex = newOrder.length
      newOrder.push(layers[fromIndex])
    }

    newOrder.forEach((l, i) => {
      // promote/demote color layer
      if (i < (numLayers - 1) && l.family === "color" && !l.css.includes("linear-gradient")) {
        const color = l.css.trim()
        l.css = `linear-gradient(${color} 0.00px, ${color} 0.00px)`
      } else if (i === (numLayers - 1) && l.family === "color" && l.type === "fake") {
        const color = (l.css.trim().match(/^linear-gradient\((.*) 0.00px, \1 0.00px\)$/i) || [])[1]
        l.css = color || l.css
      }
    })

    const props = this.props
    const newBGCSS = newOrder.map(l => l.css.trim()).join(", ")
    const newCSS = replaceCSS(props.css, props.cssProperty || "background", bgCSS, newBGCSS)
    props.onCSSChanged(newCSS.replace(/,\s+/g, ", "))
    setTimeout(() => {
      const reselectEl = document.querySelector(".bg__layer--" + landingIndex + " .layer__name")
      reselectEl && reselectEl.focus()
    })
  }

  moveLayer = dragData => {
    const { layer, layers, bgCSS } = dragData
    const dropEndLayer = this.state.dropLayer
    this.setDropArea(null)
    dragData.layerIndex = -1 // next render happens synchronously, before data is cleared
    if (!dropEndLayer || !layer || !layers) {
      return
    }
    this.moveLayerIndex(layer.index, dropEndLayer.index - layer.index, layers, bgCSS)
  }

  dragInit = (ev, layer, layers, bgCSS) => {
    if (ev && ev.button !== 0) { // if event, Left only
      return
    }
    ev && ev.preventDefault()
    // close any expanded layer editors
    this.setState({ showEditorAtIndex: -1 })
    // TODO: draw a ghost instead of () => {}
    Object.assign(dragSettings, { drag: () => {}, data: {
      layerDrag: true,
      layer,
      layerIndex: layer.index,
      layers,
      bgCSS
    }, dragEnd: this.moveLayer, threshold: 5 })
  }

  addLayer = (val, bgCSS) => {
    const props = this.props
    const newBGCSS = bgCSS ? (val.trim() + ", " + bgCSS) : val.trim()
    const newCSS = replaceCSS(props.css, props.cssProperty || "background", bgCSS, newBGCSS)
    props.onCSSChanged(newCSS.replace(/,\s+/g, ", "))
    props.setPopover()
    setTimeout(() => {
      const selectEl = document.querySelector(".for" + (props.cssProperty || "background") + " .bg__layer--0 .layer__name")
      selectEl && selectEl.focus()
    })
  }

  selectNewLayer = (layers, bgCSS) => {
    this.props.setPopover(
      "Create New Background Layer",
      (<NewBGLayer fakeColor={layers.length !== 0} onValueChanged={val => this.addLayer(val, bgCSS)} />),
      () => {
        const selectEl = document.querySelector(".for" + (this.props.cssProperty || "background") + " .button--newlayer")
        selectEl && selectEl.focus()
      }
    )
  }

  deleteLayer = (layers, layerIndex, bgCSS) => {
    const props = this.props
    const newBGValue = layers.filter(l => l.index !== layerIndex).map(l => l.css.trim()).join(", ")
    const newCSS = replaceCSS(props.css, props.cssProperty || "background", bgCSS, newBGValue)
    props.onCSSChanged(newCSS.replace(/,\s+/g, ", "))
    this.setState({ showEditorAtIndex: -1 })
    const selectEl = document.querySelector(".for" + (this.props.cssProperty || "background") + " .button--newlayer")
    selectEl && selectEl.focus()
  }

  render () {
    const state = this.state
    const props = this.props
    const css = (props.css || "").trim()
    const bgProp = props.cssProperty || "background" // augmented-ui has 2 properties that are <background> css type
    const bgCSS = getCSSPropValue(css, bgProp)
    const showEditorAtIndex = state.showEditorAtIndex

    const layers = bgCSS ? this.identifyLayerTypes(
      splitTopLevelCommas(bgCSS)
    ) : []

    const layerClass = layers.length > 1 ? "bg__layer draggable" : "bg__layer"

    return (
      <BGLayers className={"for" + bgProp}>
        <span>Layers</span>
        <Button className="button--newlayer" onClick={ev => this.selectNewLayer(layers, bgCSS)}>+ Add New Layer</Button>
        {layers.map(layer => {
          const isDragging = dragSettings.data && dragSettings.data.layerDrag && dragSettings.drag && state.dropLayer
          const pastThreshold = isDragging && !dragSettings.threshold
          const showGrabbedClass = pastThreshold && (dragSettings.data.layerIndex === layer.index) ? " grabbed-item" : ""
          const showDropZoneClass = pastThreshold && (state.dropLayer.index === layer.index) ? " show-drop-zone" : ""
          const dragAccessibilityProps = {
            "aria-label": "Use arrow keys to change the stack order of this layer.",
            onKeyDown: keyMap({
              ArrowUp: () => this.moveLayerIndex(layer.index, -1, layers, bgCSS),
              ArrowLeft: () => this.moveLayerIndex(layer.index, -1, layers, bgCSS),
              ArrowDown: () => this.moveLayerIndex(layer.index, 2, layers, bgCSS),
              ArrowRight: () => this.moveLayerIndex(layer.index, 2, layers, bgCSS),
              PageUp: () => this.moveLayerIndex(layer.index, -999, layers, bgCSS),
              Home: () => this.moveLayerIndex(layer.index, -999, layers, bgCSS),
              PageDown: () => this.moveLayerIndex(layer.index, 999, layers, bgCSS),
              End: () => this.moveLayerIndex(layer.index, 999, layers, bgCSS)
            })
          }
          const commonProps = {
            dragStart: ev => this.dragInit(ev, layer, layers, bgCSS),
            dropEnter: () => this.setDropArea(layer),
            dragAccessibilityProps,
            className: layerClass + showDropZoneClass + showGrabbedClass + " bg__layer--" + layer.index,
            layer: layer,
            editorOpen: showEditorAtIndex === layer.index,
            toggleEditor: () => this.toggleEditorAtIndex(layer.index),
            onValueChanged: val => this.layerValueChanged(bgCSS, layers, layer.index, val),
            setPopover: props.setPopover,
            onDeleteClicked: () => this.deleteLayer(layers, layer.index, bgCSS)
          }
          if (layer.family === "image") {
            return (
              <ImageLayer key={layer.index} {...commonProps} />
            )
          }
          if (layer.family === "color") {
            return (
              <ColorLayer key={layer.index} {...commonProps} />
            )
          }
          return null
        })}
      </BGLayers>
    )
  }
}

export default BackgroundBuilder
