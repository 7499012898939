import React, { Component } from 'react'
import magicComponent from 'magic-react-component'
import ValueCSSTypeCombo from '../ValueCSSTypeCombo'

/*
  Stretch To: [                  ]  // closest-corner, closest-side, farthest-corner, farthest-side, custom radius
  Radius:     [         <length> ]  // stretch = custom radius & shape = circle
  X [     <l-p> ]  Y [     <l-p> ]  // stretch = custom radius & shape = ellipse
*/

const ExtentContainer = magicComponent("div", `
  display: grid;
  grid-template-columns: 60px 1fr;
  grid-gap: 5px;
  align-items: center;
  vertical-align: middle;

  .two-rad {
    grid-column-end: span 2;
    display: grid;
    grid-template-columns: 15px 1fr 15px 1fr;
    grid-gap: 5px;
    align-items: center;
    vertical-align: middle;
  }
`)

class ValueExtent extends Component {
  // props: shape, cssValue, onValueChanged, setPopover, defaultValue ("center" for radial gradients, "top left" for bg-position)

  state = {}

  extentOptionChanged = (extentValue, shape) => {
    const radVal = shape === "circle" ? "100px" : "10% 15%"
    this.props.onValueChanged(extentValue.includes("Custom") ? radVal : extentValue.replace("0", ""))
  }

  render () {
    const props = this.props
    const shape = props.shape || "ellipse"
    const cssValue = props.cssValue
    const isCustom = /\d/.test(cssValue)
    const radii = (isCustom && cssValue.replace(/\s+/g, " ").trim().split(" ")) || ["10%", "15%"]
    const customOption = shape === "circle" ? "Custom Radius" : "Custom Radii"
    const extentValueSelected = isCustom ? customOption : cssValue

    return (
      <ExtentContainer className={props.className}>
        <span>Stretch To:</span>
        <ValueCSSTypeCombo type="extent" extraOptions={[customOption]} setPopover={props.setPopover}
          useLabel="Extent or Size of the Gradient" cssValue={extentValueSelected}
          onValueChanged={val => this.extentOptionChanged(val, shape)} />
        {isCustom
          ? (shape === "circle"
              ? <React.Fragment>
                  <span>Radius</span>
                  <ValueCSSTypeCombo type="length" setPopover={props.setPopover}
                    useLabel="Custom Radius" cssValue={radii[0]} valmax={1000}
                    onValueChanged={this.props.onValueChanged} />
                </React.Fragment>
              : <div className="two-rad">
                  <span>X</span>
                  <ValueCSSTypeCombo type="length-percentage" setPopover={props.setPopover}
                    useLabel="Custom Horizontal Radius" cssValue={radii[0]} valmax={1000}
                    onValueChanged={val => this.props.onValueChanged(val + " " + radii[1])} />
                  <span>Y</span>
                  <ValueCSSTypeCombo type="length-percentage" setPopover={props.setPopover}
                    useLabel="Custom Vertical Radius" cssValue={radii[1]} valmax={1000}
                    onValueChanged={val => this.props.onValueChanged(radii[0] + " " + val)} />
                </div>
            )
          : null
        }
      </ExtentContainer>
    )
  }
}

export default ValueExtent
