import React, { Component } from 'react'
import OverlayContainer from './OverlayContainer'
import ElementSettings from './ElementSettings'
import AddElement from './AddElement'
import AppInfo from './AppInfo'

class Pages extends Component {
  // props: title, onExit, onAdd, onRemove, previewBG, selectedEl, classManager, elementManager

  state = {}

  render () {
    const props = this.props
    const { title, onExit, onAdd, onRemove, previewBG, selectedEl, classManager, elementManager } = props

    return (
      <OverlayContainer title={title} exitCallback={onExit}>
        {(x => {
          switch (title) {
            case "App Info": return (
              <AppInfo exitCallback={onExit} />
            )
            case "Element": return (
              <ElementSettings el={selectedEl}
                previewBG={previewBG}
                classManager={classManager}
                elementManager={elementManager}
                cancelCallback={onExit}
                onRemove={onRemove} />
            )
            case "Load": return (
              <AddElement
                onAdd={onAdd}
                previewBG={previewBG}
                classManager={classManager}
                elementManager={elementManager} />
            )
            default: return  <div>3BbiHYTrxk9KCwCoekQu746oDfHRDzfyHw</div>
          }
        })()}
      </OverlayContainer>
    )
  }
}

export default Pages
