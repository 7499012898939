import React, { Component } from 'react'
import magicComponent from 'magic-react-component'
import ValueColorStopList from './ValueColorStopList'
import ValueCSSTypeCombo from '../ValueCSSTypeCombo'
import { splitTopLevelCommas, splitValueUnit } from '../css-helpers'

const LinearGradientContainer = magicComponent("div", `
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 5px;
  align-items: center;
  vertical-align: middle;
  .span--2 {
    grid-column-end: span 2;
  }
  .span--3 {
    grid-column-end: span 3;
  }
`)

class ValueGradientLinear extends Component {
  // props: cssValue, onValueChanged, setPopover

  state = {
    directions: ["to right", "to left", "to top", "to bottom", "to top right", "to top left", "to bottom right", "to bottom left"],
    // corner values technically wrong but the direction value depends on the gradient's render size in-element, so must fake it
    dirToDegMap: [90, 270, 0, 180, 45, 315, 135, 225],
    // steps are all aprox 1 / 360th of a circle to make conversion generic and interface consistent
    "deg": { min: 0, max: 360, step: 1 },
    "rad": { min: 0, max: 2 * Math.PI, step: 2 * Math.PI / 360 },
    "grad": { min: 0, max: 400, step: 10/9 },
    "turn": { min: 0, max: 1, step: 1 / 360 }
  }

  cleanDecimalValueToUnitString = (value, unit) => {
    const maxDecimalCount = ({turn: 5, rad: 4})[unit] || 2
    return (parseFloat(value) || 0).toFixed(maxDecimalCount).replace(/\.?0+$/, "") + unit
  }

  convertDirectionUnit = (newUnit, oldValue, oldUnit) => {
    const state = this.state
    const newUnitIsAngle = newUnit && !state.directions.includes(newUnit)
    const oldUnitIsAngle = oldUnit && !state.directions.includes(oldUnit)

    let convertedDirection = newUnit
    if (newUnitIsAngle && oldUnitIsAngle && newUnit !== oldUnit) {
      // is <angle> to <angle>, must convert
      const newStep = state[newUnit].step
      const oldStep = state[oldUnit].step
      convertedDirection = this.cleanDecimalValueToUnitString(parseFloat(oldValue) / oldStep * newStep, newUnit)
    } else if (newUnitIsAngle && !oldUnitIsAngle) {
      const newStep = state[newUnit].step
      const oldAsDeg = state.dirToDegMap[state.directions.indexOf(oldUnit || "to bottom")]
      convertedDirection = this.cleanDecimalValueToUnitString(oldAsDeg * newStep, newUnit)
    }
    // else newUnit is "to" <side-or-corner> type direction, or didn't actually change, so no conversion needed

    return convertedDirection
  }

  directionChanged = (newDir, oldDir, colorStops) => {
    const props = this.props
    const { value, unit } = splitValueUnit(newDir)
    const { value: oldValue, unit: oldUnit } = splitValueUnit(oldDir)

    let newValue = ""
    if (unit !== oldUnit) {
      newValue = this.convertDirectionUnit(unit, oldValue, oldUnit)
    } else {
      newValue = this.cleanDecimalValueToUnitString(value, unit)
    }

    newValue = (newValue || "").trim() ? newValue + ", " : ""
    newValue = newValue === "to bottom, " ? "" : newValue
    newValue = newValue + colorStops.join(",")
    props.onValueChanged && props.onValueChanged(newValue)
  }

  colorStopChanged = (newListValue, direction) => {
    const props = this.props
    direction = direction.trim()
    const result = (direction ? direction + ", " : "") + newListValue
    props.onValueChanged && props.onValueChanged(result)
  }

  getMinMaxStepForDirection = dir => {
    const angles = ["deg", "grad", "rad", "turn"] // use grad before checking rad to avoid conflict
    let which = null
    for (let i = 0; i < angles.length; i++) {
      if (dir.includes(angles[i])) {
        which = this.state[angles[i]]
        break
      }
    }
    return which
  }

  render () {
    const state = this.state
    const props = this.props
    const cssValue = props.cssValue

    const colorStops = splitTopLevelCommas(cssValue)
    const direction = /(?:deg|rad|grad|turn)\b|\bto\b/.test(colorStops[0] || "") ? colorStops[0] : ""
    const dirMinMax = this.getMinMaxStepForDirection(direction) || {}
    if (direction) {
      colorStops.shift()
    }

    return (
      <LinearGradientContainer>
        <span>Angle</span>
        <div className="span--2">
          <ValueCSSTypeCombo type="angle" extraOptions={state.directions} setPopover={props.setPopover}
            useLabel="gradient direction" maxDecimalPlaces={5}
            valmin={dirMinMax.min} valmax={dirMinMax.max} step={dirMinMax.step}
            cssValue={direction.trim() || "to bottom"} onValueChanged={val => this.directionChanged(val, direction, colorStops)} />
        </div>
        <ValueColorStopList className="span--3" setPopover={props.setPopover} cssValueArray={colorStops}
          onValueChanged={val => this.colorStopChanged(val, direction)} />
      </LinearGradientContainer>
    )
  }
}

export default ValueGradientLinear
