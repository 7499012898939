import { splitTopLevelSpaces, typeUnits } from '../css-helpers'
import { blend } from '../colors/color-helpers'

/*
  <color-stop-list> = [ <linear-color-stop> [, <color-hint> ]? ]#, <linear-color-stop>
  where <linear-color-stop> = <color> [ <color-stop-length> ]?
  and <color-stop-length> = [ <percentage> | <length> ]{1,2}
  and <color-hint> = [ <percentage> | <length> ]
*/

export const colorStopListDataLevel4ConvertToCSSLevel3 = css4ColorStopListData => {
  const colorStopListDataCSS3 = []
  let color = "", stop = ""
  for (let i = 0; i < css4ColorStopListData.length; i++) {
    const prevValue = css4ColorStopListData[i - 1] || {}
    const curValue = css4ColorStopListData[i] || {}
    const nextValue = css4ColorStopListData[i + 1] || {}
    if (curValue.color) {
      color = curValue.color
      stop = curValue.colorStop1 || ""
      colorStopListDataCSS3.push({ color, stop, cssValue: (color + " " + stop).trim() })
      if (curValue.colorStop2) {
        stop = curValue.colorStop2 || ""
        colorStopListDataCSS3.push({ color, stop, cssValue: (color + " " + stop).trim() })
      }
    } else if (curValue.colorHint) {
      // css4 spec requires both of these colors to be present if this one is a color-hint
      color = blend(prevValue.color, nextValue.color, 0.5)
      stop = curValue.colorHint
      colorStopListDataCSS3.push({ color, stop, cssValue: (color + " " + stop).trim() })
    }
  }
  return colorStopListDataCSS3
}

const lpUnits = typeUnits["length-percentage"]
const lpTest = new RegExp("(?:" + lpUnits.join("|") + ")$", "i") // doesn't have global flag so don't need to reset lastIndex

// TODO: doesn't validate that it's a colorstop cssValue before parsing
export const parseColorStop = cssValue => {
  const data = {}
  const parts = splitTopLevelSpaces((cssValue || "").trim())
  const colorOrHint = parts[0]

  if (colorOrHint && lpTest.test(colorOrHint)) {
    data.colorHint = colorOrHint
  } else if (colorOrHint) {
    data.color = colorOrHint
    data.stop = parts[1]
    data.colorStop1 = parts[1]
    data.colorStop2 = parts[2]
  }
  return data
}

// returns CSS Level 4 data
export const parseColorStopArray = cssValueArray => {
  return (cssValueArray || []).map(parseColorStop)
}

// takes array of unparsed values from a top-level-comma split css level 4 (or 3) <color-stop-list>
// (arrat can not include gradient direction etc, must be actual <color-stop-list> values)
// returns array of { color, stop, cssValue } cssValues (stop may be empty)
export const parseColorStopArrayIntoCSSLevel3 = cssValueArray => {
  return colorStopListDataLevel4ConvertToCSSLevel3(parseColorStopArray(cssValueArray))
}
