import React, { Component } from 'react'
import PillNav from '../../PillNav'
import ValueColorNameOrHex from './ValueColorNameOrHex'
import ValueRGBSliders from './ValueRGBSliders'
import ValueHSLSliders from './ValueHSLSliders'
import colors from './color-helpers'

class ValueAnyColor extends Component {
  // props: cssValue, onValueChanged

  state = {
    nav: ["HEX", "RGB", "HSL"]
  }

  onEscape = () => {
    this.props.onEscape && this.props.onEscape()
  }

  saveValueToType = newType => {
    const props = this.props
    const newStr = colors.convertAnyToStringPreference(props.cssValue.trim(), newType)
    props.onValueChanged && props.onValueChanged(newStr)
  }

  getNavType = (cssValue, allowHex) => {
    const upper = cssValue.toUpperCase()
    if (upper.includes("RGB")) {
      return "RGB"
    }
    if (upper.includes("HSL")) {
      return "HSL"
    }
    return allowHex ? "HEX" : "RGB"
  }

  render () {
    const state = this.state
    const props = this.props
    const cssValue = props.cssValue
    const resolvedColor = colors.resolveAnyColorStr(cssValue)
    const rgbObj = colors.anyColorStringToRGBObj(resolvedColor)
    const allowHex = rgbObj.a === 1
    const items = state.nav.filter(i => allowHex || i !== "HEX")
    const selectedType = this.getNavType(cssValue, allowHex)
    const onValueChanged = props.onValueChanged

    let ValueColorType = ValueColorNameOrHex
    if (selectedType === "RGB") {
      ValueColorType = ValueRGBSliders
    } else if (selectedType === "HSL") {
      ValueColorType = ValueHSLSliders
    }

    return (
      <div>
        <div style={{marginBottom: "5px"}}>
          <PillNav label="Color Formats" items={items} onValueChanged={this.saveValueToType} selected={selectedType} />
        </div>
        <ValueColorType cssValue={cssValue} onEscape={this.onEscape} onValueChanged={onValueChanged} />
      </div>
    )
  }
}

export default ValueAnyColor
