import normalizedEventKey from './normalized-event-key.js'

export const heldKeys = {}

let docJustFocused = true
const updateOnDocumentFocused = function (ev) {
  document.removeEventListener("pointerdown", updateOnDocumentFocused)
  document.removeEventListener("pointermove", updateOnDocumentFocused)
  docJustFocused = false
  // console.log("b4", Object.keys(heldKeys))
  // Tabbed/Clicked into document with keys held:
  if (ev.shiftKey && !heldKeys.Shift) {
    heldKeys.Shift = { ts: Date.now() }
  }
  if (ev.ctrlKey && !heldKeys.Control) {
    heldKeys.Control = { ts: Date.now() }
  }
  if (ev.altKey && !heldKeys.Alt) {
    heldKeys.Alt = { ts: Date.now() }
  }
  // Tabbed/Clicked into document with keys released:
  if (!ev.shiftKey && heldKeys.Shift) {
    delete heldKeys.Shift
  }
  if (!ev.ctrlKey && heldKeys.Control) {
    delete heldKeys.Control
  }
  if (!ev.altKey && heldKeys.Alt) {
    delete heldKeys.Alt
  }
  // console.log("aft", Object.keys(heldKeys))
}

document.addEventListener("keydown", ev => {
  const key = normalizedEventKey(ev)
  if (key === "Tab" && docJustFocused) {
    updateOnDocumentFocused(ev)
  }
  heldKeys[key] = { ts: Date.now() }
})

document.addEventListener("keyup", ev => {
  const key = normalizedEventKey(ev)
  if (key === "Tab" && docJustFocused) {
    updateOnDocumentFocused(ev)
  }
  delete heldKeys[key]
})

window.addEventListener("focus", ev => {
  docJustFocused = true
  document.addEventListener("pointerdown", updateOnDocumentFocused)
  document.addEventListener("pointermove", updateOnDocumentFocused)
})
