import React, { Component } from 'react'
import magicComponent from 'magic-react-component'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import spaceEnterClick from '../../utils/space-enter-click'

const FoldableContainer = magicComponent("div", `
  width: 100%;
  position: relative;
  > .foldable__caret {
    width: 15px;
  }
`, {
  backgroundColor: "gray700",
  color: "gray200",

  "> .foldable__title": {
    cursor: "pointer",
    fontSize: 2,
    p: 1,
    backgroundColor: "gray900",
    color: "white"
  },

  "> .foldable__content": {
    p: 1,
    fontSize: 2,
    display: ({magicProps}) => magicProps.collapsed ? "none" : "block"
  }
})

class FoldableSection extends Component {
  state = {
    collapsed: false
  }

  toggleCollapsed = () => {
    this.setState((state, props) => {
      return {
        collapsed: !state.collapsed
      }
    })
  }

  render () {
    const state = this.state
    const props = this.props
    const icon = state.collapsed ? "caret-right" : "caret-down"

    return (
      <FoldableContainer collapsed={state.collapsed}>
        <div className="foldable__title" {...spaceEnterClick(this.toggleCollapsed)}>
          <FontAwesomeIcon icon={icon} className="foldable__caret" /> {props.title || "Untitled"}
        </div>
        <div className="foldable__content">{props.children}</div>
      </FoldableContainer>
    )
  }
}

export default FoldableSection
