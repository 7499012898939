import React, { Component } from 'react'
import FoldableSubSection from '../FoldableSubSection'
import BackgroundBuilder from '../backgrounds/BackgroundBuilder'
import ValueAnyColor from '../colors/ValueAnyColor'
import ValueCSSTypeCombo from '../ValueCSSTypeCombo'
import ValueSlider from '../ValueSlider'
import magicComponent from 'magic-react-component'
import { getCSSPropValue, replaceCSS } from '../css-helpers'

const GridLayout = magicComponent("div", `
  display: grid;
  grid-template-columns: 50px minmax(0, 1fr) 50px minmax(0, 1fr);
  grid-auto-rows: auto;
  grid-gap: 5px;
  grid-row-gap: 5px;
  align-items: center;

  .grid__span2 {
    grid-column-end: span 2;
  }

  .grid__span4 {
    grid-column-end: span 4;
    border-bottom: 1px solid;
  }

  .grid__fallback {
    display: grid;
    grid-template-columns: 50px 1fr;
    grid-auto-rows: auto;
    grid-gap: 5px;
    grid-row-gap: 5px;
    align-items: center;
  }
`, {
  pt: 1,
  "&.row--none-state": {
    pb: 1,
    borderBottom: "1px solid",
    borderColor: "gray800"
  },
  ".grid__span4": {
    pb: 1,
    borderColor: "gray800"
  }
})

class AugBorderOrInset extends Component {
  // props: type ("border" || "inset"), css, onCSSChanged

  state = {}

  noneSelected = (oldCSS, oldValues) => {
    let newCSS = oldCSS
    for (let cssProp in oldValues) {
      newCSS = replaceCSS(newCSS, cssProp, oldValues[cssProp], "")
    }
    this.props.onCSSChanged(newCSS)
  }

  checkSetDefaultColors = (prop, newValue, oldValues) => {
    let additionalNewCSS = ""
    const defaultColor = "rgba(255, 215, 0, 0.5)"

    if (prop.replace(/[^-]/g, "") === "---") { // specifically "--aug-border" or "--aug-inset"
      const bgProp = prop + "-bg"
      const isBorder = prop.indexOf("border") !== -1
      const fbColorProp = prop + "-fallback-color"
      let fbVal = oldValues[fbColorProp] || oldValues[bgProp] || defaultColor
      if (fbVal.indexOf("gradient") !== -1 || fbVal.indexOf("url") !== -1) {
        // get rid of non-color fallback copied from the oldValues[bgProp]
        fbVal = defaultColor
      }
      if (!oldValues[bgProp]) {
        additionalNewCSS += " \n " + bgProp + ": " + (isBorder ? (oldValues[fbColorProp] || fbVal) : defaultColor) + ";"
      }
      if (isBorder && !oldValues[fbColorProp]) {
        additionalNewCSS += " \n " + fbColorProp + ": " + fbVal + ";"
      }
    }
    return additionalNewCSS
  }

  propertyChanged = (prop, newValue, oldValues) => {
    const isMainSizeProp = prop.replace(/[^-]/g, "") === "---"
    const oldCSS = this.props.css
    if (/none\s*$/.test(newValue)) {
      return this.noneSelected(oldCSS, oldValues)
    } else if (!/^-?\d/.test(newValue) && isMainSizeProp) {
      newValue = "0" + newValue.trim()
    }
    if (oldValues[prop] === "" && newValue.startsWith("0") && isMainSizeProp) {
      newValue = "1" + newValue // default to 10[px or whateverunit]
    }
    const newCSS = replaceCSS(oldCSS, prop, oldValues[prop], newValue)
    this.props.onCSSChanged(newCSS + this.checkSetDefaultColors(prop, newValue, oldValues))
  }

  opacityChanged = (prop, newValue, oldValues) => {
    let val = Math.max(Math.min(parseFloat(newValue) / 100, 1), 0)
    val = val >= 1 ? "" : (val.toFixed(3).replace(/\.?0+$/, "") || "0")
    const newCSS = replaceCSS(this.props.css, prop, oldValues[prop], val)
    this.props.onCSSChanged(newCSS)
  }

  upperFirst = str => {
    return str.replace(/^(.)(.*)$/, (_, f, r) => f.toUpperCase() + r)
  }

  render () {
    const props = this.props
    const type = props.type
    const css = props.css
    const setPopover = props.setPopover
    const upperType = this.upperFirst(type)
    const sizeProp = "--aug-" + type
    const bgProp = sizeProp + "-bg"
    const opacityProp = sizeProp + "-opacity"
    const fallbackColorProp = sizeProp + "-fallback-color"
    const currentValues = {
      [sizeProp]: getCSSPropValue(css, sizeProp),
      [bgProp]: getCSSPropValue(css, bgProp),
      [opacityProp]: getCSSPropValue(css, opacityProp),
      [fallbackColorProp]: getCSSPropValue(css, fallbackColorProp)
    }

    let opacity = parseFloat(currentValues[opacityProp]) * 100
    opacity = Number.isNaN(opacity) ? 100 : opacity
    opacity = opacity.toFixed(2).replace(/\.?0+$/, "") + "%"

    const noneState = currentValues[sizeProp] ? /none\s*$/.test(currentValues[sizeProp]) : true

    return noneState ? (
      <GridLayout className="row--none-state">
        <span>{upperType}</span>
        <div>
          <ValueCSSTypeCombo type="length" extraOptions={["none"]} setPopover={setPopover}
            onValueChanged={newVal => this.propertyChanged(sizeProp, newVal, currentValues)}
            cssValue={"none"} useLabel={upperType + " size"} />
        </div>
      </GridLayout>
    ) : (
      <GridLayout>
        <span>{upperType}</span>
        <div>
          <ValueCSSTypeCombo type="length" extraOptions={["none"]} setPopover={setPopover}
            onValueChanged={newVal => this.propertyChanged(sizeProp, newVal, currentValues)}
            cssValue={currentValues[sizeProp]} useLabel={upperType + " size"} />
        </div>
        <div></div>
        <div></div>

        <span>Opacity</span>
        <div className="grid__span2">
          <ValueSlider value={parseFloat(opacity) / 100} onValueChange={v => this.opacityChanged(opacityProp, v * 100 + "%", currentValues)} />
        </div>
        <div>
          <ValueCSSTypeCombo type="percentage" setPopover={setPopover}
            onValueChanged={v => this.opacityChanged(opacityProp, v, currentValues)}
            cssValue={opacity} useLabel={upperType + " Opacity Percentage"} />
        </div>

        <div className="grid__span4">
          <FoldableSubSection title={upperType + " Background"}>
            <BackgroundBuilder css={css} cssProperty={bgProp} onCSSChanged={props.onCSSChanged} setPopover={setPopover} />
            {type === "inset"
              ? null
              : <div className="grid__fallback">
                  <div className="grid__span2" aria-label="Border Fallback Color">Border Fallback Color</div>
                  <span></span>
                  <ValueAnyColor cssValue={currentValues[fallbackColorProp]} setPopover={setPopover}
                    onValueChanged={newVal => this.propertyChanged(fallbackColorProp, newVal, currentValues)} />
                </div>
            }
          </FoldableSubSection>
        </div>
      </GridLayout>
    )
  }
}

export default AugBorderOrInset
