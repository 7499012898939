import React, { Component } from 'react'
// import colors from '../colors/color-helpers'
// import ColorIcon from '../colors/ColorIcon'
import ValueGradientLinear from '../gradients/ValueGradientLinear'
import ValueGradientRadial from '../gradients/ValueGradientRadial'
import ColorIcon from '../colors/ColorIcon'
import magicComponent from 'magic-react-component'
import { getParenSource } from '../css-helpers.js'
import spaceEnterClick from '../../../utils/space-enter-click'
import FoldableSubSection from '../FoldableSubSection'
import ValuePosition from '../ValuePosition'
import ValueCSSTypeCombo from '../ValueCSSTypeCombo'
import ValueBGSize from './ValueBGSize'
import ValueRepeatStyle from './ValueRepeatStyle'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const ImageLayerContainer = magicComponent("div", `
  display: grid;
  grid-template-columns: 50px 1fr;
  grid-auto-rows: auto;
  grid-row-gap: 5px;
  grid-gap: 5px;
  align-items: center;

  &.draggable {
    .layer__name {
      cursor: grab;
    }
    .layer__drag-handle {
      display: inline-block;
      cursor: grab;
    }
  }

  .layer__name {
    height: 100%;
    line-height: 26px;
  }
  .layer__drag-handle {
    display: none;
    vertical-align: middle;
    margin-right: 5px;
    margin-left: -5px;
    width: 10px;
    height: 100%;
    background: grey;
  }
  .layer__toggle-editor {
    cursor: pointer;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
`, {
  ".layer__delete": {
    fontSize: 4,
    alignSelf: "flex-start",
    textAlign: "center",
    cursor: "pointer"
  },
  ".layer__delete__icon": {
    opacity: 0.25,
    color: "gray900"
  },
  ".layer__delete:hover .layer__delete__icon, .layer__delete:focus .layer__delete__icon": {
    opacity: 1,
    color: "red900"
  }
})

/*
                attached : to Element, to Document, to none
<attachment>             : local, scroll, fixed
                at       : 20px from [Top, Center, Bottom]
                and      : 20px from [Left, Center, Right]
<bg-position>            : top 20px left 50px
                w/Size   : [Custom, Contain, Cover]
                           Width (<length-percentage> || auto) Height (<length-percentage> || auto)
/ <bg-size>
                Repeated : Horizontally, Vertically, On Both Axes, None
<repeat-style>           : repeat-x, repeat-y, repeat, no-repeat

*/

class ImageLayer extends Component {
  // props: layer, onValueChanged, setPopover, editorOpen, toggleEditor
  // dragStart, dropEnter, dragAccessibilityProps

  state = {
    nav: ["URL", "Gradient"]
  }

  toggleEditor = () => {
    this.props.toggleEditor && this.props.toggleEditor()
  }

  consumeImageSource = (remainingCSS, type) => {
    const imageSource = getParenSource(remainingCSS, type)
    const unparsedCSS = remainingCSS.replace(type + "(" + imageSource + ")", "")
    return { imageSource, unparsedCSS }
  }

  consumeAttachment = remainingCSS => {
    // <attachment> = scroll | fixed | local
    const attachmentCSS = ((remainingCSS.match(/\b(scroll|fixed|local)\b/i) || [])[0] || "").toLowerCase()
    return {
      attachmentCSS,
      unparsedCSS: attachmentCSS ? remainingCSS.replace(attachmentCSS, "") : remainingCSS
    }
  }

  consumeRepeatStyle = remainingCSS => {
    // <repeat-style> = repeat-x | repeat-y | [ repeat | space | round | no-repeat ]{1,2}
    let repeatCSS = ((remainingCSS.match(/\b(repeat-x|repeat-y)\b/i) || [])[0] || "").toLowerCase()
    if (!repeatCSS) {
      repeatCSS = ((remainingCSS.match(/\b((?:no-repeat|space|round|repeat)\s*(?:no-repeat|space|round|repeat)?)\b/i) || [])[0] || "").toLowerCase()
    }
    return {
      repeatCSS,
      unparsedCSS: repeatCSS ? remainingCSS.replace(repeatCSS, "") : remainingCSS
    }
  }

  // <bg-image> || <bg-position> [ / <bg-size> ]? || <repeat-style> || <attachment>
  parseLayer = (layerCSS, type) => {
    const layerData = {
      type,
      imageSource: "",
      positionCSS: "",
      sizeCSS: "",
      repeatCSS: "",
      attachmentCSS: "",
      unparsedCSS: layerCSS
    }
    Object.assign(layerData, this.consumeImageSource(layerData.unparsedCSS, type))
    Object.assign(layerData, this.consumeAttachment(layerData.unparsedCSS))
    Object.assign(layerData, this.consumeRepeatStyle(layerData.unparsedCSS))
    // only pos and size should be left: <bg-position> [ / <bg-size> ]?
    const posAndSize = layerData.unparsedCSS.split("/")
    layerData.positionCSS = posAndSize[0] || ""
    layerData.sizeCSS = posAndSize[1] || ""
    delete layerData.unparsedCSS

    return layerData
  }

  compileLayerData = (layerData) => {
    const type = (layerData.type || "").trim()
    const imageSource = (layerData.imageSource || "").trim()
    const attachmentCSS = (layerData.attachmentCSS || "").trim()

    const positionCSS = (layerData.positionCSS || "").trim()
    const sizeCSS = (layerData.sizeCSS || "").trim()
    const posAndSize = (positionCSS || "top left") + " / " + sizeCSS

    const repeatCSS = (layerData.repeatCSS || "").trim()

    const parts = [type + "(" + imageSource + ")"] // required
    if (attachmentCSS && attachmentCSS !== "scroll") {
      // "scroll" is the default so it can be omitted
      parts.push(attachmentCSS)
    }
    if (sizeCSS) {
      parts.push(posAndSize)
    } else if (positionCSS) {
      parts.push(positionCSS)
    }
    if (repeatCSS) {
      parts.push(repeatCSS)
    }
    return parts.join(" ")
  }

  bgPropChanged = (newValue, prop, layerData) => {
    const newLayerData = Object.assign({}, layerData, { [prop]: newValue })
    const newLayerValue = this.compileLayerData(newLayerData)
    const props = this.props
    props.onValueChanged && props.onValueChanged(newLayerValue)
  }

  getPreviewIcon = layerData => {
    if (layerData.type.includes("gradient")) {
      let iconBG = "0 0/26px 26px "
      iconBG += layerData.type + "("
      iconBG += layerData.imageSource
      iconBG += ")"
      return <ColorIcon style={{"--display-color": iconBG}} />
    }
  }

  getTypeComponent = type => {
    if (type === "linear-gradient") {
      return ValueGradientLinear
    }
    if (type === "repeating-linear-gradient") {
      return ValueGradientLinear
    }
    if (type === "radial-gradient") {
      return ValueGradientRadial
    }
    if (type === "repeating-radial-gradient") {
      return ValueGradientRadial
    }
    return null
  }

  render () {
    const props = this.props
    const layer = props.layer

    const layerData = this.parseLayer(layer.css, layer.type)

    const DynamicImageComponent = this.getTypeComponent(layerData.type)

    const attachmentMap = {
      "0Element": "local",
      "0Document": "scroll",
      "0None": "fixed",
      "local": "Element",
      "scroll": "Document",
      "fixed": "None",
      "": "Document"
    }

    const { dragStart, dropEnter } = props

    return (
      <ImageLayerContainer className={props.className} onPointerEnter={dropEnter}>
        <div className="layer__name" onPointerDown={dragStart} {...props.dragAccessibilityProps} tabIndex="0">
          <div className="layer__drag-handle"></div>
          Image
        </div>
        <div className="layer__toggle-editor" {...spaceEnterClick(this.toggleEditor)}>
          {this.getPreviewIcon(layerData)}
          {layerData.type}
        </div>
        {props.editorOpen
          ? <React.Fragment>
              <div className="layer__delete" title="Delete Background Layer" {...spaceEnterClick(props.onDeleteClicked)}>
                <FontAwesomeIcon className="layer__delete__icon" icon="window-close" />
              </div>
              <FoldableSubSection title="Image Properties" defaultOpen={true}>
                {DynamicImageComponent
                  ? <DynamicImageComponent cssValue={layerData.imageSource}
                      onValueChanged={val => this.bgPropChanged(val, "imageSource", layerData)}
                      setPopover={props.setPopover} />
                  : null
                }
              </FoldableSubSection>
              <span></span>
              <FoldableSubSection className="bg-options" title="Background Options">
                <div style={{marginBottom: "5px"}}>Scroll Attached To</div>
                <ValueCSSTypeCombo type={["Element", "Document", "None"]} setPopover={props.setPopover}
                  useLabel="scroll attachment to" cssValue={attachmentMap[layerData.attachmentCSS]}
                  onValueChanged={val => this.bgPropChanged(attachmentMap[val], "attachmentCSS", layerData)} />
                <ValuePosition defaultValue="left top" setPopover={props.setPopover} cssValue={layerData.positionCSS}
                  onValueChanged={val => this.bgPropChanged(val, "positionCSS", layerData)} />
                <ValueBGSize setPopover={props.setPopover} cssValue={layerData.sizeCSS}
                  onValueChanged={val => this.bgPropChanged(val, "sizeCSS", layerData)} />
                <ValueRepeatStyle setPopover={props.setPopover} cssValue={layerData.repeatCSS}
                  onValueChanged={val => this.bgPropChanged(val, "repeatCSS", layerData)} />
              </FoldableSubSection>
            </React.Fragment>
          : null
        }
      </ImageLayerContainer>
    )
  }
}

export default ImageLayer
