import React, { Component } from 'react'
import ValueCSSTypeCombo from '../ValueCSSTypeCombo'
import magicComponent from 'magic-react-component'

const BGSizeContainer = magicComponent("div", `
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 5px;
  grid-row-gap: 5px;
  align-items: center;
  vertical-align: middle;

  .span--2 {
    grid-column-end: span 2;
  }
`, {
  my: 1
})

/*
  w/Size   : [Custom, Contain, Cover]
             Width (<length-percentage> || auto) Height (<length-percentage> || auto)
*/

class ValueBGSize extends Component {
  // props: cssValue, onValueChanged, setPopover

  state = {}

  valueChanged = newValue => {
    newValue = newValue.replace(/0auto/g, "auto")
    newValue = newValue.replace(/ ?auto$/, "")
    this.props.onValueChanged(newValue === "auto" ? "" : newValue)
  }

  sizeDropdownChanged = newValue => {
    newValue = newValue.replace("0", "")
    this.props.onValueChanged(newValue === "custom" ? "" : newValue)
  }

  render () {
    const props = this.props
    const cssValue = props.cssValue || "auto auto"
    const isCustom = /\d|auto/.test(cssValue)
    const dropdownValue = isCustom ? "custom" : cssValue
    const customSizes = isCustom ? cssValue.replace(/\s+/g, " ").trim().split(" ") : []
    customSizes.push("auto")

    return (
      <BGSizeContainer className={props.className}>
        <span>With Size</span>
        <ValueCSSTypeCombo className="span--2" type={["custom", "cover", "contain"]}
          cssValue={dropdownValue} setPopover={props.setPopover}
          useLabel="Background Image Size" onValueChanged={this.sizeDropdownChanged} />
        {isCustom
          ? <React.Fragment>
              <span>Width</span>
              <ValueCSSTypeCombo className="span--2" type="length-percentage" extraOptions={["auto"]}
                cssValue={customSizes[0]} setPopover={props.setPopover} valmax={500}
                useLabel="Background Image Width" onValueChanged={val => this.valueChanged(val + " " + customSizes[1])} />
              <span>Height</span>
              <ValueCSSTypeCombo className="span--2" type="length-percentage" extraOptions={["auto"]}
                cssValue={customSizes[1]} setPopover={props.setPopover} valmax={500}
                useLabel="Background Image Height" onValueChanged={val => this.valueChanged(customSizes[0] + " " + val)} />
            </React.Fragment>
          : null
        }
      </BGSizeContainer>
    )
  }
}

export default ValueBGSize
