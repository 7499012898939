import React, { Component } from 'react'
import magicComponent from 'magic-react-component'

const AugIconContainer = magicComponent("div", `
  position: relative;
  overflow: hidden;
  --overflow: 10px;
  --neghalfovfl: calc(var(--overflow) / -2);

  .icon__aug {
    position: absolute;
    width: calc(100% + var(--overflow));
    height: calc(100% + var(--overflow));
    --aug-border: 1px;
    --aug-border-bg: gold;
    --aug-t: 6px;
    --aug-r: 6px;
    --aug-b: 6px;
    --aug-l: 6px;
    --aug-tl: 13px;
    --aug-tr: 13px;
    --aug-br: 13px;
    --aug-bl: 13px;
  }
  .icon__aug--tl {
    top: 0px;
    left: 0px;
  }
  .icon__aug--t {
    top: 0px;
    left: var(--neghalfovfl);
  }
  .icon__aug--tr {
    top: 0px;
    right: 0px;
  }
  .icon__aug--r {
    top: var(--neghalfovfl);
    right: 0px;
  }
  .icon__aug--br {
    right: 0px;
    bottom: 0px;
  }
  .icon__aug--b {
    left: var(--neghalfovfl);
    bottom: 0px;
  }
  .icon__aug--bl {
    left: 0px;
    bottom: 0px;
  }
  .icon__aug--l {
    top: var(--neghalfovfl);
    left: 0px;
  }
`, {
  ".icon__aug": {
    backgroundColor: "gray800"
  }
})

class AugIcon extends Component {
  // props: position, aug

  state = {}

  render () {
    const props = this.props
    const position = props.position
    const aug = props.aug

    return (
      <AugIconContainer role="img" aria-label={aug + " preview"}>
        <div className={"icon__aug icon__aug--" + position} data-augmented-ui={aug + " exe"}></div>
      </AugIconContainer>
    )
  }
}

export default AugIcon
