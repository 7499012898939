import React, { Component } from 'react'
import magicComponent from 'magic-react-component'
import ValueColorStopList from './ValueColorStopList'
import ValueCSSTypeCombo from '../ValueCSSTypeCombo'
import ValuePosition from '../ValuePosition'
import ValueExtent from './ValueExtent'
import { splitTopLevelCommas } from '../css-helpers'

const RadialGradientContainer = magicComponent("div", `
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 5px;
  align-items: center;
  vertical-align: middle;
  .span--2 {
    grid-column-end: span 2;
  }
  .span--3 {
    grid-column-end: span 3;
  }
`)

/*
  ----Radial Gradient UI----
  Shape:     [      ellipse      ]  // "ellipse", "circle"
  at      20px from [Top, Center, Bottom]
  and     20px from [Left, Center, Right]
  Stretch To: [                  ]  // closest-corner, closest-side, farthest-corner, farthest-side, custom radius
  Radius:     [         <length> ]  // stretch = custom radius & shape = circle
  X [     <l-p> ]  Y [     <l-p> ]  // stretch = custom radius & shape = ellipse

  Spec:
  [ [ circle || <length> ]                         [ at <position> ]? , |
    [ ellipse || [ <length> | <percentage> ]{2} ]  [ at <position> ]? , |
    [ [ circle | ellipse ] || <extent-keyword> ] [at <position> ]? , |
    at <position> ,
  ]?
*/

class ValueGradientRadial extends Component {
  // props: cssValue, onValueChanged, setPopover

  state = {}

  isAColorStopValue = cssValue => {
    cssValue = cssValue.replace(/\s+/g, " ").trim()
    // detect color values in the first top-level-comma-split of the gradient source which identifies it as the first color-stop
    if (/((?:\b(?:rgba|rgb|hsla|hsl|lch|lab)\()|(?:#[a-f0-9]{3,8}\b))/i.test(cssValue)) {
      return true
    }
    if (/^\d/.test(cssValue)) {
      // if it begins with a number, then it's a header because this is an extent value
      return false
    }
    if (/\b((?:cover|(?:closest|farthest)-(?:side|corner))|contain|circle|ellipse|at)\b/i.test(cssValue)) {
      // if header keywords are in this value, it's not a color stop
      return false
    }
    // named colors (...probably. TODO)
    return true
  }

  consumeShape = remainingCSS => {
    let shape = ((remainingCSS.match(/(circle|ellipse)/i) || [])[0] || "").toLowerCase()
    const unparsedCSS = shape ? remainingCSS.replace(shape, "") : remainingCSS
    return { shape, unparsedCSS }
  }

  consumeExtent = remainingCSS => {
    let extent = ((remainingCSS.match(/\b((?:cover|(?:closest|farthest)-(?:side|corner))|contain)\b/i) || [])[0] || "").toLowerCase()
    const unparsedCSS = extent ? remainingCSS.replace(extent, "") : remainingCSS
    // old implementations, convert to spec
    extent = extent === "cover" ? "farthest-corner" : extent
    extent = extent === "contain" ? "closest-side" : extent
    return { extent, unparsedCSS }
  }

  consumePosition = remainingCSS => {
    let position = ((remainingCSS.match(/(\bat\b[\s\S]+$)/i) || [])[0] || "").toLowerCase()
    const unparsedCSS = position ? remainingCSS.replace(position, "") : remainingCSS
    position = position.replace("at", "").trim() // "at" is just a parsing token, not part of position
    return { position, unparsedCSS }
  }

  parseConfigValue = radialConfigValue => {
    const configData = {
      shape: "",
      radius: "",
      radiusX: "",
      radiusY: "", // only for ellipse (implies ellipse if it's present)
      extent: "", // keyword implies radiusX & radiusY (default: farthest-corner)
      position: "",
      unparsedCSS: radialConfigValue || ""
    }
    Object.assign(configData, this.consumeShape(configData.unparsedCSS))
    Object.assign(configData, this.consumeExtent(configData.unparsedCSS))
    Object.assign(configData, this.consumePosition(configData.unparsedCSS))
    if (!configData.extent) {
      // only remaining properties in the string should be radii
      const radii = configData.unparsedCSS.replace(/\s+/g, " ").trim().split(" ")
      configData.radius = radii[0] || ""
      configData.radiusX = radii[0] || ""
      configData.radiusY = radii[1] || ""
    }
    delete configData.unparsedCSS
    return configData
  }

  getImpliedData = configData => {
    const impliedData = { position: configData.position }

    if (configData.extent) {
      impliedData.shape = configData.shape || "ellipse"
      impliedData.extentCSSValue = configData.extent
      return impliedData
    }

    if (configData.radiusY) {
      impliedData.shape = "ellipse"
      impliedData.extentCSSValue = configData.radiusX + " " + configData.radiusY
      return impliedData
    }

    impliedData.shape = configData.shape || "ellipse"

    if (impliedData.shape === "circle") {
      impliedData.extentCSSValue = configData.radius || ""
    } else if (impliedData.shape === "ellipse") {
      impliedData.extentCSSValue = (`${configData.radiusX || ""} ${configData.radiusY || configData.radiusX || ""}`).trim()
    }

    if (!impliedData.extentCSSValue) {
      impliedData.extentCSSValue = "farthest-corner"
    }

    return impliedData
  }

  reduceExtentToCircle = (extent = "") => {
    const isCustom = /\d/.test(extent)
    const radius = isCustom && extent.replace(/\s+/g, " ").trim().split(" ")[0].replace("%", "px")
    return isCustom ? radius : extent
  }

  // shape, size or extent, at position
  configChanged = (newValue, configProp, impliedConfigData, colorStops) => {
    const props = this.props
    // console.log(newValue)
    const data = Object.assign({}, impliedConfigData, { [configProp]: newValue })
    const gradientHeaders = []
    gradientHeaders.push(data.shape === "circle" ? "circle" : "")
    const extent = data.shape === "circle" ? this.reduceExtentToCircle(data.extentCSSValue) : data.extentCSSValue
    gradientHeaders.push(extent === "farthest-corner" ? "" : extent)
    gradientHeaders.push(data.position ? " at " + data.position : "")
    let cssValue = gradientHeaders.join(" ").replace(/\s+/g, " ").trim()
    cssValue = (cssValue ? cssValue + ", " : "") + colorStops.join(", ")
    props.onValueChanged && props.onValueChanged(cssValue)
  }

  colorStopChanged = (newListValue, radialConfigValue) => {
    const props = this.props
    radialConfigValue = radialConfigValue.trim()
    const result = (radialConfigValue ? radialConfigValue + ", " : "") + newListValue
    props.onValueChanged && props.onValueChanged(result)
  }

  render () {
    const props = this.props
    const cssValue = props.cssValue

    const colorStops = splitTopLevelCommas(cssValue)
    const radialConfigValue = this.isAColorStopValue(colorStops[0] || "") ? "" : colorStops[0]
    if (radialConfigValue) {
      // first one was the optional config, remaining values will be the color stop list
      colorStops.shift()
    }

    const configData = this.parseConfigValue(radialConfigValue)
    const impliedConfigData = this.getImpliedData(configData)

    return (
      <RadialGradientContainer>
        <span>Shape</span>
        <div className="span--2">
          <ValueCSSTypeCombo type={["ellipse", "circle"]} setPopover={props.setPopover}
            useLabel="radial shape" cssValue={impliedConfigData.shape}
            onValueChanged={val => this.configChanged(val.replace("0", ""), "shape", impliedConfigData, colorStops)} />
        </div>
        <ValuePosition className="span--3" defaultValue="center" setPopover={props.setPopover} cssValue={impliedConfigData.position}
          onValueChanged={val => this.configChanged(val, "position", impliedConfigData, colorStops)} />
        <ValueExtent className="span--3" setPopover={props.setPopover}
          shape={impliedConfigData.shape} cssValue={impliedConfigData.extentCSSValue}
          onValueChanged={val => this.configChanged(val, "extentCSSValue", impliedConfigData, colorStops)} />
        <ValueColorStopList className="span--3" setPopover={props.setPopover} cssValueArray={colorStops}
          onValueChanged={val => this.colorStopChanged(val, radialConfigValue)} />
      </RadialGradientContainer>
    )
  }
}

export default ValueGradientRadial
