import React, { Component } from 'react'
import ValueCSSTypeCombo from '../ValueCSSTypeCombo'
import ValueSlider from '../ValueSlider'
import magicComponent from 'magic-react-component'
import colors from './color-helpers'

const SlidersGrid = magicComponent("div", `
  display: grid;
  grid-template-columns: 12px calc(33% - 17px) 1fr 1fr;
  grid-gap: 5px;
  align-items: center;
  vertical-align: middle;
  .span--2 {
    grid-column-end: span 2;
  }
`, {
  color: "gray200"
})

class ValueHSLSliders extends Component {
  // props: cssValue, onValueChanged

  state = {}

  updateHSLPropValue = (hslObj, prop, newVal) => {
    const newHSLObj = Object.assign({}, hslObj, { [prop]: newVal })
    const newStr = colors.hslObjToString(newHSLObj)
    this.props.onValueChanged && this.props.onValueChanged(newStr)
  }

  render () {
    const props = this.props
    const cssValue = props.cssValue
    const updateHSLPropValue = this.updateHSLPropValue
    const hslObj = colors.anyColorStringToHSLObj(cssValue)
    const hueBG = `linear-gradient(to right,
                                        hsl(  0, 100%, 50%) 10px,
                                        hsl( 30, 100%, 50%),
                                        hsl( 60, 100%, 50%),
                                        hsl( 90, 100%, 50%),
                                        hsl(120, 100%, 50%),
                                        hsl(150, 100%, 50%),
                                        hsl(180, 100%, 50%),
                                        hsl(210, 100%, 50%),
                                        hsl(240, 100%, 50%),
                                        hsl(270, 100%, 50%),
                                        hsl(300, 100%, 50%),
                                        hsl(330, 100%, 50%),
                                        hsl(360, 100%, 50%) calc(100% - 10px)
    )`
    const saturationBG = `linear-gradient(to right,
                                        hsl(${hslObj.h}, 0%, ${hslObj.l}%) 10px,
                                        hsl(${hslObj.h}, 50%, ${hslObj.l}%),
                                        hsl(${hslObj.h}, 100%, ${hslObj.l}%) calc(100% - 10px)
    )`
    const lightnessBG = `linear-gradient(to right,
                                        hsl(${hslObj.h}, ${hslObj.s}%, 0%) 10px,
                                        hsl(${hslObj.h}, ${hslObj.s}%, 50%),
                                        hsl(${hslObj.h}, ${hslObj.s}%, 100%) calc(100% - 10px)
    )`
    return (
      <SlidersGrid>
        <span>H</span>
        <div className="span--2" style={{"--track-bg": hueBG}}>
          <ValueSlider value={hslObj.h / 359} onValueChange={v => updateHSLPropValue(hslObj, "h", v * 359)} />
        </div>
        <div>
          <ValueCSSTypeCombo useLabel={"Hue (degrees)"}
            type={["°"]} onValueChanged={v => updateHSLPropValue(hslObj, "h", parseFloat(v))}
            valmin={0} valmax={359} cssValue={hslObj.h + "°"} />
        </div>
        <span>S</span>
        <div className="span--2" style={{"--track-bg": saturationBG}}>
          <ValueSlider value={hslObj.s / 100} onValueChange={v => updateHSLPropValue(hslObj, "s", v * 100)} />
        </div>
        <div>
          <ValueCSSTypeCombo useLabel={"HSL Saturation Percent"}
            type="percentage" onValueChanged={v => updateHSLPropValue(hslObj, "s", parseFloat(v))}
            valmin={0} valmax={100} cssValue={hslObj.s + "%"} />
        </div>
        <span>L</span>
        <div className="span--2" style={{"--track-bg": lightnessBG}}>
          <ValueSlider value={hslObj.l / 100} onValueChange={v => updateHSLPropValue(hslObj, "l", v * 100)} />
        </div>
        <div>
          <ValueCSSTypeCombo useLabel={"HSL Lightness Percent"}
            type="percentage" onValueChanged={v => updateHSLPropValue(hslObj, "l", parseFloat(v))}
            valmin={0} valmax={100} cssValue={hslObj.l + "%"} />
        </div>
        <span>A</span>
        <div className="span--2">
          <ValueSlider value={hslObj.a} onValueChange={v => updateHSLPropValue(hslObj, "a", v)} />
        </div>
        <div>
          <ValueCSSTypeCombo useLabel={"HSL Alpha Value"} step={0.01}
            type={["A"]} onValueChanged={v => updateHSLPropValue(hslObj, "a", parseFloat(v))}
            valmin={0} valmax={1} cssValue={hslObj.a + "A"} />
        </div>
      </SlidersGrid>
    )
  }
}

export default ValueHSLSliders
