import React, { Component } from 'react'
import ValueCSSTypeCombo from './ValueCSSTypeCombo'
import magicComponent from 'magic-react-component'
import { splitTopLevelSpaces } from './css-helpers'

/*
  1, 2, 4 value syntax:
  [
     [ left | center | right ]   ||   [ top | center | bottom ]
    |
     [ left | center | right | <length> | <percentage> ]       [ top | center | bottom | <length> | <percentage> ]?
    |
     [ [ left | right ]    [ <length> | <percentage> ] ]     &&     [ [ top | bottom ]    [ <length> | <percentage> ] ]
  ]

  3 value syntax is 2 keywords and an offset, can't be 2 offsets and 1 keyword. offset applies to previous keyword
  3 value syntax is deprecated for everything but BG, may still be used as part of background
  (so allow consuming 3 values, but force 4 value output in that case just to remain consistent)

  "center" keyword cannot have an offset
*/
/*
  ...<position> kind of sucks. The restrictions seem unecessary & make parsing it worse... plus the bg-position distinctions. Boooo

  This would be a much better syntax.
  [
     [ left | right | center ]? <length-percentage>? [ top | bottom | center ]? <length-percentage>?
    |
     [ top | bottom | center ]? <length-percentage>? [ left | right | center ]? <length-percentage>?
  ]
  
  Arguments:
    unifies both <position> and <bg-position>,
    backwards compatible,
    supports offsets from center,
    supports 3 values (with 2 <l-p>s too),
    easier to read/write/describe the syntax as plain English
  rules (same as spec, with fewer restrictions):
    No values supplied, default is "top left"
    1 value is keyword or a <l-p> (second is implied "50%" (same as "center"))
    2 values: either can be keyword or <l-p>, one for each axis (this is not a keyword + <l-p> pair for a single axis)
    3 or 4 values supports keyword + offset pairs. If only an <l-p> is used, it's implied to offset top or left
    <l-p> values are an offset from top or left if not part of a keyword + <l-p> pair that specifies otherwise
    positive offsets from top|bottom|left|right move towards center
    positive offsets from center go towards bottom/right
  determining xy order for 1(+implied), 2, 3, or 4 values:
    if no keywords are used,
      order is x then y
    if both keywords are "center"
      order is x then y
    if the first keyword is "left" or "right" or the second keyword is "top" or "bottom"
      order is x then y
    else
      order is y then x

  This module will parse input based on this algorithm since it's backwards compatible.
  The UI will simply look like this:
    Y (20 px) from [Left, Center, Right]
    X (20 px) from [Top, Center, Bottom]
  This may output using one or two calc values to simulate the additional options.
  This module will also, at least, support input in the exact calc formats that it outputs.
  ex: calc(50% + -10px) calc(100%)
*/

const PositionContainer = magicComponent("div", `
  display: grid;
  grid-template-columns: 10px 1fr 25px 1fr;
  grid-gap: 5px;
  grid-row-gap: 5px;
  align-items: center;
  vertical-align: middle;
  border-top: 1px solid;
  border-bottom: 1px solid;

  .span--4 {
    grid-column-end: span 4;
  }
`, {
  mt: 1,
  py: 1,
  borderColor: "gray800"
})

class ValuePosition extends Component {
  // props: cssValue, onValueChanged, setPopover

  state = {
    // if css simplifies to equivelant (but different) value on output, use the unsimplified data to redraw the form
    // this keeps the UI from switching to completely different looking state mid-edit but still allows the resulting
    // css to be as minimal as it can be
    capturedData: null
  }

  getKeywordOffsetPairData = cssValue => {
    const cleanedValue = (cssValue || "").replace(/\s+/g, " ").trim()
    const valueList = splitTopLevelSpaces(cleanedValue)
    if (!cleanedValue) {
      return this.props.defaultValue === "center" ? {
        keywordA: "center",
        offsetA: "0%",
        keywordB: "center",
        offsetB: "0%"
      } : {
        keywordA: "left",
        offsetA: "0%",
        keywordB: "top",
        offsetB: "0%"
      }
    }
    if (valueList.length === 1) {
      const isKeyword = /top|bottom|left|right|center/.test(cleanedValue)
      return {
        keywordA: isKeyword ? cleanedValue : "",
        offsetA: isKeyword ? "0%" : cleanedValue,
        keywordB: "", // unresolved, top or left
        offsetB: "50%"
      }
    }
    if (valueList.length === 2) {
      const isAKeyword = /top|bottom|left|right|center/.test(valueList[0])
      const isBKeyword = /top|bottom|left|right|center/.test(valueList[1])
      return {
        keywordA: isAKeyword ? valueList[0] : "",
        offsetA: isAKeyword ? "0%" : valueList[0],
        keywordB: isBKeyword ? valueList[1] : "",
        offsetB: isBKeyword ? "0%" : valueList[1]
      }
    }
    // if (valueList.length > 2) {
    const isAKeyword = /top|bottom|left|right|center/.test(valueList[0])
    const is2Keyword = /top|bottom|left|right|center/.test(valueList[1])
    const is3Keyword = !is2Keyword && /top|bottom|left|right|center/.test(valueList[2])
    return {
      keywordA: isAKeyword ? valueList[0] : "",
      offsetA: isAKeyword ? (is2Keyword ? "0%" : valueList[1]) : valueList[0],
      keywordB: is3Keyword ? valueList[2] : valueList[1],
      offsetB: valueList[3] || valueList[2] 
    }
  }

  getValueData = cssValue => {
    const kwopData = this.getKeywordOffsetPairData(cssValue)
    // offsetA and offsetB always have the default (or specified) value returned in kwopData
    // the keywords are not necessarily resolved, due to unknown position, so must resolve here
    if (!kwopData.keywordA && !kwopData.keywordB) {
      // order is x then y
      return {
        xKeyword: "left",
        xOffset: kwopData.offsetA,
        yKeyword: "top",
        yOffset: kwopData.offsetB
      }
    }
    if (kwopData.keywordA === "center" && kwopData.keywordB === "center") {
      // order is x then y
      return {
        xKeyword: "center",
        xOffset: kwopData.offsetA,
        yKeyword: "center",
        yOffset: kwopData.offsetB
      }
    }
    if (/left|right/.test(kwopData.keywordA) || /top|bottom/.test(kwopData.keywordB)) {
      // order is x then y
      return {
        xKeyword: kwopData.keywordA || "left",
        xOffset: kwopData.offsetA,
        yKeyword: kwopData.keywordB || "top",
        yOffset: kwopData.offsetB
      }
    }
    // else order is y then x
    return {
      yKeyword: kwopData.keywordA || "top",
      yOffset: kwopData.offsetA,
      xKeyword: kwopData.keywordB || "left",
      xOffset: kwopData.offsetB
    }
  }

  // TODO: this is parsing calc very specifically to what this might output, should aim be more generic
  simplifyCalcOffsets = (valueData) => {
    const newData = {}
    const xParts = valueData.xOffset.startsWith("calc(") ? valueData.xOffset.replace("calc(", "").replace(")", "").split(" ") : []
    const yParts = valueData.yOffset.startsWith("calc(") ? valueData.yOffset.replace("calc(", "").replace(")", "").split(" ") : []
    if (xParts.length && xParts[0]) {
      if (xParts[0] === "50%") {
        newData.xKeyword = "center"
        if (xParts[1] === "-") {
          newData.xOffset = "-" + xParts[2]
        } else if (xParts.length === 3) {
          newData.xOffset = xParts[2]
        }
      } else if (xParts[0] === "100%") {
        newData.xKeyword = "right"
        if (xParts[1] === "+") {
          newData.xOffset = "-" + xParts[2]
        } else if (xParts.length === 3) {
          newData.xOffset = xParts[2]
        }
      } else if (xParts.length === 1) {
        newData.xKeyword = "left"
        newData.xOffset = xParts[0]
      }
      // else can't handle it yet
    }
    if (yParts.length && yParts[0]) {
      if (yParts[0] === "50%") {
        newData.yKeyword = "center"
        if (yParts[1] === "-") {
          newData.yOffset = "-" + yParts[2]
        } else if (yParts.length === 3) {
          newData.yOffset = yParts[2]
        }
      } else if (yParts[0] === "100%") {
        newData.yKeyword = "bottom"
        if (yParts[1] === "+") {
          newData.yOffset = "-" + yParts[2]
        } else if (yParts.length === 3) {
          newData.yOffset = yParts[2]
        }
      } else if (yParts.length === 1) {
        newData.yKeyword = "top"
        newData.yOffset = yParts[0]
      }
      // else can't handle it yet
    }
    return newData
  }

  captureOrRelease = (newData) => {
    const hasCenterKW = newData.xKeyword === "center" || newData.yKeyword === "center"
    const has50x = newData.xOffset === "50%"
    const has50y = newData.yOffset === "50%"
    const has100x = newData.xOffset === "100%"
    const has100y = newData.yOffset === "100%"
    const mustCaptrue = hasCenterKW || has50x || has50y || has100x || has100y
    if (mustCaptrue) {
      this.setState({ capturedData: Object.assign({}, newData) })
    } else {
      this.setState({ capturedData: null })
    }
  }

  propChanged = (newValue, prop, valueData) => {
    const newData = Object.assign({}, valueData, { [prop]: newValue })
    this.captureOrRelease(newData)

    const pos = {
      top: "",
      bottom: "100% - ",
      left: "",
      right: "100% - ",
      center: "50% + "
    }
    newData.xOffset = /^0[^.\d]+/.test(newData.xOffset) ? "" : newData.xOffset
    newData.yOffset = /^0[^.\d]+/.test(newData.yOffset) ? "" : newData.yOffset
    let simplifiedValue = `calc(${pos[newData.xKeyword] + newData.xOffset}) calc(${pos[newData.yKeyword] + newData.yOffset})`
    simplifiedValue = simplifiedValue.replace(/ [+-] \)/g, ")").replace(/ \+ -/g, " - ").replace(/ - -/g, " + ")
    simplifiedValue = simplifiedValue.replace(/calc\(100% - 50%\)/g, "center")
    simplifiedValue = simplifiedValue.replace(/calc\(50% - 50%\)/g, "calc()")
    simplifiedValue = simplifiedValue.replace(/calc\(100% - 100%\)/g, "calc()")
    simplifiedValue = simplifiedValue.replace(/calc\(50% \+ 50%\)/g, "calc(100%)")
    simplifiedValue = simplifiedValue.replace(/calc\(50%\)/g, "center").replace(" center", "")
    simplifiedValue = simplifiedValue.replace(/^calc\(\)/, "left").replace(/^calc\(100%\)/, "right")
    simplifiedValue = simplifiedValue.replace(/calc\(\)$/, "top").replace(/calc\(100%\)$/, "bottom")
    simplifiedValue = simplifiedValue.replace(/calc\((-?\d+(?:\.\d+)?[^\d)]*)\)/g, "$1")
    simplifiedValue = simplifiedValue.replace(/center (top|bottom)/g, "$1")
    
    const defaultValue = this.props.defaultValue || "top left"
    simplifiedValue = simplifiedValue === defaultValue ? "" : simplifiedValue

    // only produces 0, 1, or 2 value results, with or without calc()
    this.props.onValueChanged(simplifiedValue)
  }

  render () {
    const props = this.props
    const cssValue = props.cssValue
    const valueData = this.state.capturedData || this.getValueData(cssValue)
    this.state.capturedData || Object.assign(valueData, this.simplifyCalcOffsets(valueData))

    return (
      <PositionContainer className={props.className}>
        <div className="span--4" aria-label="Positioned at">Positioned at</div>
        <span>X</span>
        <ValueCSSTypeCombo type="length-percentage" setPopover={props.setPopover}
          useLabel="x position offset" cssValue={valueData.xOffset} valmin={-1500} valmax={1500}
          onValueChanged={val => this.propChanged(val, "xOffset", valueData)} />
        <span>from</span>
        <ValueCSSTypeCombo type={["left", "center", "right"]} setPopover={props.setPopover}
          useLabel="x position offset" cssValue={valueData.xKeyword}
          onValueChanged={val => this.propChanged(val.replace("0", ""), "xKeyword", valueData)} />
        <span>Y</span>
        <ValueCSSTypeCombo type="length-percentage" setPopover={props.setPopover}
          useLabel="y position offset" cssValue={valueData.yOffset} valmin={-1500} valmax={1500}
          onValueChanged={val => this.propChanged(val, "yOffset", valueData)} />
        <span>from</span>
        <ValueCSSTypeCombo type={["top", "center", "bottom"]} setPopover={props.setPopover}
          useLabel="y position offset" cssValue={valueData.yKeyword}
          onValueChanged={val => this.propChanged(val.replace("0", ""), "yKeyword", valueData)} />
      </PositionContainer>
    )
  }
}

export default ValuePosition
