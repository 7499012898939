import React, { Component } from 'react'
import ValueAnyColor from '../colors/ValueAnyColor'
import ValueCSSTypeCombo from '../ValueCSSTypeCombo'
import ColorIcon from '../colors/ColorIcon'
import Button from '../../Button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import magicComponent from 'magic-react-component'
import colors from '../colors/color-helpers'
import { parseColorStop } from './gradient-helpers'
import spaceEnterClick from '../../../utils/space-enter-click'

const ColorStopContainer = magicComponent("div", `
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 18px 18px 26px;
  grid-gap: 5px;
  grid-row-gap: 2px;
  grid-template-areas: "color-icon color-icon delete-cs" "color-icon color-icon add-new-cs" "color-stop color-stop color-stop";
  .cs__delete {
    grid-area: delete-cs;
    cursor: pointer;
  }
  .cs__add-new {
    grid-area: add-new-cs;
    cursor: pointer;
  }
  .cs__action__icon {
    width: 100%;
    height: 100%;
    background: radial-gradient(circle at center, rgba(255, 255, 255, 0.5) 50%, transparent 50%);
  }
  .cs__icon {
    grid-area: color-icon;
    display: block;
    margin-left: auto;
    margin-right: auto;
    height: 100%;
    width: 100%;
    cursor: pointer;
  }
  .cs__stop {
    grid-area: color-stop;
    width: 100%;
  }
`, {
  ".cs__icon": {
    mb: 1
  },
  ".cs__delete": {
    color: "red900"
  },
  ".cs__add-new": {
    color: "gray900"
  }
})

class ColorStop extends Component {
  // props: cssValue, onValueChanged, onAddClicked, onDeleteClicked, setPopover

  state = {
    colorBeforeEditing: null
  }

  initChooseColor = (color, stop, refocusEl) => {
    stop = stop === "auto" ? "" : (" " + stop)
    const colorBeforeEditing = this.state.colorBeforeEditing
    if (!colorBeforeEditing) {
      this.setState({ colorBeforeEditing: color })
    }

    const doRefocus = () => refocusEl && refocusEl.focus && refocusEl.focus()

    const restoreColorClosePopoverAndRefocus = () => {
      this.props.onValueChanged(this.state.colorBeforeEditing + stop)
      this.props.setPopover()
      doRefocus()
    }
    const confirmAndRefocus = () => {
      this.setState({ colorBeforeEditing: null })
      this.props.setPopover()
      doRefocus()
    }
    const redrawOnColorChanged = val => {
      const closureColor = val
      this.props.onValueChanged(val + stop)
      // redraw the popover with the new value
      this.renderPopover(closureColor, redrawOnColorChanged, confirmAndRefocus, restoreColorClosePopoverAndRefocus)
    }
    this.renderPopover(color, redrawOnColorChanged, confirmAndRefocus, restoreColorClosePopoverAndRefocus)
  }

  renderPopover = (color, redrawOnColorChanged, onConfirm, onCancel) => {
    this.props.setPopover(
      "Choose Color for this position",
      (<React.Fragment>
        <ValueAnyColor cssValue={color} onValueChanged={redrawOnColorChanged} />
        <div style={{margin: "5px", display: "flex", justifyContent: "space-around"}}>
          <Button onClick={onConfirm}>Confirm</Button>
          <Button onClick={onCancel} variant="error">Cancel</Button>
        </div>
      </React.Fragment>),
      onCancel
    )
  }

  stopChanged = (newStop, oldStop, color) => {
    const onValueChanged = this.props.onValueChanged
    if (newStop.includes("auto")) {
      onValueChanged(color)
    } else {
      onValueChanged(color + " " + newStop)
    }
  }

  render () {
    const props = this.props
    const cssValue = props.cssValue
    const { color, stop = "auto" } = parseColorStop(cssValue)
    const resolvedColor = colors.resolveAnyColorStr(color)

    return (
      <ColorStopContainer className={props.className}>
        <ColorIcon className="cs__icon" style={{"--display-color": resolvedColor}} {...spaceEnterClick(ev => this.initChooseColor(color, stop, ev.target))} />
        <div className="cs__stop">
          <ValueCSSTypeCombo type="length-percentage" cssValue={stop} extraOptions={["auto"]} setPopover={props.setPopover}
            useLabel="color stop position" onValueChanged={val => this.stopChanged(val, stop, color)} />
        </div>
        {props.onDeleteClicked
          ? <div className="cs__delete" title="Delete Color Stop" {...spaceEnterClick(props.onDeleteClicked)}>
              <FontAwesomeIcon className="cs__action__icon" icon="window-close" />
            </div>
          : null
        }
        {props.onAddClicked
          ? <div className="cs__add-new" title="Add New Color Stop" {...spaceEnterClick(props.onAddClicked)}>
              <FontAwesomeIcon className="cs__action__icon" icon="plus-square" />
            </div>
          : null
        }
      </ColorStopContainer>
    )
  }
}

export default ColorStop
