
const cornerBordersOnly = `
  --aug-border: 2px;
  --aug-border-fallback-color: gold;
  --aug-border-bg: radial-gradient(circle at left bottom, gold 30px, transparent 30px),
                   radial-gradient(circle at right bottom, gold 30px, transparent 30px),
                   radial-gradient(circle at right top, gold 30px, transparent 30px),
                   radial-gradient(circle at left top, gold 30px, transparent 30px);
`

const cornerBordersDots = `
  --aug-border: 5px;
  --aug-border-fallback-color: gold;
  --aug-border-bg: radial-gradient(circle at 5px calc(100% - 5px), gold 5px, transparent 5px),
                   radial-gradient(circle at calc(100% - 5px) calc(100% - 5px), gold 5px, transparent 5px),
                   radial-gradient(circle at calc(100% - 5px) 5px, gold 5px, transparent 5px),
                   radial-gradient(circle at 5px 5px, gold 5px, transparent 5px),
                   linear-gradient(to bottom, transparent 4px, gold 4px, gold 5px, transparent 5px),
                   linear-gradient(to right, transparent 4px, gold 4px, gold 5px, transparent 5px),
                   linear-gradient(to left, transparent 4px, gold 4px, gold 5px, transparent 5px),
                   linear-gradient(to top, transparent 4px, gold 4px, gold 5px, transparent 5px);
`

const thickCornersThinEdges = `
  --aug-border: 5px;
  --aug-border-fallback-color: gold;
  --aug-border-bg: radial-gradient(circle at left bottom, gold 30px, transparent 30px),
                   radial-gradient(circle at right bottom, gold 30px, transparent 30px),
                   radial-gradient(circle at right top, gold 30px, transparent 30px),
                   radial-gradient(circle at left top, gold 30px, transparent 30px),
                   linear-gradient(to bottom, transparent 2px, gold 2px, gold 3px, transparent 3px),
                   linear-gradient(to right, transparent 2px, gold 2px, gold 3px, transparent 3px),
                   linear-gradient(to left, transparent 2px, gold 2px, gold 3px, transparent 3px),
                   linear-gradient(to top, transparent 2px, gold 2px, gold 3px, transparent 3px);
  --aug-tl: 20px;
  --aug-br: 20px;
`

const clamOpenHorizontal = `
  --aug-border: 5px;
  --aug-border-fallback-color: gold;
  --aug-border-bg: radial-gradient(circle at left bottom, gold 30px, transparent 30px),
                   radial-gradient(circle at right bottom, gold 30px, transparent 30px),
                   radial-gradient(circle at right top, gold 30px, transparent 30px),
                   radial-gradient(circle at left top, gold 30px, transparent 30px),
                   linear-gradient(gold 100%, transparent) top / 50% 20px no-repeat,
                   linear-gradient(gold 100%, transparent) bottom / 50% 20px no-repeat,
                   linear-gradient(to bottom, transparent 2px, gold 2px, gold 3px, transparent 3px),
                   linear-gradient(to top, transparent 2px, gold 2px, gold 3px, transparent 3px);
  --aug-t-height: 5px;
  --aug-t-width: 40%;
  --aug-b-height: 5px;
  --aug-b-width: 40%;
`

const clamOpenVertical = `
  --aug-border: 5px;
  --aug-border-fallback-color: gold;
  --aug-border-bg: radial-gradient(circle at left bottom, gold 30px, transparent 30px),
                   radial-gradient(circle at right bottom, gold 30px, transparent 30px),
                   radial-gradient(circle at right top, gold 30px, transparent 30px),
                   radial-gradient(circle at left top, gold 30px, transparent 30px),
                   linear-gradient(gold 100%, transparent) left / 20px 50% no-repeat,
                   linear-gradient(gold 100%, transparent) right / 20px 50% no-repeat,
                   linear-gradient(to left, transparent 2px, gold 2px, gold 3px, transparent 3px),
                   linear-gradient(to right, transparent 2px, gold 2px, gold 3px, transparent 3px);
  --aug-r-height: 40%;
  --aug-r-width: 5px;
  --aug-l-height: 40%;
  --aug-l-width: 5px;
`

const cornersErr = `
  --aug-tl: 20px;
  --aug-tr: 10px;
  --aug-br: 20px;
  --aug-bl: 10px;
`

const purpBlueBlog = `
  --aug-border: 5px;
  --aug-border-fallback-color: rgba(255, 215, 0, 0.5);
  --aug-tl: 30px;
  --aug-tr: 30px;
  --aug-tr-width: 150px;
  --aug-br: 30px;
  --aug-br-width: 80px;
  --aug-border-bg: linear-gradient(to bottom left, hsl(278, 100%, 50%), hsl(170, 77%, 58%));
  --aug-inset: 5px;
  --aug-inset-bg: radial-gradient(rgba(135, 206, 235, 0.25) 1px, transparent 1px) center / 50px 50px, hsla(280, 100%, 18%, 0.5);
`

const purpOrange = `
  --aug-border: 5px;
  --aug-border-fallback-color: rgba(255, 215, 0, 0.5);
  --aug-tl: 30px;
  --aug-tr: 30px;
  --aug-tr-width: 150px;
  --aug-br: 30px;
  --aug-br-width: 80px;
  --aug-border-bg: linear-gradient(to bottom left, hsl(278, 100%, 50%), hsl(34, 77%, 58%));
  --aug-inset: 5px;
  --aug-inset-bg: radial-gradient(hsla(0, 71%, 73%, 0.25) 1px, transparent 1px) center / 50px 50px, hsla(280, 100%, 18%, 0.5);
`

const blogAvatar = `
  --aug-border: 3px;
  --aug-border-bg: #ffffff;
  --aug-border-fallback-color: #ffffff;
  --aug-border-opacity: 0.85;
  --aug-inset: 3px;
  --aug-inset-bg: #ffffff;
  --aug-inset-opacity: 0.33;
  --aug-bl: 20px;
  --aug-tl: 10px;
  --aug-tr: 10px;
`

const blogTwitter = `
  --aug-border: 3px;
  --aug-border-bg: #ffffff;
  --aug-border-fallback-color: #ffffff;
  --aug-border-opacity: 0.85;
  --aug-inset: 3px;
  --aug-inset-bg: #1da1f2;
  --aug-inset-opacity: 0.85;
  --aug-bl: 20px;
`

const blogFacebook = `
  --aug-border: 3px;
  --aug-border-bg: #ffffff;
  --aug-border-fallback-color: #ffffff;
  --aug-border-opacity: 0.85;
  --aug-inset: 3px;
  --aug-inset-bg: #3b5998;
  --aug-inset-opacity: 0.85;
  --aug-bl: 20px;
`

const blogNext = `
  --aug-border: 3px;
  --aug-border-bg: #ffffff;
  --aug-border-fallback-color: #ffffff;
  --aug-border-opacity: 0.85;
  --aug-inset: 3px;
  --aug-inset-bg: #ffffff;
  --aug-inset-opacity: 0.33;
  --aug-tl: 20px;
`

const fileIcon = `
  background: #ffd700;
  --aug-tl: 5px;
  --aug-br: 5px;
`

const folderOutline = `
  --aug-border: 2px;
  --aug-border-bg: #ffd700;
  --aug-border-fallback-color: hsla(298, 100%, 58%, 0.5);
  --aug-tr-width: 70px;
  --aug-tr: 8px;
  --aug-tl: 8px;
`

const smallLabel = `
  background: #ffffff;
  --aug-border: 1px;
  --aug-border-bg: #000000;
  --aug-border-opacity: 0.25;
  --aug-inset: 1px;
  --aug-inset-bg: black;
  --aug-tr: 8px;
  --aug-bl: 8px;
`

const optionLeft = `
  --aug-border: 2px;
  --aug-border-bg: gold;
  --aug-tr: 25px;
  --aug-bl: 25px;
  --aug-inset: 4px;
  --aug-inset-bg: #211c00;
  --aug-tl: 4px;
  --aug-br: 4px;
`

const optionRight = `
  --aug-border: 2px;
  --aug-border-bg: gold;
  --aug-tl: 25px;
  --aug-br: 25px;
  --aug-inset: 4px;
  --aug-inset-bg: #211c00;
  --aug-tr: 4px;
  --aug-bl: 4px;
`

const optionOn = "rgb(255, 215, 0)"
const optionOff = "#211c00"

const myAugfiguration = `
  --aug-tr: 25px;
  --aug-bl: 57px;
  --aug-br-width: 25px;
  --aug-br-height: 50%;
  --aug-border: 10px;
  --aug-border-bg: linear-gradient(to bottom right, #b1ffff, #ff604d);
  --aug-border-fallback-color: #d8aea4;
  --aug-inset: 10px;
  --aug-inset-bg: #fbe806;
  background: linear-gradient(#ff604d, #b1ffff);
`

const fallbackContainer = `
  --aug-border: 10px;
  --aug-border-bg: radial-gradient( at top right, rgba(255, 128, 0, 0.95), transparent 90% ), radial-gradient( farthest-side at top left, rgba(255, 215, 0, 0.53), transparent 90% ), 0px 0px/85px 85px repeating-radial-gradient(#410, #ffd700 1px, transparent 1px, transparent 15px ), #000011;
  --aug-border-opacity: 0.66;
  --aug-border-fallback-color: orange;
  --aug-tl: 40px;
  --aug-tr: 40px;
  --aug-br: 40px;
  --aug-bl: 40px;
  --aug-b-height: 20px;
  --aug-b-width: 400px;
  --aug-inset: 10px;
  --aug-inset-bg: radial-gradient( at top right, rgba(255, 128, 0, 0.95), transparent 90% ), radial-gradient( farthest-side at top left, rgba(255, 215, 0, 0.53), transparent 90% ), 0px 0px/85px 85px repeating-radial-gradient(#410, #ffd700 1px, transparent 1px, transparent 15px ), #000011;
`

const classes = [
  { className: "x_corners-square", css: cornerBordersOnly.replace(/gold/g, "#fbf500") },
  { className: "x_corners-clip", css: cornerBordersOnly.replace(/gold/g, "#83e9e2") },
  { className: "x_corners-err", css: cornerBordersOnly.replace(/gold/g, "#e83b5e") + "\n  " + cornersErr },
  { className: "x_corners-dots", css: cornerBordersDots.replace(/gold/g, "#d8d1ba") },
  { className: "x_corners-thick", css: thickCornersThinEdges.replace(/gold/g, "#7442b3") },
  { className: "x_edges-vertical", css: clamOpenVertical.replace(/gold/g, "#567bb2") },
  { className: "x_edges-horizontal", css: clamOpenHorizontal.replace(/gold/g, "#71ab70") },
  { className: "x_blog-container", css: purpBlueBlog },
  { className: "x_blog-purple-orange", css: purpOrange },
  { className: "x_blog-avatar", css: blogAvatar },
  { className: "x_blog-twitter", css: blogTwitter },
  { className: "x_blog-facebook", css: blogFacebook },
  { className: "x_blog-next", css: blogNext },
  { className: "x_file-icon", css: fileIcon },
  { className: "x_folder-outline", css: folderOutline },
  { className: "x_small-label", css: smallLabel },
  { className: "x_option-left-on", css: optionLeft.replace("gold", optionOn) },
  { className: "x_option-right-on", css: optionRight.replace("gold", optionOn) },
  { className: "x_option-left-off", css: optionLeft.replace("gold", optionOff) },
  { className: "x_option-right-off", css: optionRight.replace("gold", optionOff) },
  { className: "x_my-augfiguration", css: myAugfiguration },
  { className: "x_fallback-container", css: fallbackContainer }
]

export const exampleClasses = classes.reduce((obj, el) => {
  obj[el.className] = el
  return obj
}, {})
