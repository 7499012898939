import React, { Component } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import magicComponent from 'magic-react-component'

const ColorOption = magicComponent("button", `
  width: 2em;
  height: 2em;
  border: 1px solid black;
  float: left;
  clear: both;
  cursor: pointer;
`, {
  mx: 2,
  my: 1,
  p: 0,
  backgroundColor: ({magicProps}) => magicProps.color,
  outline: ({magicScale}) => "2px solid " + magicScale("colors", ({magicProps}) => magicProps.active ? "gold" : "transparent"),
  "&:hover, &:focus": {
    outline: ({magicScale}) => "2px dashed " + magicScale("colors", "blue700")
  }
})

const ActionButton = magicComponent("button", `
  position: relative;
  border: none;
  background: none;
  float: left;
  clear: both;
  cursor: pointer;
  white-space: nowrap;
  vertical-align: middle;
  z-index: 999;
  svg {
    vertical-align: middle;
    margin-right: 10px;
  }
  span {
    display: inline-block;
    vertical-align: middle;
    overflow: hidden;
    width: 0px;
    transition: width 0.3s ease-in;
  }
  &:focus, &:hover {
    background: rgba(0, 0, 0, 0.25);
    svg + span {
      width: auto;
    }
  }
`, {
  mx: 2,
  my: 1,
  p: 1,
  pr: 2,
  fontSize: 5
})

class Canvas extends Component {
  // props: bgCallback, defaultColor, showPage

  constructor (props) {
    super(props)
    const colorPairs = [
      { bg: "black", fg: "white" }, 
      { bg: "gray700", fg: "white" }, 
      { bg: "gray500", fg: "black" }, 
      { bg: "gray300", fg: "black" }, 
      { bg: "white", fg: "black" }
    ]

    const defaultColor = props.defaultColor || "gray500"
    const selectedColorPair = colorPairs.filter(p => p.bg === defaultColor)[0]

    this.state = {
      colorPairs,
      selectedColorPair
    }
  }

  setBg = val => {
    this.setState({ selectedColorPair: val })
    this.props.bgCallback && this.props.bgCallback(val.bg)
  }

  render () {
    const state = this.state
    const { selectedColorPair } = state
    const props = this.props

    return (
      <div style={{"--ui-color": selectedColorPair.fg}}>
        <ActionButton style={{color: selectedColorPair.fg}} onClick={() => props.showPage("Load")}>
          <FontAwesomeIcon icon="folder-plus" /><span>Add Element</span>
        </ActionButton>
        {state.colorPairs.map(
          pair => <ColorOption key={pair.bg} color={pair.bg} active={pair === selectedColorPair} onClick={() => this.setBg(pair)} />
        )}
        {props.children}
      </div>
    )
  }
}

/*
        <ActionButton style={{color: selectedColorPair.fg}}>
          <FontAwesomeIcon icon="sd-card" /><span>Save All</span>
        </ActionButton>
        <ActionButton style={{color: selectedColorPair.fg}} onClick={() => props.showPage("Load")}>
          <FontAwesomeIcon icon="folder-plus" /><span>Add Element</span>
        </ActionButton>
        <ActionButton style={{color: selectedColorPair.fg}}>
          <FontAwesomeIcon icon="random" /><span>Load Set</span>
        </ActionButton>
*/

export default Canvas
