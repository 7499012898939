import React, { Component } from 'react'
import spaceEnterClick from '../../utils/space-enter-click'
import magicComponent from 'magic-react-component'

const AddElementContainer = magicComponent("div", `
  position: relative;

  .add-menu {
    position: absolute;
    left: 0px;
    right: 0px;
    opacity: 1;
  }
  .add-menu__scrollable-container {
    overflow-y: auto;
    height: calc(100vh - 200px);
  }
  .add-menu__preview-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, 182px);
    grid-gap: 10px;
    grid-row-gap: 15px;
    grid-auto-rows: 210px;
    align-items: center;
    justify-content: space-between;
  }
  .add-menu__preview-grid__item {
    cursor: pointer;
    position: relative;
    height: 100%;
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    border: 1px solid rgba(255, 215, 0, 0.25);
    border-radius: 5px;
    span {
      background: rgba(0, 0, 0, 0.5);
      display: block;
      padding: 2px;
    }

    &::before {
      content: "";
      border-radius: 5px;
      position: absolute;
      background: var(--preview-bg, "white");
      opacity: 0.25;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: -1;
    }
    &:focus, &:hover {
      &::before {
        opacity: 0.75;
      }
    }
  }
  .add-menu__preview-grid__box {
    position: relative;
    width: 100%;
    height: 180px;
    margin-bottom: 5px;
  }
  .add-menu__preview-grid__augmented {
    position: absolute;
    top: 50%;
    left: 50%;
    transform-origin: 50% 50%;
    transform: translate(-50%, -50%) scale(var(--scale, 1));
  }
`, {
  ".add-menu": {
    m: 1,
    p: 2,
    color: "gold",
    fontSize: 5
  },
  ".add-menu__note": {
    color: "gold",
    fontSize: 4,
    my: 2
  },
  ".add-menu__scrollable-container": {
    px: 2
  },
  ".add-menu__preview-grid__item": {
    fontSize: 3
  }
})

class AddElement extends Component {
  // props: onAdd, previewBG, classManager, elementManager

  constructor (props) {
    super(props)
    this.state = {}
  }

  renderPreview = (el) => {
    const elementWidthScale = 180 / (el.w || 180)
    const elementHeightScale = 180 / (el.h || 180)
    const minScale = Math.min(Math.min(elementWidthScale, elementHeightScale), 1)
    const elClass = el.classes.join(" ")
    const addEl = () => {
      this.props.elementManager.addToPage(el.key)
      this.props.onAdd()
    }
    const label = "Add this " + elClass + " element to the page"
    return (
      <div key={el.key} className="add-menu__preview-grid__item" aria-label={label} {...spaceEnterClick(addEl)} tabIndex="0">
        <div className="add-menu__preview-grid__box" aria-hidden="true">
          <div style={{"--scale": minScale, height: (el.h || 180) + "px", width: (el.w || 180) + "px"}}
            className={elClass + " add-menu__preview-grid__augmented"}
            data-augmented-ui={el.data["augmented-ui"]}
          ></div>
        </div>
        <span>{elClass}</span>
      </div>
    )
  }

  render () {
    const props = this.props
    const { previewBG, elementManager } = props
    const allExampleElements = elementManager.exampleElements
    const allSavedElements = elementManager.savedElements
    const savedElementsLoading = !allSavedElements

    return (
      <AddElementContainer magic={{"--preview-bg": ({magicScale}) => magicScale("colors", previewBG || "white")}}>
        <div className="add-menu highlighted">
          <div className="add-menu__title">Add element to the page</div>
          <div className="add-menu__scrollable-container hide-scrollbars">
            <div className="add-menu__note">Locally Saved Elements</div>
            {savedElementsLoading
              ? <div className="add-menu__note">Loading Locally Saved Elements.|...</div>
              : <div className="add-menu__preview-grid">
                  {allSavedElements.map(key => this.renderPreview(elementManager.allSourceElements[key]))}
                </div>
            }
            <div className="add-menu__note">Example Elements</div>
            <div className="add-menu__preview-grid">
              {allExampleElements.map(key => this.renderPreview(elementManager.allSourceElements[key]))}
            </div>
          </div>
        </div>
      </AddElementContainer>
    )
  }
}

export default AddElement
