import React, { Component } from 'react'
import Button from '../Button'
import magicComponent from 'magic-react-component'

const AppInfoContainer = magicComponent("article", `
  position: absolute;
  left: 0px;
  right: 0px;
  opacity: 1;
  overflow-y: auto;
  height: calc(100vh - 200px);

  a, p {
    color: white;
  }
`, {
  px: 4
})

class AppInfo extends Component {
  // props:

  constructor (props) {
    super(props)
    this.state = {}
  }

  render () {
    // const props = this.props

    return (
      <AppInfoContainer className="hide-scrollbars">
        <h2>Augooiigooey tech</h2>
        <p>
          A WYSIWYG Editor that allows you to create
          {" "}<a href="https://github.com/propjockey/augmented-ui">augmented-ui</a> elements for use in
          any project across the web without writing code. <a href="https://twitter.com/hashtag/NoCode?src=hashtag_click">#NoCode</a>
          <br />
          Augooiigooey relies on these open source projects:
        </p>
        <ul>
          <li><a href="https://github.com/facebook/create-react-app">Create React App</a></li>
          <li><a href="https://github.com/styled-components/styled-components">styled-components</a></li>
          <li><a href="https://github.com/styled-system/styled-system">styled-system</a></li>
          <li><a href="https://github.com/localForage/localForage">localForage</a></li>
          <li><a href="https://github.com/FortAwesome/react-fontawesome">react-fontawesome</a></li>
        </ul>
        <h2>Augooiigooey accessibility</h2>
        <p>
          Built with <a href="https://a11yproject.com/">A11Y accessibility</a> at heart,
          using aria attributes for screen readers and full keyboard control across the entire app,
          Augooiigooey makes Sci-Fi and CyberPunk web design more accessible than ever before.
        </p>
        <p>
          If anything falls short of your accessibility expectations, or if you have any feedback in general,
          please do not hesitate to reach out to me by filing an issue on the
          {" "}<a href="https://github.com/propjockey/Augooiigooey/issues">github repository</a> or,
          if you prefer, privately at <a href="mailto:james@augmented-ui.com">james@augmented-ui.com</a>.
          I appreciate the opportunity to learn from you and I will do everything I can to address any concerns.
        </p>
        <h2>Supporting Augooiigooey</h2>
        <p>
          I spent most of the last 2 years working on (mainly)
          {" "}<a href="https://github.com/propjockey/">open source projects</a> and started applying for jobs as I
          neared the end of my savings... just in time for COVID-19.<br />
          <br />
          So I am paying rent with my credit card now and I need a job, preferably remote but definitely
          willing to relocate for the right opportunity.<br />
          If you think I could be an asset to your company, please reach out to me on
          {" "}<a href="https://www.linkedin.com/in/james0x57/">LinkedIn</a> or by email at
          {" "}<a href="mailto:james@augmented-ui.com">james@augmented-ui.com</a>.<br />
          <br />
          If you enjoy this app or augmented-ui enough to donate, you can find my
          {" "}<a href="http://augmented-ui.com/docs/#supporting-augmented-ui">PayPal and BitCoin info here</a>.<br />
          Alternatively, I have an
          {" "}<a href="https://opencollective.com/augmented-ui">Open Collective account set up for augmented-ui</a> too.<br />
          I sincerely appreciate it.<br />
          <br />
          In any case, thank you for checking out Augooiigooey!
        </p>
        <Button onClick={this.props.exitCallback}>Exit back to the app</Button>
      </AppInfoContainer>
    )
  }
}

export default AppInfo
