import React, { Component } from 'react'
import AugIcon from './AugIcon'
import ValueCSSTypeCombo from '../ValueCSSTypeCombo'
import magicComponent from 'magic-react-component'
import { getCSSPropValue, replaceCSS } from '../css-helpers'

const GridLayout = magicComponent("div", `
  display: grid;
  grid-template-columns: 50px 1fr 50px 1fr;
  grid-auto-rows: auto;
  grid-row-gap: 5px;
  grid-gap: 5px;
  align-items: center;

  .grid__span2 {
    grid-column-end: span 2;
  }
  .grid__span3 {
    grid-column-end: span 3;
  }
  .augprop__icon {
    position: relative;
    height: 0px;
  }
  .augprop__icon > * {
    position: absolute;
    transform: translateX(50%);
    top: -25px;
    width: 30px;
    height: 30px;
  }
`)

const SubSectionContainer = magicComponent("div", `
  border-bottom: 1px solid;
`, {
  py: 1,
  borderColor: "gray800",
  ".container__title": {
    pb: 1,
    fontSize: 3
  }
})

class AugProps extends Component {
  // props: aug, css, onCSSChanged, setPopover

  state = {
    isEdgeAug: { t: true, r: true, b: true, l: true },
    tl: "Top-Left Aug",
    t: "Top Aug",
    tr: "Top-Right Aug",
    r: "Right Aug",
    br: "Bottom-Right Aug",
    b: "Bottom Aug",
    bl: "Bottom-Left Aug",
    l: "Left Aug"
  }

  augPosition = aug => {
    let pos = aug.substr(0, 3)
    if (/-/.test(pos)) {
      return pos.replace(/-.*$/, "")
    }
    return false
  }

  allowWH = aug => {
    return !(/clip$|round$/.test(aug))
  }

  getCurrentValues = (pos, allowWH, isEdgeAug) => {
    const css = this.props.css
    const sizeProp = "--aug-" + pos
    const currentValues = {
      sizeProp,
      [sizeProp]: getCSSPropValue(css, sizeProp)
    }
    if (allowWH) {
      const widthProp = sizeProp + "-width"
      const heightProp = sizeProp + "-height"
      currentValues.widthProp = widthProp
      currentValues.heightProp = heightProp
      currentValues[widthProp] = getCSSPropValue(css, widthProp)
      currentValues[heightProp] = getCSSPropValue(css, heightProp)
    }
    if (isEdgeAug) {
      const centerProp = sizeProp + "-origin-" + (/t|b/.test(pos) ? "x" : "y")
      const offsetProp = sizeProp + "-offset"
      currentValues.centerProp = centerProp
      currentValues.offsetProp = offsetProp
      currentValues[centerProp] = getCSSPropValue(css, centerProp)
      currentValues[offsetProp] = getCSSPropValue(css, offsetProp)
    }
    return currentValues
  }

  widthChanged = (newVal, oldValues) => {
    const prop = oldValues.widthProp
    const oldVal = oldValues[prop]

    if (newVal.indexOf("auto") > -1) {
      newVal = ""
    }

    const newCSS = replaceCSS(this.props.css, prop, oldVal, newVal)
    this.props.onCSSChanged(newCSS)
  }

  heightChanged = (newVal, oldValues) => {
    const prop = oldValues.heightProp
    const oldVal = oldValues[prop]

    if (newVal.indexOf("auto") > -1) {
      newVal = ""
    }

    const newCSS = replaceCSS(this.props.css, prop, oldVal, newVal)
    this.props.onCSSChanged(newCSS)
  }

  renderWH = (aug, currentValues) => {
    const autoMsg = "auto: calculation from size"
    const width = currentValues[currentValues.widthProp] || autoMsg
    const height = currentValues[currentValues.heightProp] || autoMsg
    const widthTypeSupport = /-rect$|-clip-x$/.test(aug) ? "length-percentage" : "length"
    const heightTypeSupport = /-rect$|-clip-y$/.test(aug) ? "length-percentage" : "length"
    return (
      <React.Fragment>
        <span>Width</span>
        <div className="grid__span3">
          <ValueCSSTypeCombo useLabel={aug + " width"}
            setPopover={this.props.setPopover} onValueChanged={newVal => this.widthChanged(newVal, currentValues)}
            extraOptions={[autoMsg]} type={widthTypeSupport}
            valmax={500} cssValue={width} />
        </div>
        <span>Height</span>
        <div className="grid__span3">
          <ValueCSSTypeCombo useLabel={aug + " height"}
            setPopover={this.props.setPopover} onValueChanged={newVal => this.heightChanged(newVal, currentValues)}
            extraOptions={[autoMsg]} type={heightTypeSupport}
            valmax={500} cssValue={height} />
        </div>
      </React.Fragment>
    )
  }

  centerChanged = (newVal, oldValues) => {
    const prop = oldValues.centerProp
    const oldVal = oldValues[prop]

    if (newVal.indexOf("between") > -1) {
      newVal = ""
    }

    const newCSS = replaceCSS(this.props.css, prop, oldVal, newVal)
    this.props.onCSSChanged(newCSS)
  }

  offsetChanged = (newVal, oldValues) => {
    const prop = oldValues.offsetProp
    const oldVal = oldValues[prop]

    if (newVal === "0px") {
      newVal = ""
    }

    const newCSS = replaceCSS(this.props.css, prop, oldVal, newVal)
    this.props.onCSSChanged(newCSS)
  }

  renderEdgePositions = (aug, currentValues) => {
    const autoMsg = "auto: between corner augs"
    const center = currentValues[currentValues.centerProp] || autoMsg
    const offset = currentValues[currentValues.offsetProp] || "0px"
    return (
      <React.Fragment>
        <span>Center</span>
        <div className="grid__span3">
          <ValueCSSTypeCombo useLabel={"origin of " + aug + " along the edge."}
            extraOptions={[autoMsg]} type="length-percentage"
            setPopover={this.props.setPopover} onValueChanged={newVal => this.centerChanged(newVal, currentValues)}
            valmin={-500} valmax={500} cssValue={center} />
        </div>
        <span>Offset</span>
        <div>
          <ValueCSSTypeCombo useLabel={aug + "'s offset from center."}
            type="length-percentage"
            setPopover={this.props.setPopover} onValueChanged={newVal => this.offsetChanged(newVal, currentValues)}
            valmin={-500} valmax={500} cssValue={offset} />
        </div>
        <span className="grid__span2">from center</span>
      </React.Fragment>
    )
  }

  sizeChanged = (newVal, oldValues) => {
    const prop = oldValues.sizeProp
    const oldVal = oldValues[prop]

    if (newVal === "15px") {
      newVal = ""
    }

    const newCSS = replaceCSS(this.props.css, prop, oldVal, newVal)
    this.props.onCSSChanged(newCSS)
  }

  render () {
    const state = this.state
    const props = this.props
    const aug = (props.aug || "").replace(/[^a-z-]/g, "")
    const pos = this.augPosition(aug)
    if (!pos) {
      return null
    }
    const allowWH = this.allowWH(aug)
    const isEdgeAug = state.isEdgeAug[pos] || false

    const cv = this.getCurrentValues(pos, allowWH, isEdgeAug)

    return (
      <SubSectionContainer>
        <div className="container__title">{state[pos]} ({aug})</div>
        <GridLayout>
          <span>Size</span>
          <div className="grid__span2">
            <ValueCSSTypeCombo type="length" useLabel={aug + " size"}
              setPopover={props.setPopover} onValueChanged={newVal => this.sizeChanged(newVal, cv)}
              valmax={500} cssValue={cv[cv.sizeProp] || "15px"} />
          </div>
          <div className="augprop__icon"><AugIcon position={pos} aug={aug} /></div>
          {allowWH ? this.renderWH(aug, cv) : null}
          {isEdgeAug ? this.renderEdgePositions(aug, cv) : null}
        </GridLayout>
      </SubSectionContainer>
    )
  }
}

export default AugProps
