
const elements = [
  { key: "001", classes: ["x_corners-square"],     data: { "augmented-ui": "exe" }, h: 120, w: 120 },
  { key: "002", classes: ["x_corners-clip"],       data: { "augmented-ui": "tl-clip tr-clip br-clip bl-clip exe" }, h: 120, w: 120, l: 230 },
  { key: "003", classes: ["x_corners-err"],        data: { "augmented-ui": "tl-clip tr-rect br-clip bl-rect exe" }, h: 120, w: 120, l: 380 },
  { key: "004", classes: ["x_corners-dots"],       data: { "augmented-ui": "exe" }, h: 120, w: 120, t: 220 },
  { key: "005", classes: ["x_corners-thick"],      data: { "augmented-ui": "tl-clip tr-round br-clip exe" }, h: 120, w: 120, t: 220, l: 230 },
  { key: "006", classes: ["x_edges-vertical"],     data: { "augmented-ui": "tl-clip tr-clip bl-clip br-clip r-clip-y l-clip-y exe" }, h: 290, w: 120, l: 380, t: 220 },
  { key: "007", classes: ["x_edges-horizontal"],   data: { "augmented-ui": "tl-clip t-clip-x tr-clip bl-clip b-clip-x br-clip exe" }, h: 120, w: 275, t: 390 },
  { key: "008", classes: ["x_blog-container"],     data: { "augmented-ui": "tl-rect tr-clip-x br-clip-x exe" }, t: 90, l: 110, w: 650, h: 350 },
  { key: "009", classes: ["x_blog-purple-orange"], data: { "augmented-ui": "tl-rect tr-clip-x br-clip-x exe" }, t: 90, l: 110, w: 650, h: 350 },
  { key: "010", classes: ["x_blog-avatar"],        data: { "augmented-ui": "tl-round tr-round bl-clip exe" }, t: 50, l: 70, w: 60, h: 60 },
  { key: "011", classes: ["x_blog-twitter"],       data: { "augmented-ui": "bl-clip exe" }, t: 70, l: 625, w: 65, h: 40 },
  { key: "012", classes: ["x_blog-facebook"],      data: { "augmented-ui": "bl-clip exe" }, t: 70, l: 700, w: 65, h: 40 },
  { key: "013", classes: ["x_blog-next"],          data: { "augmented-ui": "tl-clip exe" }, t: 420, l: 695, w: 70, h: 40 },
  { key: "014", classes: ["x_file-icon"],          data: { "augmented-ui": "tl-round tr-clip br-round bl-clip exe" }, h: 120, w: 100 },
  { key: "015", classes: ["x_folder-outline"],     data: { "augmented-ui": "tl-clip tr-clip-x exe" }, h: 80, w: 120, l: 220 },
  { key: "016", classes: ["x_small-label"],        data: { "augmented-ui": "bl-clip tr-clip exe" }, h: 30, w: 80, l: 380, t: 75 },
  { key: "017", classes: ["x_option-left-on"],     data: { "augmented-ui": "tr-clip tl-round bl-clip br-round exe" }, h: 50, w: 250, t: 220 },
  { key: "018", classes: ["x_option-right-on"],    data: { "augmented-ui": "tl-clip tr-round br-clip bl-round exe" }, h: 50, w: 250, l: 380, t: 220 },
  { key: "019", classes: ["x_option-left-off"],    data: { "augmented-ui": "tr-clip tl-round bl-clip br-round exe" }, h: 50, w: 250, t: 300 },
  { key: "020", classes: ["x_option-right-off"],   data: { "augmented-ui": "tl-clip tr-round br-clip bl-round exe" }, h: 50, w: 250, l: 380, t: 300 },
  { key: "021", classes: ["x_my-augfiguration"],   data: { "augmented-ui": "tr-clip bl-clip br-clip-y exe" }, h: 270, w: 200 },
  { key: "022", classes: ["x_fallback-container"], data: { "augmented-ui": "tl-clip tr-clip br-clip bl-clip b-clip-x exe" }, h: 550, w: 800 }
]

export const exampleElements = elements.reduce((obj, el) => {
  obj[el.key] = el
  return obj
}, {})

export const exampleElementSets = {
  "simple-containers": ["001", "002", "003", "004", "005", "006", "007"],
  "shoutout-to-dave-geddes": ["008", "010", "011", "012", "013"],
  "deus-etc": ["014", "015", "016", "017", "018", "019", "020"]
}
