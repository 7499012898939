import React, { Component } from 'react'
import magicComponent from 'magic-react-component'
import keyMap from '../../utils/key-map'
import spaceEnterClick from '../../utils/space-enter-click'
import { typeUnits, splitValueUnit } from './css-helpers'

const UnitInputContainer = magicComponent("div", `
  position: relative;
  border-radius: 5px;
  white-space: nowrap;
  display: grid;
  grid-template-columns: minmax(0, auto) minmax(min-content, auto);
  grid-gap: 0px;

  .vu__input {
    background: transparent;
    border: none;
  }
  .vu__input--collapsed {
    padding: 0px;
    margin: 0px;
    width: 0px;
  }
  .vu__input--collapsed + div {
    width: 100%;
  }
  .vu__unit {
    cursor: pointer;
    text-align: center;
  }
  .vu__unit--span2 {
    grid-column: 1 / span 2;
  }
  .vu__unit--noclick {
    cursor: auto;
  }
`, {
  backgroundColor: 'gray900',
  p: 0,

  ".vu__input": {
    p: 1,
    m: 0,
    color: "gray200"
  },
  ".vu__unit": {
    p: 1,
    m: 0,
    color: "gray500"
  }
})

const UnitList = magicComponent("div", `
  margin-left: auto;
  margin-right: auto;
  max-width: 75%;
  border-radius: 5px;
  overflow: hidden;
`, {
  backgroundColor: 'gray900',
  ".unit__option": {
    backgroundColor: 'gray900',
    color: "gray500",
    p: 1,
    fontSize: 3
  },
  ".unit__option--active": {
    backgroundColor: 'gray800',
    color: "gold"
  },
  ".unit__option:focus, .unit__option:hover": {
    backgroundColor: 'gray750'
  }
})

class ValueCSSTypeCombo extends Component {
  // props: useLabel, type ("percentage", "length-percentage", "length", "alpha-value", ["customUnitList"])
  //        extraOptions, cssValue, onValueChanged, setPopover
  //        valmin, valmax, step

  state = {
    valmin: 0,
    valmax: 100,
    almostValid: null
  }

  captureAlmostValid = function (newVal, newUnit, oldVal, oldUnit) {
    // allows user to start typeing decimal places without being inturrupted
    if (!newVal || newVal === "-" || (/\d+\.0*$/.test(newVal) && newVal.replace(/[^.]/g, "").length === 1)) {
      this.setState({ almostValid: newVal })
    } else {
      this.setState({ almostValid: null })
      this.valueChanged(newVal, newUnit, oldVal, oldUnit)
    }
  }

  valueChanged = (newVal, newUnit, oldVal, oldUnit) => {
    const props = this.props
    const valmin = (typeof props.valmin !== "undefined") ? props.valmin : this.state.valmin
    const valmax = (typeof props.valmax !== "undefined") ? props.valmax : this.state.valmax
    newVal = /^(-?\d*\.?\d*)$/.test(newVal) ? newVal : oldVal
    newVal = Math.min(Math.max(parseFloat(newVal) || 0, valmin), valmax)
    newVal = newVal.toFixed(props.maxDecimalPlaces || 2).replace(/\.?0+$/, "")
    props.onValueChanged && props.onValueChanged(newVal + newUnit, oldVal + oldUnit)
    props.setPopover && props.setPopover()
  }

  focusPrev = ev => {
    const target = ev.target || {}
    const nextTarget = target.previousElementSibling
    nextTarget && nextTarget.focus && nextTarget.focus()
  }

  focusNext = ev => {
    const target = ev.target || {}
    const nextTarget = target.nextElementSibling
    nextTarget && nextTarget.focus && nextTarget.focus()
  }

  showUnitList = (selectedUnit, unitList, unitChanged, refocusEl) => {
    const doRefocus = () => refocusEl && refocusEl.focus && refocusEl.focus()
    const refocusAfterUnitChanged = (unit) => { unitChanged(unit); doRefocus() }
    this.props.setPopover(
      "Select unit for " + this.props.useLabel,
      (<UnitList role="listbox" aria-label="Available Types">
        {unitList.map(unit => (
          <div key={unit}
            aria-label={unit}
            role="option"
            aria-selected={selectedUnit === unit ? "true" : "false"}
            onKeyDown={keyMap({
              ArrowUp: this.focusPrev,
              ArrowDown: this.focusNext,
              " ": () => refocusAfterUnitChanged(unit),
              Enter: () => refocusAfterUnitChanged(unit)
            })}
            tabIndex="0"
            onClick={() => refocusAfterUnitChanged(unit)}
            className={"unit__option" + (selectedUnit === unit ? " unit__option--active" : "")}
          >{unit}</div>
        ))}
      </UnitList>),
      doRefocus
    )
  }

  modify = ev => {
    if (ev.ctrlKey) return 100
    if (ev.altKey) return 0.1
    if (ev.shiftKey) return 10
    return 1
  }

  valueChangeKeyMap = changedCallback => {
    const step = this.props.step || 1
    const jump = amount => ev => {
      ev.target.value = (parseFloat(ev.target.value) + (this.modify(ev) * amount))
      return changedCallback(ev)
    }
    return {
      ArrowUp: jump(step),
      ArrowDown: jump(-step),
      // ArrowRight: jump(1),
      // ArrowLeft: jump(-1),
      End: jump(99999),
      Home: jump(-99999),
      PageUp: jump(25 * step),
      PageDown: jump(-25 * step)
    }
  }

  render () {
    const state = this.state
    const props = this.props
    const type = props.type || "length"
    const unitList = (Array.isArray(type) ? type : typeUnits[type]).concat(props.extraOptions || [])
    const defaultUnit = (typeof props.defaultUnit !== "undefined") ? props.defaultUnit : unitList[0]
    const { value, unit } = splitValueUnit(props.cssValue, defaultUnit)

    const valChanged = ev => this.captureAlmostValid(ev.target.value, unit, value, unit)
    const unitChanged = newUnit => this.valueChanged(value, newUnit, value, unit)

    const stateValue = state.almostValid !== null ? state.almostValid : value

    return (
      <UnitInputContainer className={props.className} collapsed={state.collapsed}>
        {value === ""
          ? null
          : <input aria-label={props.useLabel} className="vu__input" type="text" value={stateValue}
              onKeyDown={keyMap(this.valueChangeKeyMap(valChanged))}
              onChange={valChanged} />
        }
        {unitList.length > 1
          ? <div className={"vu__unit" + (value === "" ? " vu__unit--span2" : "")}
              {...spaceEnterClick(ev => this.showUnitList(unit, unitList, unitChanged, ev.target))}
              aria-label={props.useLabel + ": " + unit}
            >{unit}</div>
          : <div className={"vu__unit vu__unit--noclick" + (value === "" ? " vu__unit--span2" : "")} aria-label={unit}>{unit}</div>
        }
      </UnitInputContainer>
    )
  }
}

export default ValueCSSTypeCombo
