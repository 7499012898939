import React, { Component } from 'react'
import ValueAnyColor from '../colors/ValueAnyColor'
import colors from '../colors/color-helpers'
import ColorIcon from '../colors/ColorIcon'
import magicComponent from 'magic-react-component'
import spaceEnterClick from '../../../utils/space-enter-click'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const ColorLayerContainer = magicComponent("div", `
  display: grid;
  grid-template-columns: 50px 1fr;
  grid-auto-rows: auto;
  grid-row-gap: 5px;
  grid-gap: 5px;
  align-items: center;

  &.draggable {
    .layer__name {
      cursor: grab;
    }
    .layer__drag-handle {
      display: inline-block;
      cursor: grab;
    }
  }

  .layer__name {
    height: 100%;
    line-height: 26px;
  }
  .layer__drag-handle {
    display: none;
    vertical-align: middle;
    margin-right: 5px;
    margin-left: -5px;
    width: 10px;
    height: 100%;
    background: grey;
  }
  .layer__toggle-editor {
    cursor: pointer;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
`, {
  ".layer__delete": {
    fontSize: 4,
    alignSelf: "flex-start",
    textAlign: "center",
    cursor: "pointer"
  },
  ".layer__delete__icon": {
    opacity: 0.25,
    color: "gray900"
  },
  ".layer__delete:hover .layer__delete__icon, .layer__delete:focus .layer__delete__icon": {
    opacity: 1,
    color: "red900"
  }
})

class ColorLayer extends Component {
  // props: layer, onValueChanged, setPopover, editorOpen, toggleEditor
  // dragStart, dropEnter, dragAccessibilityProps

  state = {}

  toggleEditor = () => {
    this.props.toggleEditor && this.props.toggleEditor()
  }

  colorChanged = (newVal, fakeColorLayer) => {
    newVal = newVal.trim()
    if (fakeColorLayer) {
      newVal = `linear-gradient(${newVal} 0.00px, ${newVal} 0.00px)`
    }
    this.props.onValueChanged(newVal)
  }

  render () {
    const props = this.props
    const layer = props.layer
    const resolvedColor = colors.resolveAnyColorStr(layer.css)
    const { dragStart, dropEnter } = props
    const colorChanged = val => this.colorChanged(val, layer.type === "fake")

    return (
      <ColorLayerContainer className={props.className} onPointerEnter={dropEnter}>
        <div className="layer__name" onPointerDown={dragStart} {...props.dragAccessibilityProps} tabIndex="0">
          <div className="layer__drag-handle"></div>
          Color
        </div>
        <div className="layer__toggle-editor" {...spaceEnterClick(this.toggleEditor)}>
          <ColorIcon style={{"--display-color": resolvedColor}} />
          {layer.css.trim()}
        </div>
        {props.editorOpen
          ? <React.Fragment>
              <div className="layer__delete" title="Delete Background Layer" {...spaceEnterClick(props.onDeleteClicked)}>
                <FontAwesomeIcon className="layer__delete__icon" icon="window-close" />
              </div>
              <ValueAnyColor cssValue={layer.css} onValueChanged={colorChanged} />
            </React.Fragment>
          : null
        }
      </ColorLayerContainer>
    )
  }
}

export default ColorLayer
