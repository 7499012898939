import React from 'react'
import 'prismjs/themes/prism-tomorrow.css'
import { ThemeProvider } from 'styled-components'
import theme from './theme'
import ClassManager from './components/ClassManager'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faCaretRight, faCaretDown, faWindowClose, faPlusSquare, faCog, faSdCard, faRandom, faFolderPlus } from '@fortawesome/free-solid-svg-icons'
import localforage from 'localforage'

localforage.config({
  name: "augooiigooey"
})

library.add(faCaretRight, faCaretDown, faWindowClose, faPlusSquare, faCog, faSdCard, faRandom, faFolderPlus)

/*
  App
    ThemeProvider
      ClassManager
        <style>[classes]
        ElementManager
          LayoutManager
            Output
            Canvas
              [Elements -> WHTL<children>]
            GUI
            Overlay

  ----------------------------------------

  Classes:
    { className, css, variants: {--name: css} }

  Elements:
    { key, classes, data: {"augmented-ui": augs}, whtl }
*/

function App() {
  return (
    <ThemeProvider theme={theme}>
      <ClassManager />
    </ThemeProvider>
  )
}

document.addEventListener('pointerdown', ev => {
  if (ev && ev.target && ev.target.matches && ev.target.matches("[tabindex='0']")) {
    ev.target.focus()
  }
})

export default App
