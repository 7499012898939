import React, { Component } from 'react'
import { exampleClasses } from '../static/example-data/classes'
import ElementManager from './ElementManager'
import localforage from 'localforage'

const classStore = localforage.createInstance({ name: "augooiigooey", storeName: "classes" })

/*
  { className, css, variants: {--name: css} }
  TODO: variants
*/

class ClassManager extends Component {
  constructor (props) {
    super(props)

    const _exampleClasses = Object.keys(exampleClasses)
    const state = {
      // _ keeps the classes from conflicting since the app doesn't allow them to start with _
      _exampleClasses,
      _savedClasses: undefined
    }
    _exampleClasses.forEach(cn => {
      // "my-augfiguration": { className: "my-...", css: "..." }, ...
      state[cn] = Object.assign({}, exampleClasses[cn]) // TODO: clone variants obj if present
    })

    this.state = state
  }

  componentDidMount () {
    const _savedClasses = []
    const savedClasses = {}
    classStore.iterate((obj, className) => {
      _savedClasses.push(className)
      savedClasses[className] = obj
    }).then(() => {
      this.setState(savedClasses)
      this.setState({ _savedClasses })
    }).catch(err => {
      console.log(err)
      this.setState(savedClasses)
      this.setState({ _savedClasses })
    })
  }

  revert = existingClassName => {
    return classStore.getItem(existingClassName)
      .then(storeObj => {
        if (storeObj && storeObj.className) {
          this.setState({
            [storeObj.className]: storeObj
          })
        } else if (this.state._exampleClasses.includes(existingClassName)) {
          storeObj = Object.assign({}, exampleClasses[existingClassName])
          this.setState({
            [existingClassName]: storeObj
          })
        }
        return storeObj
      })
  }

  // TODO: delete from storage

  commit = (oldClassName, newClassName) => {
    newClassName = newClassName || oldClassName
    const state = this.state
    const cssObj = state[oldClassName]
    const newObj = Object.assign({}, cssObj, { className: newClassName })
    let promise = Promise.resolve()
    const _savedClasses = (state._savedClasses || []).slice()

    if (newClassName !== oldClassName) {
      promise = this.revert(oldClassName)
        .then(storeObj => {
          _savedClasses.push(newClassName)
          this.setState({
            [newClassName]: newObj,
            _savedClasses
          })
        })
    } else if (!_savedClasses.includes(newClassName)) {
      _savedClasses.push(newClassName)
      this.setState({ _savedClasses })
    }

    return promise.then(() => {
      return classStore.setItem(newClassName, newObj)
    })
  }

  cssChanged = (className, css) => {
    this.setState(state => {
      const cssObj = state[className]
      return { [className]: Object.assign({}, cssObj, { css }) }
    })
  }

  getCSS = className => {
    return (this.state[className] || {}).css || ""
  }

  render () {
    const state = this.state
    const { _exampleClasses, _savedClasses } = state
    const classManager = {
      revert: this.revert, // (existingClassName)
      save: this.commit, // (oldClassName, newClassName)
      getCSS: this.getCSS, // (className)
      onCSSChanged: this.cssChanged, // (className, css)
      exampleClasses: _exampleClasses,
      savedClasses: _savedClasses
    }

    return (
      <React.Fragment>
        <style>
          {_exampleClasses.map(className => {
            const cssObj = state[className]
            return (
              <React.Fragment key={cssObj.className}>
                {"." + cssObj.className + " {"}
                  {cssObj.css}
                {"}"}
              </React.Fragment>
            )
          })}
        </style>
        {_savedClasses
          ? <style>
              {_savedClasses.map(className => {
                const cssObj = state[className]
                return (
                  <React.Fragment key={cssObj.className}>
                    {"." + cssObj.className + " {"}
                      {cssObj.css}
                    {"}"}
                  </React.Fragment>
                )
              })}
            </style>
          : null
        }
        <ElementManager classManager={classManager} />
      </React.Fragment>
    )
  }
}

export default ClassManager
