import React, { Component } from 'react'
import ValueCSSTypeCombo from '../ValueCSSTypeCombo'
import magicComponent from 'magic-react-component'

const RepeatStyleContainer = magicComponent("div", `
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 5px;
  grid-row-gap: 5px;
  align-items: center;
  vertical-align: middle;
  border-top: 1px solid;

  .span--2 {
    grid-column-end: span 2;
  }
`, {
  mt: 1,
  pt: 1,
  borderColor: "gray800"
})

class ValueRepeatStyle extends Component {
  // props: cssValue, onValueChanged, setPopover

  state = {}

  valueChanged = (newValue, which, twoValues) => {
    newValue = newValue.replace("0", "")
    const x = which === 0 ? newValue : twoValues[0]
    const y = which === 1 ? newValue : twoValues[1]
    let cssValue = x + " " + y
    if (cssValue === "repeat no-repeat") {
      cssValue = "repeat-x"
    } else if (cssValue === "no-repeat repeat") {
      cssValue = "repeat-y"
    } else if (x === y) {
      cssValue = x
    }
    this.props.onValueChanged(cssValue === "repeat" ? "" : cssValue)
  }

  explodeRepeatValues = cssValue => {
    cssValue = cssValue.replace(/\s+/g, " ").trim()
    if (cssValue === "repeat-x") {
      cssValue = "repeat no-repeat"
    } else if (cssValue === "repeat-y") {
      cssValue = "no-repeat repeat"
    } else if (/^(?:repeat|space|round|no-repeat)$/.test(cssValue)) {
      cssValue = `${cssValue} ${cssValue}`
    }
    return cssValue.split(" ")
  }

  render () {
    const props = this.props
    const cssValue = props.cssValue || "repeat repeat"
    const twoValues = this.explodeRepeatValues(cssValue)
    const repeatStyle = ["repeat", "space", "round", "no-repeat"]

    return (
      <RepeatStyleContainer className={props.className}>
        <span>Repeat-X</span>
        <ValueCSSTypeCombo className="span--2" type={repeatStyle}
          cssValue={twoValues[0]} setPopover={props.setPopover}
          useLabel="Background Horizontal Repeat" onValueChanged={val => this.valueChanged(val, 0, twoValues)} />
        <span>Repeat-Y</span>
        <ValueCSSTypeCombo className="span--2" type={repeatStyle}
          cssValue={twoValues[1]} setPopover={props.setPopover}
          useLabel="Background Vertical Repeat" onValueChanged={val => this.valueChanged(val, 1, twoValues)} />
      </RepeatStyleContainer>
    )
  }
}

export default ValueRepeatStyle
