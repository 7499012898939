import magicComponent from 'magic-react-component'

// transparentCheckerBG: https://stackoverflow.com/a/35362074
const transparentCheckerBG = `
  background-image: linear-gradient(45deg, #888 25%, transparent 25%),
                    linear-gradient(-45deg, #888 25%, transparent 25%),
                    linear-gradient(45deg, transparent 75%, #888 75%),
                    linear-gradient(-45deg, transparent 75%, #888 75%);
  background-size: 12px 12px;
  background-position: 0 0, 0 6px, 6px -6px, -6px 0px;
`
const ColorIcon = magicComponent("span", `
  position: relative;
  display: inline-block;
  width: 26px;
  height: 26px;
  border: 1px solid;
  vertical-align: middle;
  ${transparentCheckerBG}

  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: var(--display-color, transparent);
  }
`, {
  borderColor: "gray900",
  mr: 1
})

export default ColorIcon
