import magicComponent from 'magic-react-component'

const right3D = `
  .allow3Drails & {
    transform-origin: 0% 33vh;
    transform: perspective(2077px) rotateY(-7.5deg);
    backface-visibility: hidden;
    -webkit-font-smoothing: subpixel-antialiased;
  }
`
const left3D = `
  .allow3Drails & {
    transform-origin: 100% 33vh;
    transform: perspective(2077px) rotateY(7.5deg);
    backface-visibility: hidden;
    -webkit-font-smoothing: subpixel-antialiased;
  }
`

export const GridLayout = magicComponent("div", `
  display: grid;
  width: 100%;
  height: 100%;
  margin: 0px;
  padding: 0px;
  background: hsl(210,7%,17%);
  border-top: 3px solid gold;
  grid-template-columns: 240px 1fr 240px;
  grid-template-rows: 3em minmax(0, 1fr);
  grid-template-areas: "title headerbar byline"
                        "css canvas gui";

  .common-button {
    border: 1px solid;
    border-radius: 5px;
    text-align: center;
    cursor: pointer;
  }
`, {
  // default focus effect for app
  "*:focus": {
    boxShadow: ({magicScale}) => `inset 2px 2px 0px ${magicScale("colors", "blue700")}, inset -2px -2px 0px ${magicScale("colors", "blue700")}, 0 0 1px white`
  },
  ".common-button": {
    background: ({magicScale}) => `linear-gradient(${magicScale("colors", "gray700")}, black)`,
    color: "gray100",
    borderColor: "gray900",
    py: 1,
    px: 2,
    m: 1
  }
})

export const Title = magicComponent("h1", `
  grid-area: title;
  height: 100%;
  background: linear-gradient(to right, black 40px, hsl(210,7%,17%));
  ${left3D}
  .app-logo {
    width: 34px;
    font-size: 0px;
    vertical-align: middle;
  }
`, {
  color: "gray500",
  // backgroundColor: "gray800",
  m: 0,
  p: 1,
  fontSize: 5
})

export const HeaderBar = magicComponent("div", `
  grid-area: headerbar;
  line-height: 2em;
  vertical-align: middle;
  text-align: center;
`, {
  color: "gray500",
  backgroundColor: "gray800",
  fontSize: 3,
  p: 2
})

export const ByLine = magicComponent("div", `
  grid-area: byline;
  ${right3D}

  .button--byline {
    vertical-align: middle;
    width: 100%;
    background: transparent;
    border: none;
    cursor: pointer;
  }
  .button--byline__icon {
    display: inline-block;
    text-align: center;
    width: 30px;
    height: 50px;
    transform: scaleY(0.6);
    vertical-align: middle;
    --aug-tl: 13px;
    --aug-tr: 13px;
    --aug-bl: 13px;
    --aug-br: 13px;
    --aug-inset: 3px;
    --aug-inset-bg: gold;
    --aug-border: 3px;
    --aug-border-bg: gold;
    --aug-border-opacity: 0.57;
    margin-right: 10px;

    i {
      transform: scaleY(1.667);
      color: black;
      font-size: 24px;
      font-weight: bold;
      font-family: serif;
      line-height: 50px;
      vertical-align: bottom;
    }
  }
  .button--byline:hover .button--byline__icon,
  .button--byline:focus .button--byline__icon {
    --aug-border-opacity: 1;
    --aug-inset-opacity: 0.5;
  }
  .button--byline__icon::before,
  .button--byline__icon::after {
    transition: opacity 0.25s ease-out;
  }
`, {
  backgroundColor: "gray800",
  ".button--byline": {
    p: 0,
    fontSize: 4,
    color: "white"
  }
})

export const Css = magicComponent("div", `
  grid-area: css;
  display: grid;
  grid-template-rows: 2.25em 2.25em minmax(0, 1fr);
  grid-template-columns: 1fr;
  font-family: monospace;
  ${left3D}
  > h2 {
    --aug-t-height: 3px;
    --aug-t-width: 15%;
    --aug-t-origin-x: 15%;
    --aug-tr-height: 4px;
    --aug-tr-width: 45%;
    --aug-b-height: 3px;
    --aug-b-width: 50%;
    --aug-l: 5px;
    --aug-l-offset: 3px;
    --aug-r: 5px;
    --aug-r-offset: -3px;
  }
  > h2,
  > div {
    line-height: 2em;
    vertical-align: middle;
    padding: 0px;
    padding-left: 15px;
    margin: 0px;
  }
  .output {
    position: relative;

    &::before {
      content: "";
      opacity: 0.5;
      position: absolute;
      pointer-events: none;
      top: 0px;
      left: 0px;
      right: 0px;
      bottom: 0px;
      box-shadow: inset 0 0 30px gold;
      mask-image: repeating-linear-gradient(to bottom, black, black 1px, transparent 3px);
    }
  }
  .scrollable {
    height: 100%;
    overflow-y: auto;
  }
  pre {
    margin: 0px;
    text-shadow: -1px -1px 2px black, 1px 1px 2px black;
    white-space: pre-wrap;
  }
  code {
    white-space: pre-wrap;
  }
`, {
  fontSize: 2,
  backgroundColor: "gray800",
  "> h2": {
    fontSize: 3
  },
  "> h2, > h2 a": {
    backgroundColor: "gold",
    color: "black"
  },
  "> div": {
    backgroundColor: "black",
    color: "gray400"
  },
  "pre": {
    backgroundColor: "gray800",
    fontSize: 1
  }
})

export const Canvas = magicComponent("div", `
  grid-area: canvas;
  position: relative;
  border-left: 1px solid;
  border-right: 1px solid;
`, {
  borderColor: "gray800"
})

export const Gui = magicComponent("div", `
  grid-area: gui;
  height: 100%;
  position: relative;
  ${right3D}
`, {
  backgroundColor: "gray800"
})
