import React, { Component } from 'react'
import magicComponent from 'magic-react-component'
import ColorIcon from './ColorIcon'
import colors from './color-helpers'
import keyMap from '../../../utils/key-map'

const ColorInputContainer = magicComponent("div", `
  position: relative;
  border-radius: 5px;
  white-space: nowrap;
  display: grid;
  grid-template-columns: minmax(min-content, auto) minmax(0, auto);
  grid-gap: 0px;
  align-items: center;

  .color__type {
    width: 40px;
    text-align: right;
  }
  .color__input {
    background: transparent;
    border: none;
  }
`, {
  backgroundColor: 'gray900',
  p: 0,

  ".color__type": {
    p: 1,
    m: 0,
    color: "gray500"
  },

  ".color__input": {
    p: 1,
    m: 0,
    color: "gray200"
  }
})

const ColorValidityContainer = magicComponent("button", `
  display: block;
  width: 100%;
  align-items: center;
  border: 1px solid;
  text-align: left;
  border-radius: 5px;
  cursor: pointer;

  .validity__span--2 {
    display: block;
    margin-bottom: 5px;
  }
`, {
  borderColor: "gray900",
  backgroundColor: "gray600",
  p: 1,
  my: 1,

  "&:focus": {
    backgroundColor: "gray400",
    boxShadow: ({magicScale}) => `inset 2px 2px 0px ${magicScale("colors", "blue700")}, inset -2px -2px 0px ${magicScale("colors", "blue700")}`
  }
})

class ValueColorNameOrHex extends Component {
  // props: cssValue, onValueChanged

  state = {
    usersInput: null,
    currentUserValueValidResolution: false
  }

  autocompleteForType = (partialValue, type) => {
    const rgb = type.includes("rgb")
    const valObj = rgb
                    ? { 0: 127, 1: 127, 2: 127 }
                    : (type.includes("hsl")
                        ? { 0: 180, 1: 100, 2: 50 }
                        : null)
    if (!valObj) {
      return partialValue
    }
    
    // alpha default
    valObj[3] = 1

    const vals = []
    partialValue.replace(/\b(\d+(?:\.\d+)?)/g, (_, val) => vals.push(val))
    Object.assign(valObj, vals)

    const alpha = type.includes("a") ? true : valObj[3] !== 1

    const units = rgb ? { 0: "", 1: "", 2: "" } : { 0: "", 1: "%", 2: "%" }

    return `${type}(${valObj[0] + units[0]}, ${valObj[1] + units[1]}, ${valObj[2] + units[2]}${alpha ? ", " + valObj[3] : ""})`
  }

  validateUserInput = val => {
    const usersInput = (val || "").replace(/#/g, "")
    const usersInputWithHash = /^[a-f0-9]+$/.test(usersInput) ? "#" + usersInput : usersInput
    const userEnteredBlack = usersInput === "black" || usersInputWithHash === "#000" || usersInputWithHash === "#000000"

    const userInputTypePreference = usersInputWithHash.replace(/(rgba?|hsla?)?.*/, "$1")
    const autoCompletedValue = this.autocompleteForType(usersInputWithHash, userInputTypePreference)
    const autoCompleteResolvedColor = colors.convertAnyToStringPreference(autoCompletedValue, userInputTypePreference)

    const resolvedColorWithoutTypePreference = colors.resolveAnyColorStr(autoCompletedValue)
    const inputIsValid = resolvedColorWithoutTypePreference !== "#000000" || userEnteredBlack

    return inputIsValid ? autoCompleteResolvedColor : false
  }

  userValChanged = (newVal, oldVal) => {
    const userValidValue = this.validateUserInput(newVal)

    this.setState({
      usersInput: newVal,
      currentUserValueValidResolution: userValidValue
    })
  }

  forceStateResolution = (newVal, oldVal) => {
    const userValidValue = this.validateUserInput(newVal)

    // let go of captured state
    this.setState({
      usersInput: null,
      currentUserValueValidResolution: false
    })

    this.props.onValueChanged && this.props.onValueChanged(userValidValue || oldVal, oldVal)
  }

  render () {
    const state = this.state
    const props = this.props
    const resolvedColor = colors.resolveAnyColorStr(props.cssValue)

    const usersInput = state.usersInput

    const stateValue = ((usersInput !== null ? usersInput : resolvedColor) || "").replace(/#/g, "")
    const stateIsHex = !stateValue || /^#?[a-f0-9]+$/.test(stateValue)

    const stateResolutionValue = state.currentUserValueValidResolution || resolvedColor
    const valuesAreTheSame = state.currentUserValueValidResolution === resolvedColor

    const userValChanged = ev => this.userValChanged(ev.target.value, resolvedColor)
    const forceStateResolution = withVal => () => this.forceStateResolution(withVal, resolvedColor)

    const userInputTypePreference = stateIsHex ? "#" : stateValue.replace(/(rgba?|hsla?)?.*/, "$1") || "name"
    const resolveTypeLabel = (stateResolutionValue.replace(/(rgba?|hsla?)?.*/, "$1") || "hex").toUpperCase()

    const shortcuts = {
      Escape: () => props.onEsc && props.onEsc()
    }
    if (!valuesAreTheSame && state.currentUserValueValidResolution) {
      shortcuts.Enter = forceStateResolution(stateResolutionValue)
    }
    if (!valuesAreTheSame && usersInput) {
      shortcuts.Escape = forceStateResolution(resolvedColor)
    }

    const aria = { "aria-label": "Color Hex String or Name" }
    if (!valuesAreTheSame && state.currentUserValueValidResolution) {
      aria["aria-invalid"] = false
    }

    return (
      <div>
        <ColorInputContainer>
          <div className="color__type">{userInputTypePreference}</div>
          <input className="color__input" {...aria}
            onKeyDown={keyMap(shortcuts)}
            type="text" value={stateValue} onChange={userValChanged} />
        </ColorInputContainer>

        {!valuesAreTheSame && state.currentUserValueValidResolution ? (
          <ColorValidityContainer onClick={forceStateResolution(stateResolutionValue)}>
            <div className="validity__span--2">Confirm, use {resolveTypeLabel} color:</div>
            <ColorIcon style={{"--display-color": stateResolutionValue}} />
            <span>{stateResolutionValue.replace(/rgba?|hsla?|\(|\)/gi, "").trim()}</span>
          </ColorValidityContainer>
        ) : null}

        {!valuesAreTheSame && usersInput ? (
          <ColorValidityContainer onClick={forceStateResolution(resolvedColor)}>
            <div className="validity__span--2">Cancel, use current color:</div>
            <ColorIcon style={{"--display-color": resolvedColor}} />
            <span>{resolvedColor}</span>
          </ColorValidityContainer>
        ) : null}
      </div>
    )
  }
}

export default ValueColorNameOrHex
