import React, { Component } from 'react'
import magicComponent from 'magic-react-component'
import spaceEnterClick from '../utils/space-enter-click'

const PillNavContainer = magicComponent("div", `
  display: grid;
  width: 100%;
  grid-auto-flow: column;
  grid-auto-columns: 1fr;
  grid-auto-rows: 26px;
  grid-gap: 1px;
  align-items: center;
  border-radius: 5px;
  overflow: hidden;
  border: 1px solid;

  .pillnav__item {
    text-align: center;
    border: none;
    cursor: pointer;
    font-weight: bold;
  }

  .pillnav__item--active {
    display: inline-block;
    height: 26px;
    vertical-align: middle;
  }
`, {
  backgroundColor: "gray900",
  borderColor: "gray900",

  ".pillnav__item": {
    fontSize: 2,
    backgroundColor: "gray800",
    color: "gray400",
    p: 1,
    m: 0
  },
  ".pillnav__item--active": {
    color: "gray900",
    backgroundColor: "rgba(255, 215, 0, 0.75)"
  }
})

class PillNav extends Component {
  // props: items, selected, onValueChanged, label

  state = {}

  render () {
    const props = this.props
    const items = props.items
    const selected = props.selected

    return (
      <PillNavContainer className={props.className} aria-label={props.label || "Options"}>
        {items.map(item => (
          <div key={item.title || item} {...spaceEnterClick(() => props.onValueChanged(item))}
            className={"pillnav__item" + (item === selected ? " pillnav__item--active" : "")}
            aria-selected={item === selected ? "true" : "false"}
            aria-label={item.label || item.title || item}
          >
            {item.title || item}
          </div>
        ))}
      </PillNavContainer>
    )
  }
}

export default PillNav
